import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import CourseDetails from "./CourseDetails.jsx";
import CourseAccess from "./CourseAccess.jsx";
import CoursewareUpload from "./CoursewareUpload.jsx";
import AssessmentQuestions from "./AssessmentQuestions.jsx";

import TitleElement from "../../../components/TitleElement.jsx";
import LoadingBar from "../../../components/LoadingBar.jsx";

import { courseApi } from "../../../api/course.js";

export default function EditCourseTabManager() {

	const { courseId } = useParams();
	const navigate = useNavigate();
	const location = useLocation(); 
	const [activeTab, setActiveTab] = useState("details");
	const [selectedCourse, setSelectedCourse] = useState(null);

	const getCourseData = async () => {
		const course = await courseApi.getCourse(courseId);
		setSelectedCourse(course);
	};

	useEffect(() => {
		// Extract the last part of the URL to determine the active tab
		const pathParts = location.pathname.split("/");
		const currentTab = pathParts[pathParts.length - 1];
	
		// Set the active tab based on the current path
		if (pathParts.length === 3) {
			// If the URL doesn't specify a tab, redirect to /courseId/details
			navigate(`/course/${courseId}/details`, { replace: true });
		} else {
			setActiveTab(currentTab);
		}
	}, [location.pathname, navigate, courseId]);

	useEffect(() => {
		getCourseData();
	}, [courseId]);

	const handleTabSelect = (key) => {
		navigate(`/course/${courseId}/${key}`, { replace: true }); 
	};

	return (
		<>
		{selectedCourse ? (
			<>
				{/* {console.log("getting course info:" + selectedCourse.courseName)} */}
				<TitleElement title={selectedCourse.courseName} noRule />

				<Tabs defaultActiveKey={"details"} 
					activeKey={activeTab} 
					className="mb-5 border-bottom border-primary"
					variant="underline"
					onSelect={handleTabSelect}
					justify>

					<Tab eventKey="details" title="Course Details">
						<CourseDetails selectedCourse={selectedCourse} />
					</Tab>
					<Tab eventKey="access" title="Course Access">
						<CourseAccess />
					</Tab>
					<Tab eventKey="upload" title="Courseware Upload">
						<CoursewareUpload course={selectedCourse} courseId={courseId} />
					</Tab>
					<Tab eventKey="assessment" title="Assessment Questions">
						<AssessmentQuestions />
					</Tab>
				</Tabs>
			</>
		) : (
			<LoadingBar />
		)}
	</>
  );
}
