import React, { useState, useEffect } from "react";
import { Alert, Col, Row, Button, Form, Dropdown } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { CSVLink } from "react-csv";
import { Typeahead } from "react-bootstrap-typeahead";
import {uniqWith, isEqual} from "lodash";
import DatePicker from "react-datepicker";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import { courseApi } from "../../../api/course.js";
import { clientApi } from "../../../api/client.js";
import { useAuth } from "../../../contexts/AuthContext.jsx";
import formatDate from "../../../_helpers/formatDate";

export default function CourseAttempts() {
    const authContext = useAuth();
    const isCustomerAdmin = authContext.authentication.role === "CustomerAdmin";
    const adminClientId = authContext.authentication.clientId;
    const adminClientName = authContext.authentication.clientName;
    const initialFilterState = {
        clientId: adminClientId || "",
        courseId: "",
        employeeNumber: "",
        name: "",
        passed: "both",
        fromDate: null,
        toDate: null,
        otherFieldName: "",
        otherFieldValue: "",
    };

    const [pageState, setPageState] = useState({pageError: null}); 
    const [loading, setLoading] = useState(false);

    const [rowData, setRowData] = useState([]);
    const [clients, setClients] = useState([]);
    const [courses, setCourses] = useState([]);
    const [filters, setFilters] = useState(initialFilterState);
    const [otherFieldNames, setOtherFieldNames] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [showIncomplete, setShowIncomplete] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [filteredRowData, setFilteredRowData] = useState([]);
      
    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 28,
        },
        ...(isCustomerAdmin ? [] : [{ field: 'clientName', headerName: 'Client', sortable: true, flex: 3, resizable: true }]),
        { field: 'username', headerName: 'Group', sortable: true, flex: 2, resizable: true },
        { field: 'courseName', headerName: 'Course', sortable: true, flex: 4, resizable: true },
        { field: 'employeeNumber', headerName: 'Emp #', sortable: true, flex: 2, resizable: true },
        { field: 'firstName', headerName: 'First Name', sortable: true, flex: 2, resizable: true },
        { field: 'lastName', headerName: 'Last Name', sortable: true, flex: 2, resizable: true },
        { field: 'passed', headerName: 'Pass', sortable: true, flex: 1, resizable: true },
        { field: 'otherFieldName1', headerName: 'Other Name 1', sortable: true, flex: 2, resizable: true },
        { field: 'otherFieldValue1', headerName: 'Other Value 1', sortable: true, flex: 2, resizable: true },
        { field: 'completed', headerName: 'Date Completed', sortable: true, flex: 3, resizable: true, valueFormatter: params => params.value ? formatDate(params.value) : '' },
        ...(filteredRowData.some(row => row.reportScore) ? [{ field: 'score', headerName: 'Score', sortable: true, flex: 2, resizable: true }] : []),
    ];

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateChange = (name, date) => {
        setFilters({ ...filters, [name]: date });
        console.log(date);
    };

    const removeSuffix = (str) => {
        const match = str.match(/#(\d+)/);
        if (match) {
            return str.replace(match[0], '');
        } else {
            return str;
        }
    }

    const applyFilters = (reset = false) => {
        console.log("reset:", reset);
        console.log("Current filters:", filters);
        setLoading(true);

        if (reset) {
            console.log("RESETTING")
            setFilters(initialFilterState);
        }

        let { clientId, courseId, employeeNumber, name, passed, fromDate, toDate, otherFieldName, otherFieldValue } = reset ? initialFilterState : filters;
        
        const filterPayload = {};
        console.log("COURSE ID: ", courseId);
        if (clientId) filterPayload.clientId = parseInt(clientId);
        if (courseId) filterPayload.courseId = parseInt(courseId);
        if (employeeNumber) filterPayload.employeeNumber = employeeNumber;
        if (name) filterPayload.name = name;
        if (passed !== 'both') filterPayload.passed = passed === 'true';
        if (fromDate) filterPayload.fromDate = fromDate.toISOString();
        if (toDate) filterPayload.toDate = toDate.toISOString();
        const cleanedOtherFieldName = removeSuffix(otherFieldName);
        if (otherFieldName) filterPayload.otherFieldName = cleanedOtherFieldName;
        if (otherFieldValue) filterPayload.otherFieldValue = otherFieldValue;
    
        courseApi.getCourseAttempts(filterPayload)
            .then((courseAttempts) => {
                // Process each course attempt to handle reportScore
                const processedAttempts = courseAttempts.map(attempt => {
                    if (!attempt.reportScore) {
                        return { ...attempt, score: null, scorePercentage: null };
                    }
                    return attempt;
                });

                setSelectedRows([]);
                setOtherFieldNames(getOtherFieldNames(processedAttempts));
                setRowData(processedAttempts);
            })
            .catch((error) => {
                setPageState(prevState => ({ ...prevState, pageError: 'Failed to load data' }));
            })
            .finally(() => {
                setLoading(false);
            });        
    };

    const getOtherFieldNames = (courseAttempts) => {
        const otherFieldNames = [];
    
        courseAttempts.forEach(row => {
            for (let i = 1; i <= 10; i++) {
                const fieldName = row[`otherFieldName${i}`];
                if (fieldName) {
                    otherFieldNames.push({ field: `OtherField${i}`, fieldName });
                }
            }
        });
    
        return uniqWith(otherFieldNames, isEqual);
    }

    const clearFilters = () => {
        setOtherFieldNames([]);
        setSelectedRows([]);
        setFilteredRowData([]);
        applyFilters(true);
    };

    const onSelectionChanged = (event) => {
        const selectedRows = event.api.getSelectedRows();
        setSelectedRows(selectedRows);
    };

    const handleOptionClick = async (operation) => {
        const selectedIds = selectedRows.map(row => row.id);

        if (selectedIds.length === 0) {
            setPageState(prevState => {return {...prevState, pageError: "Please select at least one row." }});
            return;
        }

        try {
            switch (operation) {
                case 'hide':
                    await courseApi.hideCourseAttempts(selectedIds);
                    break;
                case 'unhide':
                    await courseApi.unhideCourseAttempts(selectedIds);
                    break;
                case 'generateCertificates':
                    await courseApi.generateCertificates(selectedIds);
                    break;
                default:
                    console.error("Unknown operation:", operation);
            }

            applyFilters();
        } catch (error) {
            console.error("Operation failed:", error);
        }
    };

    useEffect(() => {
        if (!isCustomerAdmin) {            
            clientApi.getAllClients().then(setClients);
            courseApi.getAllCourses().then(setCourses);
        }

        setFilters(initialFilterState);
        applyFilters();
    }, []);

    useEffect(() => {
        const filteredData = rowData.filter(row => {
            const isIncomplete = !row.completed;
            const isHidden = row.hidden;
    
            return (showIncomplete || !isIncomplete) && (showHidden || !isHidden);
        });
    
        setFilteredRowData(filteredData);
    }, [rowData, showIncomplete, showHidden]);  

    return (
        <>
            <Row className="mt-3">
                <Col md={12}>
                    <h3 className="text-secondary">Course Attempts</h3>
                    <hr className="mt-3 border-secondary" />
                </Col>
            </Row>

            <div className="bg-light shadow p-4 rounded-3">
                <Form className="p-2">
                    {/* Main Filters */}
                    <Row className="mb-4">
                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterClientId">
                                <Form.Label className="fw-semibold text-secondary">Client</Form.Label>
                                {!isCustomerAdmin ? (
                                    <Typeahead
                                        clearButton
                                        id="filterClientId"
                                        options={clients.map(client => client.clientName)}
                                        placeholder="All Clients"
                                        selected={filters.clientId ? clients.filter(client => client.id === parseInt(filters.clientId)).map(client => client.clientName) : []}
                                        onChange={(selected) => {
                                            const selectedClient = clients.find(client => client.clientName === selected[0]);
                                            handleFilterChange({ target: { name: 'clientId', value: selectedClient ? selectedClient.id : '' } });
                                        }}                                        
                                    />
                                ) : (
                                    <div className="form-control" style={{backgroundColor: "#f8f9fa"}}>
                                        {adminClientName}
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col lg={6} md={6} sm={12}>
                            <Form.Group controlId="filterCourseId">
                                <Form.Label className="fw-semibold text-secondary">Course</Form.Label>
                                <Typeahead
                                    clearButton
                                    id="filterCourseId"
                                    options={[...new Set(courses.map(course => course.courseName))]}
                                    placeholder="All Courses"
                                    selected={filters.courseId ? courses.filter(course => course.id === parseInt(filters.courseId)).map(course => course.courseName) : []}
                                    onChange={(selected) => {
                                        const selectedCourse = courses.find(course => course.courseName === selected[0]);
                                        handleFilterChange({ target: { name: 'courseId', value: selectedCourse ? selectedCourse.id : '' } });
                                    }}
                                    disabled={courses.length === 0}
                                />
                            </Form.Group>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterPassed">
                                <Form.Label className="fw-semibold text-secondary">Status</Form.Label>
                                <Form.Select name="passed" value={filters.passed} onChange={handleFilterChange}>
                                    <option value="both">All Status</option>
                                    <option value="true">Passed</option>
                                    <option value="false">Failed</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* User Info Filters */}
                    <Row className="mb-4">
                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterName">
                                <Form.Label className="fw-semibold text-secondary">Name</Form.Label>
                                <Form.Control type="text" name="name" value={filters.name} onChange={handleFilterChange} placeholder="Search by name" />
                            </Form.Group>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterEmployeeNumber">
                                <Form.Label className="fw-semibold text-secondary">Employee Number</Form.Label>
                                <Form.Control type="text" name="employeeNumber" value={filters.employeeNumber} onChange={handleFilterChange} placeholder="Enter employee #" />
                            </Form.Group>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterOtherFieldName">
                                <Form.Label className="fw-semibold text-secondary">Other Field Name</Form.Label>
                                <Form.Select 
                                    name="otherFieldName" 
                                    value={filters.otherFieldName} 
                                    onChange={handleFilterChange}                                    
                                    disabled={otherFieldNames.length === 0}
                                >
                                    <option value="">Select Field</option>
                                    {otherFieldNames.map((otherField, index) => (
                                        <option key={index} value={`${otherField.field}#${index}`}>{otherField.fieldName}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterOtherFieldValue">
                                <Form.Label className="fw-semibold text-secondary">Other Field Value</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="otherFieldValue" 
                                    value={filters.otherFieldValue} 
                                    onChange={handleFilterChange}
                                    disabled={otherFieldNames.length === 0}
                                    placeholder="Enter value"
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Date Filters */}
                    <Row className="mb-4">
                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterFromDate">
                                <Form.Label className="fw-semibold text-secondary">From Date</Form.Label>
                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text bg-light">
                                        <i className="bi bi-calendar text-secondary"></i>
                                    </span>
                                    <DatePicker
                                        selected={filters.fromDate}
                                        onChange={(date) => handleDateChange('fromDate', date)}
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                        placeholderText="Select date"
                                    />
                                </div>
                            </Form.Group>
                        </Col>

                        <Col lg={3} md={6} sm={12}>
                            <Form.Group controlId="filterToDate">
                                <Form.Label className="fw-semibold text-secondary">To Date</Form.Label>
                                <div className="input-group flex-nowrap">
                                    <span className="input-group-text bg-light">
                                        <i className="bi bi-calendar text-secondary"></i>
                                    </span>
                                    <DatePicker
                                        selected={filters.toDate}
                                        onChange={(date) => handleDateChange('toDate', date)}
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                        placeholderText="Select date"
                                    />
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>

                    <hr className="text-secondary opacity-25" />

                    {/* Actions Row */}
                    <Row className="mt-4 align-items-center">
                        <Col lg={6} md={12} className="mb-3 mb-lg-0">
                            <Button variant="info" onClick={() => {applyFilters()}} className="me-2 px-4 mb-2 mb-md-2">
                                <i className="bi bi-funnel me-2"></i>Apply Filter
                            </Button>
                            <Button variant="outline-secondary" onClick={clearFilters} className="me-2 mb-2 mb-md-2">
                                <i className="bi bi-x-circle me-2"></i>Reset
                            </Button>
                            <CSVLink 
                                data={filteredRowData} 
                                filename="filtered_course_attempts.csv" 
                                className="btn btn-info mb-2 mb-md-2"
                                disabled={!filteredRowData || filteredRowData.length === 0}
                            >
                                <i className="bi bi-download me-2"></i>Export CSV
                            </CSVLink>
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                            <Form.Group controlId="showIncomplete" className="mb-0">
                                    <Form.Check 
                                        type="switch"
                                        label="Show Incomplete" 
                                        checked={showIncomplete} 
                                        onChange={(e) => setShowIncomplete(e.target.checked)} 
                                    />
                                </Form.Group>
                                {!isCustomerAdmin && (
                                    <Form.Group controlId="showHidden" className="mb-0">
                                        <Form.Check 
                                            type="switch"
                                            label="Show Hidden" 
                                            checked={showHidden} 
                                            onChange={(e) => setShowHidden(e.target.checked)} 
                                        />
                                    </Form.Group>
                                )}
                        </Col>

                        <Col lg={3} md={6} className="text-lg-end">
                            {isCustomerAdmin ? (
                                <Button
                                    variant="info"
                                    onClick={() => handleOptionClick('generateCertificates')}
                                    disabled={!selectedRows || selectedRows.length === 0}
                                    className="px-4"
                                >
                                    <i className="bi bi-file-earmark-pdf me-2"></i>Generate Certificates
                                </Button>
                            ) : (
                                <Dropdown>
                                    <Dropdown.Toggle
                                        variant="info"
                                        id="dropdown-basic"
                                        disabled={!selectedRows || selectedRows.length === 0} 
                                        className="px-4"
                                    >
                                        <i className="bi bi-gear me-2"></i>Actions
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleOptionClick('hide')}>
                                            <i className="bi bi-eye-slash me-2"></i>Hide Records
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleOptionClick('unhide')}>
                                            <i className="bi bi-eye me-2"></i>Unhide Records
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleOptionClick('generateCertificates')}>
                                            <i className="bi bi-file-earmark-pdf me-2"></i>Generate Certificates
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </Col>
                    </Row>
                </Form>
            </div>

            {pageState.pageError && (
                <Alert key="pageError" variant="danger" className="mt-3">
                    <i className="bi bi-exclamation-triangle me-2"></i>
                    {pageState.pageError}
                </Alert>
            )}

            <div className="mt-3 mx-2">
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                    {loading ? (
                        <Alert key="loading" variant="info">
                            <i className="bi bi-hourglass-split me-2"></i>
                            Loading data, please wait...
                        </Alert>
                    ) : (
                        <AgGridReact
                            rowData={filteredRowData}
                            columnDefs={columnDefs}
                            paginationPageSize={20}
                            pagination={true}
                            domLayout='autoHeight'
                            rowSelection="multiple"
                            onSelectionChanged={onSelectionChanged}
                        />
                    )}
                </div>
            </div>
        </>
    );
}
