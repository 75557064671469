import { authHeader } from "../_helpers/authHeader.js";
import axios from "../_helpers/axiosInstance.js";

const login = async (username, password) => {
    const data = { username, password };
    const response = await axios.post('AdminAuth/login', data);
    return response.data.result;
};

const refreshToken = async (jwtToken, refreshToken) => {
    const data = { jwtToken, refreshToken };
    const response = await axios.post('AdminAuth/refresh', data);
    return response.data.result;
};

const resetPassword = async (password, resetKey) => {
    const data = { password, resetKey };
    await axios.post('AdminAuth/password/reset', data);
};

const sendPasswordResetLink = async (email) => {
    const data = { email };
    await axios.post('AdminAuth/password/resetLink', data);
};

const validateResetKey = async (resetKey) => {
    const response = await axios.get(`adminAuth/password/reset/${resetKey}/validate`);
    return response.data.result;
};

const masqueradeAsClientUser = async (clientUserId) => {
    const response = await axios.post('AdminAuth/masquerade', {clientUserId}, {headers: authHeader()});
    return response.data.result;
}

export const adminAuthApi = {
    masqueradeAsClientUser,
    login,
    refreshToken,
    resetPassword,
    sendPasswordResetLink,
    validateResetKey
};