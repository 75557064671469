import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import FeedbackQuestionList from './FeedbackQuestionList.jsx';
import FeedbackQuestionEdit from './FeedbackQuestionEdit.jsx';
import { feedbackQuestionApi } from '../../../api/feedbackQuestion.js';

export default function FeedbackQuestions() {

  const { clientId, tab } = useParams();

  const [questions, setQuestions] = useState([]);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [questionIdToEdit, setQuestionIdToEdit] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  
  const toggleEditing = (questionId) => {
    setQuestionIdToEdit(questionId);
    setIsEditingQuestion(!isEditingQuestion);
  };

  const getFeedbackQuestions = async () => {
    const response = await feedbackQuestionApi.getFeedbackQuestions(clientId);
    setQuestions(response);
  };

  useEffect (() => {
    if (tab === "feedback") {
      getFeedbackQuestions();
    } else {
      setIsEditingQuestion(false);
    }
  }, [tab]);

  useEffect(() => {
    if (isUpdated) {
      getFeedbackQuestions();
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <>
      {isEditingQuestion ? (
        <FeedbackQuestionEdit 
          clientId={clientId} 
          questionId={questionIdToEdit} 
          setIsEditingQuestion={toggleEditing} 
          setIsUpdated={setIsUpdated} 
        />
      ) : (
        <FeedbackQuestionList 
          clientId={clientId} 
          questions={questions} 
          setIsEditingQuestion={toggleEditing} 
          setQuestionIdToEdit={setQuestionIdToEdit} 
          setIsUpdated={setIsUpdated} 
          isEmpty={!questions || questions.length === 0}
        />
      )}
    </>
  );
};
