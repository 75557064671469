import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PostLoginQuestionList from './PostLoginQuestionList.jsx';
import PostLoginQuestionEdit from './PostLoginQuestionEdit.jsx';
import { postLoginQuestionApi } from '../../../api/postLoginQuestion.js';

export default function PostLoginQuestions() {

  const { clientId, tab } = useParams();

  const [questions, setQuestions] = useState([]);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [questionIdToEdit, setQuestionIdToEdit] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const toggleEditing = (questionId) => {
    setQuestionIdToEdit(questionId);
    setIsEditingQuestion(!isEditingQuestion);
  };

  const getPostLoginQuestions = async () => {
    const response = await postLoginQuestionApi.getPostLoginQuestions(clientId);
    setQuestions(response);
  };

  useEffect(() => {
    if (tab === "postlogin") {
      getPostLoginQuestions();
    } else {
      setIsEditingQuestion(false);
    }
  }, [tab]);

  useEffect(() => {
    if (isUpdated) {
      getPostLoginQuestions();
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <>
      {isEditingQuestion ? (
        <PostLoginQuestionEdit 
          clientId={clientId} 
          questionId={questionIdToEdit} 
          setIsEditingQuestion={toggleEditing} 
          setIsUpdated={setIsUpdated} 
        />
      ) : (
        <PostLoginQuestionList 
          questions={questions} 
          setIsEditingQuestion={toggleEditing} 
          setQuestionIdToEdit={setQuestionIdToEdit} 
          setIsUpdated={setIsUpdated} 
          isEmpty={!questions || questions.length === 0}
        />
      )}
    </>
  );
};
