import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Toast, ToastContainer, Modal, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as formik from 'formik';
import * as yup from 'yup';

import { adminAuthApi } from "../../../api/adminAuth.js";
import { clientUserApi } from "../../../api/clientUser.js";

export default function ClientUserEdit(props) {
    
    const { clientId, userId, setIsEditingUser, setIsUpdated } = props;

    const { Formik } = formik;    
    const navigate = useNavigate();

    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedUser, setSelectedUser] = useState(null);
    const [showToast, setShowToast] = useState(false);
    
    const userDefaults = {
        clientId: clientId,
        firstName: "",
        lastName: "",
        phoneNumber: "",
        username: "",
        emailAddress: ""
    }
    
	const getUserData = async () => {
		const user = await clientUserApi.getClientUser(userId);
		setSelectedUser(user);
	};

    const handleResendInvite = async (userId) => {
        await clientUserApi.resendInvite(userId);
    }

	useEffect(() => {
		if (userId) getUserData();
	}, [userId]);

    const schema = yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        username: yup.string().required('Username is required'),
        emailAddress: yup.string().email('Invalid email').required('Email Address is Required'),
    });

    return (
        <>
            <Row className="mb-3">
                {selectedUser ? <h5 className="text-secondary mb-3">Edit Client Admin User</h5> : <h5 className="text-secondary mb-3">Create Client Admin User</h5>}
            </Row>           

            <Formik                                        
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        try {
                            if (selectedUser) {
                                await clientUserApi.updateClientUser(values);
                                setIsUpdated(true);
                                setIsEditingUser(false);
                            } else {
                                await clientUserApi.createClientUser(values);
                                setShowToast(true);
        
                                setTimeout(() => {
                                    navigate(`/client/${clientId}/details`);
                                    setIsUpdated(true);
                                    setIsEditingUser(false);
                                  }, 4000); 
                            }
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Error Submitting User" }})
                            console.error("Error Submitting User" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedUser || userDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-5 pt-2">
                            <Col md={11} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">
                                <Row>
                                    {/* User FirstName Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="firstName">
                                        <Form.Label>First Name:</Form.Label>
                                        <Form.Control
                                            name="firstName"
                                            onChange={handleChange}
                                            value={values.firstName}
                                            type="text" 
                                            isInvalid={touched.firstName && !!errors.firstName}                                        
                                        />
                                        {touched.firstName && (<Form.Control.Feedback type="invalid">
                                            {errors.firstName}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>

                                    {/* User Last Name Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="lastName">
                                        <Form.Label>Last Name:</Form.Label>
                                        <Form.Control
                                            name="lastName"
                                            onChange={handleChange}
                                            value={values.lastName}
                                            type="text" 
                                            isInvalid={touched.lastName && !!errors.lastName}                                        
                                        />
                                        {touched.lastName && (<Form.Control.Feedback type="invalid">
                                            {errors.lastName}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>                                

                                    {/* User Phone Number Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="phoneNumber">
                                        <Form.Label>Phone Number: <i><small>(Optional)</small></i></Form.Label>
                                        <Form.Control
                                            name="phoneNumber"
                                            onChange={handleChange}
                                            value={values.phoneNumber}
                                            type="text" 
                                            isInvalid={touched.phoneNumber && !!errors.phoneNumber}                                        
                                        />
                                        {touched.phoneNumber && (<Form.Control.Feedback type="invalid">
                                            {errors.phoneNumber}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    {/* User Name Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="username">
                                        <Form.Label>Username:</Form.Label>
                                        <Form.Control
                                            name="username"
                                            onChange={handleChange}
                                            value={values.username}
                                            type="text" 
                                            isInvalid={touched.username && !!errors.username}  
                                            disabled={!!selectedUser}                                           
                                        />
                                        {touched.username && (<Form.Control.Feedback type="invalid">
                                            {errors.username}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>

                                    {/* User Email Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="emailAddress">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control
                                            name="emailAddress"
                                            onChange={handleChange}
                                            value={values.emailAddress}
                                            type="email" 
                                            isInvalid={touched.emailAddress && !!errors.emailAddress}                                        
                                        />
                                        {touched.emailAddress && (<Form.Control.Feedback type="invalid">
                                            {errors.emailAddress}
                                        </Form.Control.Feedback>)}                                    
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col md={4}>
                                        {!selectedUser && (
                                            <Form.Text className="text-muted w-20 mt-3">An email will be automatically sent to the client admin with the new username and a link to activate their account.</Form.Text>
                                        )}
                                        {selectedUser && values.emailAddress && !selectedUser.accountActivated && (
                                            <Button 
                                                className="btn-danger w-100 mt-2" 
                                                onClick={() => handleResendInvite(selectedUser.clientUserId)}
                                            >
                                                Resend Invite Email
                                            </Button>
                                        )}  
                                    </Col>
                                    <Col md={{ span: 3, offset: 2 }}>
                                        {/* Submit Button */}
                                        <Button className="btn-info w-100 mt-2" type="submit" disabled={isSubmitting || !dirty}>Save User</Button>
                                    </Col>
                                    <Col md={3}>
                                        {/* Cancel Button */}
                                        <Button className="btn-info w-100 mt-2" type="button" onClick={() => setIsEditingUser(false)}>Cancel</Button>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>

                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                        {/* Toast Notification */}
                        <ToastContainer position="middle-center" className="p-3">
                            <Toast onClose={() => setShowToast(false)} show={showToast} delay={4000} autohide>
                                <Toast.Header className="bg-primary">
                                    <strong className="me-auto">Client Admin Created</strong>
                                </Toast.Header>
                                <Toast.Body className="p-4">Email sent to {values.emailAddress}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </Form> 
                )}
            </Formik>
        </>
    )
}