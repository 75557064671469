import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

import ActionBar from "../../../components/ActionBar.jsx";
import { courseAccessApi } from "../../../api/courseAccess.js";

export default function CourseAccessList(props) {

    const { courseAccesses, setIsAddingCourseAccess, setIsEditingCourseAccess, setCourseAccessIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'clientName', headerName: 'Client Name', flex: 6 },
        { field: 'employeeGroupName', headerName: 'Employee Group Name', flex: 5 },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);

    useEffect(() => {
        if (courseAccesses) {
            setRowData(courseAccesses);
        }
    }, [courseAccesses]);

    const handleEditCourseAccess = (courseAccessId) => {
        setIsEditingCourseAccess(true);
        setCourseAccessIdToEdit(courseAccessId);
    };

    const handleNewCourseAccess = () => {
        setIsAddingCourseAccess(true);
        setCourseAccessIdToEdit(null);
    };

    const handleDeleteCourseAccess = async (courseAccessId) => {
        try {
            await courseAccessApi.deleteCourseAccess(courseAccessId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting course:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Employee Groups attached to this Course.&nbsp;
            <Link to="#" onClick={handleNewCourseAccess} className="text-info">Add a group</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Employee Group Course Access</h5>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                    <Button variant="info" onClick={handleNewCourseAccess}>+ Add Group</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.clientName + ": " + props.data.employeeGroupName}
                                    onDelete={handleDeleteCourseAccess}
                                    onEdit={handleEditCourseAccess}
                                />
                            ),
                        }}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>
        </>
    );
}
