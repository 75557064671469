
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import AdminNav from "../components/AdminNav.jsx";
import AdminHeader from "../components/AdminHeader.jsx";
import { useAuth } from "../contexts/AuthContext.jsx";

export default function ProtectedLayout() {
    const authContext = useAuth();
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    return (
        authContext.signedIn() ? (
            <Container fluid className="d-flex flex-column p-0 gx-0">
                <AdminHeader />
                <Row xs={12} className="h-100 bg-white p-0 gx-0 flex-grow-1">
                    <Col xs={isSidebarCollapsed ? 1 : 2} className="p-0 min-h-100 min-vh-100">
                        <AdminNav
                            isCollapsed={isSidebarCollapsed}
                            onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
                        />
                    </Col>
                    <Col xs={isSidebarCollapsed ? 11 : 10} className="h-100 p-5">
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        ) : (
            <Outlet />
        )
    );
}
