import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Form, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Typeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import ActionBar from "../../../components/ActionBar.jsx";
import { assessmentQuestionApi } from "../../../api/assessmentQuestion.js";
import { courseApi } from "../../../api/course.js";

export default function AssessmentQuestionList(props) {

    const { courseId, questions, setIsEditingQuestion, setQuestionIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'question', headerName: 'Question', flex: 8, rowDrag: true, sortable: true },
        { field: 'questionType', headerName: 'Type', flex: 3 },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);
    const [showModal, setShowModal] = useState(false);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);

    useEffect(() => {
        if (questions) {
            setRowData(questions);
        }
    }, [questions]);

    const handleEditQuestion = (questionId) => {
        setIsEditingQuestion(true);
        setQuestionIdToEdit(questionId);
    };

    const handleNewQuestion = () => {
        setIsEditingQuestion(true);
        setQuestionIdToEdit(null);
    };

    const handleImportQuestions = () => {
        getCourseList();
        setShowModal(true);
    };

    const onRowDragEnd = (params) => {
        let newOrder = params.api.getDisplayedRowAtIndex(0).data.id;
        for (let i = 1; i < params.api.getDisplayedRowCount(); i++) {
            newOrder += ',' + params.api.getDisplayedRowAtIndex(i).data.id;
        }
        const newOrderIntegers = newOrder.split(',').map(idString => parseInt(idString, 10));
        assessmentQuestionApi.reorderAssessmentQuestions(newOrderIntegers).then(() => {
                console.log('Questions order successfully updated on backend');
            })
            .catch((error) => {
                console.error('Error reordering questions on backend:', error);
        });
    };

    const getCourseList = async () => {
        try {
            const response = await courseApi.getAllCourses();
            setCourses(response);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const handleCopyQuestions = async () => {
        if (!selectedCourse) return;

        try {
            await assessmentQuestionApi.copyAssessmentQuestions(selectedCourse, courseId);
            setShowModal(false);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error copying questions:', error);
        }
    };

    const handleExportQuestions = async () => {
        await assessmentQuestionApi.exportAssessmentQuestions(courseId);
    };

    const handleExportQuestionsWithFeedback = async () => {
        await assessmentQuestionApi.exportAssessmentQuestionsWithFeedback(courseId);
    };

    const handleExportAnswerKey = async () => {
        await assessmentQuestionApi.exportAssessmentQuestionAnswerKey(courseId);
    };    

    const handleDeleteQuestion = async (questionId) => {
        try {
            await assessmentQuestionApi.deleteAssessmentQuestion(questionId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Assessment Questions found.&nbsp;
            <Link to="#" onClick={handleNewQuestion} className="text-info">Add a question</Link>&nbsp;
            or&nbsp;
            <Link to="#" onClick={handleImportQuestions} className="text-info">import questions from an existing course</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Assessment Questions</h5>
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                    <Dropdown>
                        <Dropdown.Toggle variant="info">
                            Export
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleExportQuestions}>Export Questions</Dropdown.Item>
                            <Dropdown.Item onClick={handleExportQuestionsWithFeedback}>Export Questions with Feedback</Dropdown.Item>
                            <Dropdown.Item onClick={handleExportAnswerKey}>Export Answer Key</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="info" className="ms-3" onClick={handleNewQuestion}>+ Add Question</Button>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.question}
                                    onDelete={handleDeleteQuestion}
                                    onEdit={handleEditQuestion}
                                />
                            ),
                        }}
                        onRowDragEnd={onRowDragEnd}
                        rowDragManaged={true}
                        suppressMoveWhenRowDragging={true}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>

            <Modal 
                show={showModal} 
                onHide={() => setShowModal(false)}
                centered
                animation={false}
            >
                <Modal.Header className="bg-primary" closeButton>
                    <Modal.Title>Import Questions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light text-center py-5">
                    <Form>
                        <Form.Group className="mt-3 d-flex flex-column align-items-center">
                            <Form.Label>Select a Course to import Assessment Questions from:</Form.Label>
                            <Typeahead
                                clearButton
                                className="w-75 mt-3"
                                id="course-select"
                                options={courses.map(course => course.courseName)}
                                placeholder="Select a course"
                                onChange={(selected) => {
                                    const selectedCourse = courses.find(course => course.courseName === selected[0]);
                                    setSelectedCourse(selectedCourse ? selectedCourse.id : ''); 
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                    <Button variant="info w-25 m-2" onClick={handleCopyQuestions}>Import</Button>
                    <Button variant="info w-25 m-2" onClick={() => setShowModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
