import React, { useState, useEffect } from "react";
import { Container, Col, Form, Row, Button, Alert } from "react-bootstrap";
import * as formik from "formik";
import * as yup from 'yup';

import { accountApi } from "../../../api/account.js";

export default function EditAccount() {

    const { Formik } = formik;    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [editingEmail, setEditingEmail] = useState(false);
    const [editingPassword, setEditingPassword] = useState(false);

	const getAccountData = async () => {
		const account = await accountApi.getAccount();
        console.log("account: " + JSON.stringify(account));
        setSelectedAccount(account);
	};

	useEffect(() => {
        console.log("getting account data");
		getAccountData();
	}, []);

    const passwordSchema = yup.object().shape({
        password: yup.string()
            .min(8, 'Password is too short - should be 8 chars minimum.')
            .max(18, 'Password is too long - should be 18 chars maximum.')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter.')
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter.')
            .matches(/[0-9]/, 'Password must contain at least one number.')
            .required('Required')
    });

    const emailSchema = yup.object().shape({
        emailAddress: yup.string().email('Invalid email').required('Required')
    });

    return (
        <>
            <Row className="mt-3">
                <Col md={12}>
                    <h3 className="text-secondary">Edit Account</h3>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />     
            <Col md={7} className="bg-light shadow p-5 rounded-3 m-5">

                <Row className="mb-5">
                    <Col md={3} className="d-flex my-auto justify-content-end">Username:</Col>
                    <Col md={5}>
                        {selectedAccount ? selectedAccount.userName : 'Loading...'}
                    </Col>
                </Row>

                {/* Email Change Single Line Form */}
                <Formik                                        
                    validationSchema={emailSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        console.log("submit pressed" + JSON.stringify(values));
                        try {
                            try {
                                await accountApi.updateEmailAddress(values);
                                resetForm({ values });
                            } catch (e){
                                setPageState(prevState => {return {...prevState, pageError: "Error Submitting Email" }})
                                console.error("Error Submitting Email" + e);
                            }
                        } finally {
                            setEditingEmail(false);
                            setSubmitting(false);
                        }
                    }}
                    initialValues={selectedAccount || { emailAddress: "" }}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, handleChange, touched, values, isSubmitting, setFieldValue, dirty, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} md={12}>
                            <Row className="d-flex align-items-center">

                                    {/* Email Change Input */}
                                    <Form.Label as={Col} md={3} className="d-flex my-auto justify-content-end">Email Address:</Form.Label>
                                    <Form.Group as={Col} md={5} controlId="emailAddress">
                                        <Form.Control
                                            name="emailAddress"
                                            onChange={handleChange}
                                            value={values.emailAddress}
                                            type="email" 
                                            disabled={!editingEmail}
                                            isInvalid={touched.emailAddress && !!errors.emailAddress}                                        
                                        />
                                        {touched.emailAddress && (
                                            <Form.Control.Feedback type="invalid">{errors.emailAddress}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    {/* Edit/Update Button and Conditional Cancel Button */}
                                    <Col md={2}>
                                        {editingEmail ? (
                                            <Button className="btn-info m-2 w-100" type="submit" disabled={isSubmitting || !dirty}>Update</Button>
                                        ) : (
                                            <Button className="btn-info m-2 w-100" type="button" onClick={() => setEditingEmail(true)}>Edit</Button>
                                        )}
                                    </Col>
                                    <Col md={2}>
                                        {editingEmail && (
                                            <Button className="btn-info m-2 w-100" type="button" onClick={() => {
                                                setFieldValue('emailAddress', selectedAccount.emailAddress, false);  // false to avoid setting the form as dirty
                                                setEditingEmail(false);
                                            }}
                                            disabled={isSubmitting}>Cancel</Button>
                                        )}
                                    </Col>
                            </Row>

                            {pageState.pageError && (
                                <Alert key="pageError" variant="danger mt-3">
                                    {pageState.pageError}
                                </Alert>
                            )}
                        </Form> 
                    )}
                </Formik>

                {/* Password Change Single Line Form */}
                <Formik
                    validationSchema={passwordSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        console.log("submit pressed" + JSON.stringify(values));
                        try {
                            try {
                                await accountApi.updatePassword(values);
                                resetForm({ values: { password: '' } });
                            } catch (e) {
                                setPageState(prevState => { return { ...prevState, pageError: "Error Submitting Password" } })
                                console.error("Error Submitting Password" + e);
                            }
                        } finally {
                            setEditingPassword(false);
                            setSubmitting(false);
                        }
                    }}
                    initialValues={{ password: '' }}
                >
                    {({ handleSubmit, handleChange, touched, values, isSubmitting, setFieldValue, dirty, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} md={12} className="mt-4">
                            <Row className="d-flex align-items-center">

                                    {/* Password Change Input */}
                                    <Form.Label as={Col} md={3} className="d-flex my-auto justify-content-end">Password:</Form.Label>
                                    <Form.Group as={Col} md={5} controlId="password">
                                        <Form.Control
                                            name="password"
                                            onChange={handleChange}
                                            value={values.password}
                                            type="password"
                                            disabled={!editingPassword}
                                            isInvalid={touched.password && !!errors.password}
                                        />
                                        {touched.password && (
                                            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    {/* Edit/Update Button and Conditional Cancel Button */}
                                    <Col md={2}>
                                        {editingPassword ? (
                                            <Button className="btn-info m-2 w-100" type="submit" disabled={isSubmitting || !dirty}>Update</Button>
                                        ) : (
                                            <Button className="btn-info m-2 w-100" type="button" onClick={() => setEditingPassword(true)}>Edit</Button>
                                        )}
                                    </Col>
                                    <Col md={2}>
                                        {editingPassword && (
                                            <Button className="btn-info m-2 w-100" type="button" onClick={() => {
                                                setFieldValue('password', '', false);  // false to avoid setting the form as dirty
                                                setEditingPassword(false);
                                            }}
                                            disabled={isSubmitting}>Cancel</Button>
                                        )}
                                    </Col>
                            </Row>

                            {pageState.pageError && (
                                <Alert key="pageError" variant="danger mt-3">
                                    {pageState.pageError}
                                </Alert>
                            )}
                        </Form>
                    )}
                </Formik>
            </Col>
        </>
    )
}
