import React, { useState } from "react";
import { Container, Col, Form, Row, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import * as formik from "formik";
import * as yup from 'yup';

import ClientUserList from "./ClientUserList.jsx";
import ClientUserEdit from "./ClientUserEdit.jsx";
import { clientApi } from "../../../api/client.js";

export default function ClientDetails(props) {
    const { Formik } = formik; 
    const { tab } = useParams();
    const { client, clientId, users } = props;
    const [uploadedLogo, setUploadedLogo] = useState(null);
    const [logoUrl] = useState(client.logoUrl);

    const [isEditingUser, setIsEditingUser] = useState(false);
    const [userIdToEdit, setUserIdToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    if (isEditingUser && tab !== "details" && tab !== "") {
        setIsEditingUser(false);
    }

    const toggleEditing = (userId) => {
        setIsEditingUser(!isEditingUser);
        setUserIdToEdit(userId);
    };

    const schema = yup.object().shape({
        clientName: yup.string()
            .required('Client Name is required')
            .test(
                'isUnique',
                'Client Name already in use',
                async (value, { context }) => {
                    if (value === client.clientName) return true;
                    const isAvailable = await clientApi.checkClientNameAvailablity(value);
                    return !isAvailable;
                }
            ),
        logo: yup.mixed(),
        canViewReports: yup.boolean(),
        payPerUse: yup.boolean(),
        purchaserName: yup.string()
    });

    return (
        <>
            <Row className="mb-3">
                <h5 className="text-secondary mb-3">Client Details</h5>
                <Col>                    
                    <Formik
                        validationSchema={schema}
                        onSubmit={async (values, { setSubmitting, resetForm }) => {
                            try { 
                                await clientApi.updateClient({...values, clientId});
                            } catch (e) {
                                setPageState(prevState => {return {...prevState, pageError: "Error Submitting Client Edit" }})
                                console.error("Error Submitting Client Edit" + e);
                            } finally {
                                setSubmitting(false);
                                resetForm({ values });
                            }
                        }}
                        initialValues={client}
                    >
                        {({ handleSubmit, handleChange, setFieldValue, touched, values, isSubmitting, dirty, errors }) => (
                            <Form noValidate onSubmit={handleSubmit} md={12}>
                                <Row className="ms-5 pt-3">
                                    <Col md={7} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">    
                                        <Row>
                                            <Form.Group className="mb-3" controlId="clientName">
                                                <Form.Label>Client Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="clientName"
                                                    value={values.clientName}
                                                    onChange={handleChange}
                                                    isInvalid={touched.clientName && !!errors.clientName}
                                                />
                                                {touched.clientName && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.clientName}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3 d-flex align-items-center">
                                            <Col md={4}>
                                                <Form.Group md={4} className="mb-3 mt-4" controlId="canViewReports">
                                                    <Form.Check
                                                        name="canViewReports"
                                                        label="Can View Reports"
                                                        onChange={handleChange}
                                                        checked={values.canViewReports}
                                                        type="checkbox"
                                                    />
                                                </Form.Group>
                                                <Form.Group md={4} className="mb-3" controlId="payPerUse">
                                                    <Form.Check
                                                        name="payPerUse"
                                                        label="Pay Per Use"
                                                        onChange={handleChange}
                                                        checked={values.payPerUse}
                                                        type="checkbox"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Button type="submit" className="btn-info ms-auto w-25" disabled={isSubmitting || !dirty}>Save Changes</Button>
                                            <Button as={Link} to="/clients" type="button" className="btn-info w-25 mx-3">Cancel</Button>
                                        </Row>
                                    </Col>
                                    <Col md={{ span: 3, offset: 1 }}>
                                        {uploadedLogo ? (
                                            <Container className="p-5 mb-3 border rounded-3 shadow d-flex justify-content-center">
                                                <img src={URL.createObjectURL(uploadedLogo)} style={{ maxWidth: "200px" }} />
                                            </Container>
                                        ) : logoUrl ? (
                                            <Container className="p-5 mb-3 border rounded-3 shadow d-flex justify-content-center">
                                                <img src={logoUrl} style={{ maxWidth: "200px" }} />
                                            </Container>
                                        ) : null}
                                        <Form.Group controlId="logo" className="mb-3">
                                            <Form.Control
                                                name="logo"
                                                onChange={(event) => {
                                                    setUploadedLogo(event.currentTarget.files[0]);
                                                    setFieldValue('logo', event.currentTarget.files[0]);
                                                }}
                                                type="file"
                                                isInvalid={touched.logo && !!errors.logo}
                                            />
                                            {touched.logo && <Form.Control.Feedback type="invalid">{errors.logo}</Form.Control.Feedback>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>                    
                </Col>
            </Row>
            <Row className="mb-3 mt-1">
                {isEditingUser ? (
                    <ClientUserEdit 
                        client={client} 
                        clientId={clientId} 
                        userId={userIdToEdit} 
                        setIsEditingUser={toggleEditing} 
                        setIsUpdated={setIsUpdated} 
                    />
                ) : (
                    <ClientUserList 
                        clientId={clientId} 
                        users={users} 
                        setIsEditingUser={toggleEditing} 
                        setUserIdToEdit={setUserIdToEdit} 
                        isUpdated={isUpdated} 
                        setIsUpdated={setIsUpdated} 
                    />
                )}
            </Row>
        </>            
    );
}
