import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getCourseCredits = async (clientId) => {
    const response = await axios.get(`CourseCredit/client/${clientId}`, { headers: authHeader() });
    return response.data;
}

const updateCourseCredits = async (courseCreditId, totalCredits, creditsUsed) => {
    const data = {courseCreditId, totalCredits, creditsUsed};
    const response = await axios.put(`CourseCredit`, data, { headers: authHeader() });
    return response.data;
}

export const courseCreditApi = {
    getCourseCredits,
    updateCourseCredits,
}