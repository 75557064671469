import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";

import ActionBar from "../../../components/ActionBar.jsx";
import { courseAccessApi } from "../../../api/courseAccess.js";

export default function EmployeeGroupCourseList(props) {

    const { courseAccesses, setIsEditingCourseAccess, setIsAddingCourseAccess, setCourseAccessIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'courseName', headerName: 'Course Name', rowDrag: true, flex: 5 },
        { field: 'doNotReportScore', headerName: 'Do Not Report Score', flex: 3 },
        { field: 'showScoreToParticipant', headerName: 'Show Score to Participant', flex: 3 },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);

    useEffect(() => {
        if (courseAccesses) {
            setRowData(courseAccesses);
        }
    }, [courseAccesses]);

    const handleEditCourseAccess = (courseAccessId) => {
        setIsEditingCourseAccess(true);
        setCourseAccessIdToEdit(courseAccessId);
    };

    const handleNewCourseAccess = () => {
        setIsAddingCourseAccess(true);
        setCourseAccessIdToEdit(null);
    };

    const onRowDragEnd = (params) => {
        let newOrder = params.api.getDisplayedRowAtIndex(0).data.id;
        for (let i = 1; i < params.api.getDisplayedRowCount(); i++) {
            newOrder += ',' + params.api.getDisplayedRowAtIndex(i).data.id;
        }
        const newOrderIntegers = newOrder.split(',').map(idString => parseInt(idString, 10));
        courseAccessApi.reorderCourseAccesses(newOrderIntegers).then(() => {
                console.log('Course order successfully updated on backend');
            })
            .catch((error) => {
                console.error('Error reordering courses on backend:', error);
        });
    };

    const handleDeleteCourseAccess = async (courseAccessId) => {
        try {
            await courseAccessApi.deleteCourseAccess(courseAccessId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting course:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Courses attached to this Employee Group.&nbsp;
            <Link to="#" onClick={handleNewCourseAccess} className="text-info">Add a course</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Employee Group Course Access</h5>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                    <Button variant="info" onClick={handleNewCourseAccess}>+ Add Course</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.courseName}
                                    onDelete={handleDeleteCourseAccess}
                                    onEdit={handleEditCourseAccess}
                                />
                            ),
                        }}                        
                        onRowDragEnd={onRowDragEnd}
                        rowDragManaged={true}
                        suppressMoveWhenRowDragging={true}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>
        </>
    );
}
