import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getAllCourses = async () => {
    const response = await axios.get('Course', { headers: authHeader() });
    return response.data.result;
}

const getPPUCourses = async () => {
    const response = await axios.get('Course/ppu', { headers: authHeader() });
    return response.data.result;
}

const getCourse = async (courseId) => {
    const response = await axios.get(`Course/${courseId}`, { headers: authHeader() });
    return response.data.result;
}

const getCourseAttempts = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.post('CourseAttempt', data, { headers: authHeader() });
    return response.data.result;
}

const createCourse = async (course) => {
    const data = JSON.stringify(course);
    const response = await axios.post('Course', data, { headers: authHeader() });
    return response.data.result;
}

const updateCourse = async (course) => {
    const data = JSON.stringify(course);
    const response = await axios.put('Course', data, { headers: authHeader() });
    return response.data.result;
}

const deleteCourse = async (courseId) => {
    const response = await axios.delete(`Course/${courseId}`, { headers: authHeader() });
    return response.data.result;
}

const uploadCourseware = async (values) => {
    const formData = new FormData();
    formData.append('CourseId', values.courseId);
    formData.append('Courseware', values.courseware);
    formData.append('UserRole', 0);

    const response = await axios.post('Course/courseware', formData, { headers: { 'content-type': 'multipart/form-data', ...authHeader() } });
    return response.data.result;
}

const hideCourseAttempts = async (courseAttemptIds) => {
    const data = {courseAttemptIds: courseAttemptIds};
    const response = await axios.put('CourseAttempt/hide', data, { headers: authHeader() });
    return response.data.result;
}

const unhideCourseAttempts = async (courseAttemptIds) => {
    const data = {courseAttemptIds: courseAttemptIds};
    const response = await axios.put('CourseAttempt/unhide', data, { headers: authHeader() });
    return response.data.result;
}

const generateCertificates = async (courseAttemptIds) => {
    const data = {courseAttemptIds: courseAttemptIds}; 
    return axios.post('Certificate/batch', data, {
        headers: authHeader(),
        responseType: 'blob' 
    }).then(response => {
        const filename = getFileNameFromContentDisposition(response.headers['content-disposition']);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const getFileNameFromContentDisposition = (contentDisposition) => {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    if (matches != null && matches[1]) {
        console.log(matches[1].replace(/['"]/g, ''));
        return matches[1].replace(/['"]/g, '');
    }
    return 'certificates.zip';
}

const getFeedback = async (values) => {
    const data = JSON.stringify(values);
    console.log("getFeedback: " + data);
    const response = await axios.post('Feedback', data, { headers: authHeader() });
    return response.data.result;
}

const reorderPPUCourses = async (courses) => {
    const data = {
        orderedPPUCourses: courses,
    };
    const response = await axios.put('Course/ppu/reorder', data, { headers: authHeader() });
    return response.data.result;
}

export const courseApi = {
    getAllCourses,
    getPPUCourses,
    getCourse,
    getCourseAttempts,
    createCourse,
    updateCourse,
    deleteCourse,
    uploadCourseware,
    hideCourseAttempts,
    unhideCourseAttempts,
    generateCertificates,
    getFeedback,
    reorderPPUCourses
}