import React, {useState} from "react";
import {Form, Button, Col, Row, Container} from "react-bootstrap";
import {useNavigate, Link} from "react-router-dom";
import * as formik from 'formik';
import * as yup from 'yup';

import {courseApi} from "../../../api/course.js";
import TitleElement from "../../../components/TitleElement.jsx";

export default function CreateCourse() {

    const navigate = useNavigate();
    const [pageState, setPageState] = useState({pageError: null});
    const { Formik } = formik;

    const courseDefaults = {
        courseName: "",
        displayName: "",
        passingScore: 0,
        randomizeQuestions: true,
        askForFeedback: true,
        useClientLogoForCertificate: true,
        category: "",
        allowToCompleteOnFail: true
    };

    const schema = yup.object().shape({
        courseName: yup.string().required("Course Name is required"),
        displayName: yup.string().required("Display Name is required"),
        passingScore: yup.number()
            .required("Passing Score is required")
            .min(1, "Please enter a valid score"),
        randomizeQuestions: yup.boolean(),
        askForFeedback: yup.boolean(),
        useClientLogoForCertificate: yup.boolean(),
        category: yup.string(),
        allowToCompleteOnFail: yup.boolean(),
    });

    return (
        <>
            <TitleElement title="New Course" />

            <Formik
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        try {
                            const courseId = await courseApi.createCourse(values);
                            console.log("created course: " + courseId);
                            navigate(`/course/${courseId}`);
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Error Submitting New Course [CreateCourse.jsx]" }})
                            console.error("Error Submitting New Course" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={courseDefaults}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-3 pt-3">
                            <Col md={6} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">

                                {/* Course Name Input */}
                                <Form.Group className="mb-3" controlId="courseName">
                                    <Form.Label>Course Name: </Form.Label>
                                    <Form.Control
                                        name="courseName"
                                        onChange={handleChange}
                                        value={values.courseName}
                                        type="text" 
                                        isInvalid={touched.courseName && !!errors.courseName}                                        
                                    />
                                    {touched.courseName && (<Form.Control.Feedback type="invalid">
                                        {errors.courseName}
                                    </Form.Control.Feedback>)}
                                </Form.Group>

                                {/* Course Display Name Input */}
                                <Form.Group className="mb-3" controlId="displayName">
                                    <Form.Label>Display Name: </Form.Label>
                                    <Form.Control
                                        name="displayName"
                                        onChange={handleChange}
                                        value={values.displayName}
                                        type="text"
                                        isInvalid={touched.displayName && !!errors.displayName}
                                    />
                                    {touched.displayName && (<Form.Control.Feedback type="invalid">
                                        {errors.displayName}
                                    </Form.Control.Feedback>)}
                                </Form.Group> 

                                {/* Course Category Input */}
                                <Form.Group className="mb-3" controlId="category">
                                    <Form.Label>Category: </Form.Label>
                                    <Form.Control
                                        name="category"
                                        onChange={handleChange}
                                        value={values.category}
                                        type="text"
                                        isInvalid={touched.category && !!errors.category}
                                    />
                                    {touched.category && (<Form.Control.Feedback type="invalid">
                                        {errors.category}
                                    </Form.Control.Feedback>)}
                                </Form.Group> 

                                <Row>
                                    {/* Courseware Update User */}
                                    <Form.Group as={Col} className="mb-3" controlId="canUploadCourseware">
                                        <Form.Label>Can Upload Courseware: </Form.Label>
                                        <Form.Select
                                            name="canUploadCourseware"
                                            onChange={handleChange}
                                            value={values.canUploadCourseware}
                                            isInvalid={touched.canUploadCourseware && !!errors.canUploadCourseware}
                                        >
                                            <option value="firstName">Only Admin</option>
                                            <option value="lastName">Client and Admin</option>
                                        </Form.Select>
                                        
                                        {touched.canUploadCourseware && (<Form.Control.Feedback type="invalid">
                                            {errors.canUploadCourseware}
                                        </Form.Control.Feedback>)}
                                    </Form.Group> 

                                    {/* Passing Score Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="passingScore">
                                        <Form.Label>Passing Score: </Form.Label>
                                        <Form.Control
                                            name="passingScore"
                                            onChange={handleChange}
                                            value={values.passingScore}
                                            type="text"
                                            isInvalid={touched.passingScore && !!errors.passingScore}
                                        />
                                        {touched.passingScore && (<Form.Control.Feedback type="invalid">
                                            {errors.passingScore}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Col md={{span: 4, offset: 1}}>
                                <Row className="bg-light shadow p-4 rounded-3 mb-5 mt-0">
                                    <Col>
                                        {/* Randomize Questions */}
                                        <Form.Group className="mb-3" controlId="randomizeQuestions">
                                            <Form.Check
                                                name="randomizeQuestions"
                                                type="checkbox"
                                                label="Randomize questions"
                                                onChange={handleChange}
                                                checked={values.randomizeQuestions}
                                                isInvalid={touched.randomizeQuestions && !!errors.randomizeQuestions}
                                            />
                                            {touched.randomizeQuestions && (<Form.Control.Feedback type="invalid">
                                                {errors.randomizeQuestions}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>

                                        {/* Ask for Feedback */}
                                        <Form.Group className="mb-3" controlId="askForFeedback">
                                            <Form.Check
                                                name="askForFeedback"
                                                type="checkbox"
                                                label="Ask for Feedback"
                                                onChange={handleChange}
                                                checked={values.askForFeedback}
                                                isInvalid={touched.askForFeedback && !!errors.askForFeedback}
                                            />
                                            {touched.askForFeedback && (<Form.Control.Feedback type="invalid">
                                                {errors.askForFeedback}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>

                                        {/* Use Logo for Certificate */}
                                        <Form.Group className="mb-3" controlId="useClientLogoForCertificate">
                                            <Form.Check
                                                name="useClientLogoForCertificate"
                                                type="checkbox"
                                                label="Use Client Logo for Certificate"
                                                onChange={handleChange}
                                                checked={values.useClientLogoForCertificate}
                                                isInvalid={touched.useClientLogoForCertificate && !!errors.useClientLogoForCertificate}
                                            />
                                            {touched.useClientLogoForCertificate && (<Form.Control.Feedback type="invalid">
                                                {errors.useClientLogoForCertificate}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>

                                        {/* Allow Complete on Fail */}
                                        <Form.Group className="mb-3" controlId="allowToCompleteOnFail">
                                            <Form.Check
                                                name="allowToCompleteOnFail"
                                                type="checkbox"
                                                label="Allow students to submit results regardless of outcome"
                                                onChange={handleChange}
                                                checked={values.allowToCompleteOnFail}
                                                isInvalid={touched.allowToCompleteOnFail && !!errors.allowToCompleteOnFail}
                                            />
                                            {touched.allowToCompleteOnFail && (<Form.Control.Feedback type="invalid">
                                                {errors.allowToCompleteOnFail}
                                            </Form.Control.Feedback>)}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Create Course Button */}
                                <Row className="pt-5">
                                    <Col md={6}>
                                        <Button className="btn-info mb-5 w-100 text-white" type="submit" disabled={isSubmitting || !dirty}>Save Course</Button>
                                    </Col>
                                    <Col md={6}>
                                        <Button as={Link} to="/courses" className="btn-info mb-5 w-100 text-white" type="button">Cancel</Button>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form>
                )}
            </Formik>
        </>
    )
}
