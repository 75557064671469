import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as formik from 'formik';
import * as yup from 'yup';

import { userApi } from "../../api/user.js";
import { adminUserApi } from "../../api/adminUser.js";

export default function ActivateAdminUserPage() {
    const {Formik} = formik;
    const {activationKey} = useParams();
    const [activationSuccessful, setActivationSucessful] = useState(false);
    const [activationKeyValid, setActivationKeyValid] = useState(true);
    const schema = yup.object().shape({
        password: yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+[\]{};':"\\|,.<>/?`~\-]{8,}$/,
                "Password must be at least 8 characters, with an uppercase letter, lowercase letter, a number, and may include special characters."
            ),
        passwordConfirm: yup.string()
            .required("Password Confirm is required")
            .oneOf([yup.ref('password')], 'Passwords must match'),
    });
    

    useEffect(() => {
        const validateKey = async (key) => {
            const result = await userApi.validateUserActivationKey(key);
            setActivationKeyValid(result.valid);
        }
        if (activationKey) {
            validateKey(activationKey);
        } else {
            setActivationKeyValid(false);
        }

    }, [activationKey]);

    if (!activationKeyValid) {
        return (
        <Row>
            <Col md={12}>
                <h4 className="text-secondary">Activate Admin User</h4>
                <hr className="border-secondary mb-5"/>
                <Alert variant={"danger"}>Sorry the activation link used is either expired or invalid.  Ask your account admin to resend the activation link.</Alert>
                <Button className="btn-info w-100 mt-3" href={'/login'}>Go to Login</Button>
            </Col>
        </Row>


        );

    } else if (activationSuccessful){
        return (
        <Row>
            <Col md={12}>
                <h4 className="text-secondary">Activate Admin User</h4>
                <hr className="border-secondary mb-5"/>
                <p>Your password has been set and your account is now activated. You may now login.</p>
                <Button  className="btn-info w-100 mt-3" href={'/login'}>Go to Login</Button>
            </Col>
        </Row>
        );
    } else {
        return (
            <div>
                <Formik
                    validationSchema={schema}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            setSubmitting(true);
                            await adminUserApi.activateAdminUser(activationKey, values.password);
                            setActivationSucessful(true);
                        } finally {
                            setSubmitting(false);
                        }

                    }}
                    initialValues={{
                        password: '',
                        passwordConfirm: '',
                    }}
                >
                    {({ handleSubmit, handleChange, touched, values, isSubmitting, errors }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <h4 className="text-secondary">Activate Admin User</h4>
                            <hr className="border-secondary mb-5"/>
                            <p>Please set your password below then click the "Set password and activate account" button.</p>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="password">
                                    <Form.Label>Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            isInvalid={touched.password && !!errors.password}
                                        />
                                        {touched.password && (<Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>)}
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="passwordConfirm">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Control
                                            type="password"
                                            name="passwordConfirm"
                                            value={values.passwordConfirm}
                                            onChange={handleChange}
                                            isInvalid={touched.passwordConfirm && !!errors.passwordConfirm}
                                        />
                                        {touched.passwordConfirm && (<Form.Control.Feedback type="invalid">
                                            {errors.passwordConfirm}
                                        </Form.Control.Feedback>)}
                                    </InputGroup>
                                </Form.Group>
                            </Row>

                            <Button className="btn-info w-100 mt-3" type="submit" disabled={isSubmitting}>Set password and activate account.</Button>
                        </Form>
                    )}
                </Formik>
            </div>

        );
    }
}
