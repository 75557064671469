import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeGroupList from './EmployeeGroupList.jsx';
import EmployeeGroupEdit from './EmployeeGroupEdit.jsx';
import { employeeGroupApi } from '../../../api/employeeGroup.js';

export default function EmployeeGroups(props) {
    const { clientId, tab } = useParams();
    const { client } = props;
    const [groups, setGroups] = useState([]);
    const [isEditingGroup, setIsEditingGroup] = useState(false);
    const [groupIdToEdit, setGroupIdToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    const toggleEditing = (groupId) => {
        setGroupIdToEdit(groupId);
        setIsEditingGroup(!isEditingGroup);
    };

    const getEmployeeGroups = async () => {
        const response = await employeeGroupApi.getEmployeeGroups(clientId);
        setGroups(response);
    };

    useEffect(() => {
        if (tab === "groups") {
            getEmployeeGroups();
        } else {
            setIsEditingGroup(false);
        }
    }, [tab]);

    useEffect(() => {
        if (isUpdated) {
            getEmployeeGroups();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <>
            {isEditingGroup ? (
                <EmployeeGroupEdit 
                    client={client} 
                    clientId={clientId} 
                    groupId={groupIdToEdit} 
                    setIsEditingGroup={toggleEditing} 
                    setIsUpdated={setIsUpdated} 
                />
            ) : (
                <EmployeeGroupList 
                    clientId={clientId} 
                    groups={groups} 
                    setIsEditingGroup={toggleEditing} 
                    setGroupIdToEdit={setGroupIdToEdit} 
                    isUpdated={isUpdated} 
                    setIsUpdated={setIsUpdated} 
                    isEmpty={!groups || groups.length === 0}
                />
            )}
        </>
    );
}
