export default function formatDate(dateString) {

    if (!dateString) {
      return '';
    }

    // Convert to string if it's not a string
    if (typeof dateString !== 'string') {
      dateString = dateString.toString();
    }
    
    const parts = dateString.split('T');
    const datePart = parts[0];
    const timePart = parts[1].split('.')[0]; 
  
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    };
  
    const parsedDate = new Date(datePart + 'T' + timePart);
  
    // Handle potential invalid date scenarios
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid date format:", dateString);
      return "Invalid Date";
    }
  
    return new Intl.DateTimeFormat(navigator.language, options).format(parsedDate);
  }
  