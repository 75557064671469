import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { invoiceApi } from "../../../api/invoice.js";
import { Col, Row } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import formatDate from "../../../_helpers/formatDate.js";
import LoadingBar from "../../../components/LoadingBar.jsx";

function InvoiceDetailsPage() {
    const [invoice, setInvoice] = useState(null);
    const { invoiceId } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            const invoiceDetails = await invoiceApi.getInvoice(invoiceId);
            console.log(invoiceDetails);
            setInvoice(invoiceDetails);
        };
        getInvoiceDetails();
    }, [invoiceId]);

    if (!invoice) {
        return <LoadingBar className="mt-5" />;
    }

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h3 className="text-secondary">Invoice #{invoice.id}</h3>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3">
                <Col md={12} className="ag-theme-alpine">
                    <InvoiceTable lineItems={invoice.lineItems} />
                </Col>
            </Row>
            <Row className="">
                <Col md={12} className="text-end">
                    <InvoiceTotals subTotal={invoice.subTotal} tax={invoice.tax} total={invoice.total} />
                </Col>
            </Row>
            <PaymentInformation
                datePaid={invoice.datePaid}
                cardType={invoice.cardType}
                lastFour={invoice.lastFour}
            />
        </>
    );
}

function InvoiceTable({ lineItems }) {
    const [columnDefs] = useState([
        { field: 'courseDisplayName', headerName: 'Course', flex: 2, resizable: false },
        { field: 'quantity', headerName: 'Quantity', flex: 1, resizable: false },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            resizable: false,
            valueFormatter: params => {
                const price = params.value;
                return price !== null && price !== undefined ? `$${price.toFixed(2)}` : 'N/A';
            }
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            resizable: false,
            valueFormatter: params => {
                const price = params.data.price;
                const quantity = params.data.quantity;
                return price !== null && price !== undefined && quantity !== null && quantity !== undefined
                    ? `$${(price * quantity).toFixed(2)}`
                    : 'N/A';
            }
        }
    ]);

    const [rowData] = useState(lineItems.map(item => ({
        ...item,
        total: item.price * item.quantity,
    })));

    return (
        <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            domLayout='autoHeight'
        />
    );
}

function InvoiceTotals({ subTotal, tax, total }) {
    return (
        <div className="invoice-totals">
            <Row>
                <Col md={{ span: 3, offset: 9 }}>
                    <p style={{ backgroundColor: '#B3D3F444', padding: '10px' }}>Subtotal: {subTotal !== null && subTotal !== undefined ? `$${subTotal.toFixed(2)}` : 'N/A'}</p>
                    <p style={{ backgroundColor: '#B3D3F444', padding: '10px' }}>Tax: {tax !== null && tax !== undefined ? `$${tax.toFixed(2)}` : 'N/A'}</p>
                    <p style={{ backgroundColor: '#B3D3F444', padding: '10px' }}><strong>Total: {total !== null && total !== undefined ? `$${total.toFixed(2)}` : 'N/A'}</strong></p>
                </Col>
            </Row>
        </div>
    );
}

function PaymentInformation({ datePaid, cardType, lastFour }) {
    return (
        <div className="payment-information mt-5">
            <h3 className="text-secondary">Payment Information</h3>
            <hr className="border-secondary" />
            <Row>
                <Col md={6}>
                    <strong>Payment Date:</strong> {formatDate(datePaid)}
                </Col>
                <Col md={6}>
                    <strong>Credit Card Type:</strong> {cardType}
                </Col>
                <Col md={6}>
                    <strong>Credit Card Number:</strong> {`**** **** **** ${lastFour}`}
                </Col>
            </Row>
        </div>
    );
}

export default InvoiceDetailsPage;
