import React, { useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from 'react-router-dom';

import formatDate from "../../../_helpers/formatDate";

function CustomerInvoiceTable({ invoiceList }) {
    const navigate = useNavigate();
    const { clientId } = useParams();

    const navigateToInvoice = useCallback((invoiceId) => {
        navigate(`/invoices/${invoiceId}`);
    }, [navigate]);

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'orderDate',
            headerName: 'Invoice Date',
            flex: 3,
            valueFormatter: params => params.value ? formatDate(params.value) : ''
        },
        {
            field: 'totalCredits',
            headerName: 'Credits',
            flex: 1
        },
        {
            field: 'total',
            headerName: 'Amount',
            flex: 1,
            valueFormatter: params => params.value ? `$${params.value.toFixed(2)}` : ''
        },
        {
            field: 'datePaid',
            headerName: 'Paid',
            flex: 3,
            valueFormatter: params => params.value ? formatDate(params.value) : ''
        },
        {
            headerName: '',
            field: 'id',
            flex: 1,
            cellRenderer: (params) => {
                const invoice = params.data;
                return invoice.datePaid ? (
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigateToInvoice(params.value)}
                    >
                        <i className="bi bi-search align-middle"> </i>Details
                    </span>
                ) : (
                    <span
                        style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => navigateToInvoice(params.value)}
                    >
                        <i className="bi bi-currency-dollar align-middle"></i> Pay Now
                    </span>
                );
            },
            cellStyle: (params) => {
                if (!params.data.datePaid) {
                    return { backgroundColor: '#ffcccb' }; // Light pink for unpaid invoices
                }
                return null;
            }
        },
    ]);

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
            <AgGridReact
                rowData={invoiceList}
                columnDefs={columnDefs}
                domLayout='autoHeight'
            />
        </div>
    );
}

export default CustomerInvoiceTable;
