import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { useLocation, NavLink } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.jsx";
import "./AdminNav.css";

export default function AdminNav({ isCollapsed, onToggle }) {
    const location = useLocation();
    const authContext = useAuth();
    const [autoCollapsed, setAutoCollapsed] = useState(false);

    const role = authContext.authentication.role;
    const isSuperUser = role === 'SuperUser';
    const isAdmin = role === 'Admin';
    const isCustomerAdmin = role === 'CustomerAdmin';

    // Check if the current location matches either "/" or "/clients" to set highlight if clients is accessed through root/default
    const isActiveClientList = location.pathname === "/" || location.pathname === "/clients";

    // Handle auto-collapse on window resize
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1000) {
                setAutoCollapsed(true);
            } else {
                setAutoCollapsed(false);
            }
        };

        window.addEventListener("resize", handleResize);

        // Initialize state based on the current window size
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Nav
            variant="highlight"
            className={`bg-primary d-flex flex-column h-100 pt-5 ${isCollapsed || autoCollapsed ? 'collapsed' : ''}`}
            activeKey={isActiveClientList ? "/clients" : location.pathname}
        >

            {(isSuperUser || isAdmin) && (
                <span style={{ fontSize: 14 }}>
                    <Nav.Link  className={`nav-link my-1 px-2 py-2 bi-people-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/clients" title="Client List" alt="Client List Icon"> {isCollapsed || autoCollapsed ? "" : " Client List"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-list-check ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/attempts" title="Course Attempts" alt="Course Attempts Icon"> {isCollapsed || autoCollapsed ? "" : " Course Attempts"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-bar-chart-line-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/feedback" title="Feedback" alt="Feedback Icon"> {isCollapsed || autoCollapsed ? "" : " Feedback"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-person-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/account" title="Account" alt="Account Icon"> {isCollapsed || autoCollapsed ? "" : " Account"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-mortarboard-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/courses" title="Courses" alt="Courses Icon"> {isCollapsed || autoCollapsed ? "" : " Courses"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-tag-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/payperuse" title="Pay Per Use" alt="Pay Per Use Icon"> {isCollapsed || autoCollapsed ? "" : " Pay Per Use"}</Nav.Link>
                </span>
            )}
            {isCustomerAdmin && (
                <span style={{ fontSize: 14 }}>
                    <Nav.Link className={`my-1 px-2 py-2 bi-list-check ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/attempts" title="Course Attempts" alt="Course Attempts Icon"> {isCollapsed || autoCollapsed ? "" : " Course Attempts"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-bar-chart-line-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/feedback" title="Feedback" alt="Feedback Icon"> {isCollapsed || autoCollapsed ? "" : " Feedback"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-person-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/account" title="Account" alt="Account Icon"> {isCollapsed || autoCollapsed ? "" : " Account"}</Nav.Link>
                    <Nav.Link className={`my-1 px-2 py-2 bi-tag-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/invoices" title="Invoices" alt="Invoices Icon"> {isCollapsed || autoCollapsed ? "" : " Invoices"}</Nav.Link>
                </span>
            )}
            {isSuperUser && (
                <span style={{ fontSize: 14 }}>
                    <Nav.Link className={`my-1 px-2 py-2 bi-shield-lock-fill ${isCollapsed || autoCollapsed ? 'text-center' : 'px-5'}`} as={NavLink} to="/admin-users" title="Admin Users" alt="Admin Users Icon"> {isCollapsed || autoCollapsed ? "" : " Admin Users"}</Nav.Link>
                </span>
            )}

            {!autoCollapsed && (
                <>
                {isCollapsed || autoCollapsed ? (
                    <div className="toggle-btn-condensed text-center bg-info mt-5 p-2" onClick={onToggle}>
                        <i className="bi bi-chevron-double-right text-white p-1" style={{ fontSize: 14, borderRadius: 2 }} title="Expand Menu" alt="Expand Menu Icon"></i>
                    </div>
                ) : (
                    <div className="toggle-btn-expanded text-center bg-info mt-5 p-2" onClick={onToggle}>
                        <i className="bi bi-chevron-double-left text-white p-1" style={{ fontSize: 14, borderRadius: 2 }} title="Collapse Menu" alt="Collapse Menu Icon"></i>
                    </div>
                )}
                </>     
            )}
        </Nav>
    );
}
