import React, { useState, useCallback, useEffect } from "react";
import { Alert, Col, Row, Button, Form } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import { CSVLink } from "react-csv";
import { courseApi } from "../../../api/course.js";
import { clientApi } from "../../../api/client.js";
import { useAuth } from "../../../contexts/AuthContext.jsx";
import * as XLSX from "xlsx";


function ExportToExcel({ feedbackData , excludeCols =[]}) {
    const handleExport = () => {  
        event.preventDefault();

      // 1) Create a sanitized copy of your data that omits columns in excludeCols
      const sanitizedData = feedbackData.map((row) => {
        const newRow = { ...row };
        excludeCols.forEach((col) => delete newRow[col]);
        return newRow;
      });

      //Convert JSON array to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(sanitizedData);
  
      //Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Feedback");
      //Auto-size columns
      autoWidth(worksheet, sanitizedData);
      //Prompt the user to download an Excel file
      XLSX.writeFile(workbook, "feedback.xlsx");
    };
  
    return (
      <button type='button' disabled={!feedbackData || feedbackData.length === 0} onClick={handleExport} className="btn btn-info">
        Export XLSX Report
      </button>
    );
  }
  function autoWidth(worksheet, data) {
    // If there’s no data, just return
    if (!data || data.length === 0) return;
  
    // Keys (columns) based on the first row
    const columns = Object.keys(data[0]);
  
    // For each column, determine the max length in that column
    const colWidths = columns.map((col) => {
      let maxLength = col.length; // start w/ the column name length
      data.forEach((row) => {
        const cellValue = row[col] ? row[col].toString() : "";
        maxLength = Math.max(maxLength, cellValue.length);
      });
      // Add some padding to make it look nice
      return { wch: maxLength + 2 };
    });
  
    // Assign column widths to the worksheet
    worksheet["!cols"] = colWidths;
  }
function flattenFeedbackData(feedback) {
  const rows = [];

  feedback.forEach(client => {
    const { id: clientId, name: clientName, courses } = client;

    courses.forEach(course => {
      const { id: courseId, name: courseName, submissions } = course;

      submissions.forEach(sub => {
        const { submissionId, answers } = sub;

        answers.forEach(ans => {
          rows.push({
            clientId,
            clientName,
            courseId,
            courseName,
            submissionId,
            questionId: ans.questionId,
            question: ans.question,
            answer: ans.answer,
            questionType: ans.questionType
          });
        });
      });
    });
  });

  return rows;
}

export default function Feedback() {
    const authContext = useAuth();
    const isCustomerAdmin = authContext.authentication.role === "CustomerAdmin";
    const adminClientId = authContext.authentication.clientId;
    const adminClientName = authContext.authentication.clientName;

    const getCurrentDateAndMonthBefore = () => {
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setMonth(fromDate.getMonth() - 1);
        return { fromDate, toDate };
    };

    const { fromDate, toDate } = getCurrentDateAndMonthBefore();

    const initialFilterState = {
        clientId: adminClientId || "",
        fromDate: fromDate,
        toDate: toDate
    };

    const [pageState, setPageState] = useState({pageError: null}); 
    const [loading, setLoading] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [rawFeedback, setRawFeedback] = useState([]);
    const [clients, setClients] = useState([]);
    const [filters, setFilters] = useState(initialFilterState);

    const csvHeaders = [
        { label: "Client ID", key: "clientId" },
        { label: "Client Name", key: "clientName" },
        { label: "Course Name", key: "courseName" },
        { label: "Submission ID", key: "submissionId" },
        { label: "Question", key: "question" },
        { label: "Answer", key: "answer" },
        { label: "Question Type", key: "questionType" }
      ];

    const flattenedData = flattenFeedbackData(rawFeedback);
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleDateChange = (name, date) => {
        setFilters({ ...filters, [name]: date });
    };

    const applyFilters = () => {
        setLoading(true);  // Start loading
        
        const { clientId, fromDate, toDate } = filters;
        const filterPayload = {};
    
        if (clientId) filterPayload.clientId = parseInt(clientId);
        if (fromDate) filterPayload.fromDate = fromDate.toISOString();
        if (toDate) filterPayload.toDate = toDate.toISOString();
    
        courseApi.getFeedback(filterPayload)
            .then((feedback) => {
                setRawFeedback(feedback);
                const formattedData = feedback.map(client => {
                    return client.courses.map(course => {
                        const submissions = course.submissions;
                        const questions = submissions.length > 0 ? submissions[0].answers.map(ans => ans.question) : [];
                        const columnDefs = questions.map(q => ({
                            headerName: q,
                            field: q,
                            sortable: true,
                            filter: false,
                            flex: 4
                        }));
                        
                        const rowData = submissions.map(submission => {
                            const answers = submission.answers.reduce((acc, ans) => {
                                acc[ans.question] = ans.answer;
                                return acc;
                            }, {});
                            return { ...answers, submissionId: submission.submissionId };
                        });
                        
                        return {
                            courseTitle: client.name + ": " + course.name,
                            columnDefs,
                            rowData,
                            enablePagination: rowData.length > 10
                        };
                    });
                }).flat();

                setRowData(formattedData);
            })
            .catch((error) => {
                setPageState(prevState => ({ ...prevState, pageError: 'Failed to load data' }));
            })
            .finally(() => {
                setLoading(false);  // Stop loading
            });
    };

    useEffect(() => {
        if (!isCustomerAdmin) {            
            clientApi.getAllClients().then(setClients);
        }
    }, []);

    return (
        <>
            <Row className="mt-3">
                <Col md={12}>
                    <h3 className="text-secondary">Feedback</h3>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3 ">
                <Col md={12} className="bg-light shadow p-4 rounded-3">
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="filterClientId">
                                    <Form.Label>Client:</Form.Label><br />
                                    {!isCustomerAdmin ? (
                                        <Typeahead
                                            clearButton
                                            id="filterClientId"
                                            options={clients.map(client => client.clientName)}
                                            placeholder="All Clients"
                                            selected={
                                                filters.clientId
                                                    ? clients.filter(client => client.id === parseInt(filters.clientId)).map(client => client.clientName)
                                                    : []
                                            }
                                            onChange={(selected) => {
                                                const selectedClient = clients.find(client => client.clientName === selected[0]);
                                                handleFilterChange({ target: { name: 'clientId', value: selectedClient ? selectedClient.id : '' } });
                                            }}                                        
                                        />
                                    ) : (
                                        <div
                                            className="form-control-plaintext"
                                            style={{
                                                border: "1px solid #ced4da",
                                                borderRadius: ".25rem",
                                                padding: ".375rem .75rem",
                                                backgroundColor: "#e9ecef",
                                                color: "#495057",
                                            }}
                                        >
                                            {adminClientName}
                                        </div>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="fromDate">
                                    <Form.Label>Feedback from:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.fromDate}
                                        onChange={(date) => handleDateChange('fromDate', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group controlId="toDate">
                                    <Form.Label>Feedback to:</Form.Label><br />
                                    <i className="bi bi-calendar fs-4 me-3 text-secondary"></i>
                                    <DatePicker
                                        selected={filters.toDate}
                                        onChange={(date) => handleDateChange('toDate', date)}                                        
                                        showYearDropdown
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md={6}> 
                                <Button variant="info" onClick={applyFilters} className="me-3">Apply Filter</Button>
                                {/* <CSVLink
                                    data={flattenedData}
                                    headers={csvHeaders}
                                    filename="filtered_course_attempts.csv"
                                    className="btn btn-info"
                                    disabled={!rawFeedback || rawFeedback.length === 0}
                                >
                                    Export CSV Report
                                </CSVLink> */}
                                <ExportToExcel feedbackData={flattenedData} excludeCols={['questionId', 'courseId', 'clientId']} />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}
            <Row className="mt-3 mx-2 pt-5">
                {loading ? (
                    <Alert key="loading" variant="info">Loading data, please wait...</Alert>
                ) : rowData.length === 0 ? (
                    <Col md={12}>
                        <Alert key="noFilters" variant="info">No Results Found</Alert>
                    </Col>
                ) : (
                    rowData.map((courseData, index) => (
                        <Col md={12} key={index} className="mb-4">
                            <h4 className="text-secondary my-4">{courseData.courseTitle}</h4>
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    columnDefs={courseData.columnDefs}
                                    rowData={courseData.rowData}
                                    domLayout='autoHeight'
                                    pagination={courseData.enablePagination}
                                    paginationPageSize={10}
                                    paginationPageSizeSelector={[10, 20, 50, 100]}
                                />
                            </div>
                        </Col>
                    ))
                )}
            </Row>
        </>
    );
}
