import React from "react";

export default function TitleElement(props) {

    return (
        <>
            <h3 className="text-secondary">{props.title}</h3>
            {props.noRule ? <br/> : <hr className="border-secondary" />}
        </>
    )
}