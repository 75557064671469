import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Modal } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import { assessmentQuestionApi } from "../../../api/assessmentQuestion.js";

export default function AssessmentQuestionOptionEdit(props) {
    
    const { questionId, questionOptionId, setIsEditingQuestionOption, setIsUpdated } = props;
    const { Formik } = formik;    
    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [showModal, setShowModal] = useState(true);
    const [selectedQuestionOption, setSelectedQuestionOption] = useState(null);
    
    const schema = yup.object().shape({
        text: yup.string().required("Option text is required"),
        selectionResponse: yup.string().required("Selection response is required"),
        correct: yup.boolean()
    });

    const questionOptionDefaults = {
        assessmentQuestionId: questionId,
        text: "",
        selectionResponse: "",
        correct: false
    };
    
    const getQuestionOptionData = async () => {
        const questionOption = await assessmentQuestionApi.getAssessmentQuestionOption(questionId, questionOptionId);
        setSelectedQuestionOption(questionOption);
    };

    const handleCancel = () => {
        setShowModal(false);
        setIsEditingQuestionOption(false);
    };
    
    useEffect(() => {
        if (questionOptionId) getQuestionOptionData();
    }, [questionOptionId]);

    return (
        <>
            <Formik        
                validationSchema={schema}                                
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        if (selectedQuestionOption) {
                            await assessmentQuestionApi.updateAssessmentQuestionOption(values);
                        } else {
                            await assessmentQuestionApi.createAssessmentQuestionOption(values);
                        } 
                        setIsEditingQuestionOption(false);
                        setIsUpdated(true);
                    } catch (e){
                        setPageState(prevState => ({...prevState, pageError: "Error Submitting Option" }));
                        console.error("Error Submitting Option" + e);
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedQuestionOption || questionOptionDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal 
                            show={showModal} 
                            onHide={() => handleCancel()}
                            centered
                            animation={false}
                            backdrop="static"
                        >
                            <Modal.Header className="bg-primary" closeButton>
                                <Modal.Title>Set Question Option</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bg-light py-5">
                                <Form.Group className="d-flex flex-column align-items-center" controlId="text">
                                    <Form.Label>Option Text:</Form.Label>
                                    <Form.Control
                                        className="w-75 mt-3"
                                        type="text"
                                        name="text"
                                        value={values.text}
                                        onChange={handleChange}
                                        isInvalid={touched.text && !!errors.text}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.text}
                                    </Form.Control.Feedback>
                                </Form.Group>
  
                                <Form.Group className="d-flex flex-column align-items-center mt-4" controlId="selectionResponse">
                                    <Form.Label>Selection Response:</Form.Label>
                                    <Form.Control
                                        className="w-75 mt-3"
                                        type="text"
                                        name="selectionResponse"
                                        value={values.selectionResponse}
                                        onChange={handleChange}
                                        isInvalid={touched.selectionResponse && !!errors.selectionResponse}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.selectionResponse}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="d-flex flex-column align-items-center mt-4" controlId="correct">
                                    <Form.Check
                                        name="correct"
                                        label="Correct"
                                        onChange={handleChange}
                                        checked={values.correct}
                                        type="checkbox"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                                <Button variant="info w-25 m-2" onClick={handleSubmit} disabled={isSubmitting || !dirty}>Save</Button>
                                <Button variant="info w-25 m-2" onClick={handleCancel}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>

            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}          
        </>
    )
}
