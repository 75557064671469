import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { courseCreditApi } from "../../../api/courseCredit.js";
import { invoiceApi } from "../../../api/invoice.js";
import InvoiceTable from "../client/InvoiceTable.jsx";
import CustomerInvoiceTable from "./CustomerInvoiceTable.jsx";

export default function CustomerInvoices() {

    const [pageState, setPageState] = useState({
        invoiceList: []
    })
    const {clientId} = useParams();
    useEffect(() => {
        const getInvoices = async () => {
            const response = await invoiceApi.getMyInvoices();
            setPageState(prevState => {
                return {...prevState, invoiceList: response}
            });
        }
        getInvoices();
    }, [clientId]);

    const onCreditsChanged = async (newCreditsList) => {
        let reloadCredits = false;
        if (newCreditsList){
            newCreditsList.forEach(c => {
                courseCreditApi.updateCourseCredits(c.id, c.totalCredits, c.usedCredits);
            })
        }
    }
    return (
        <>
            <Row className="ms-3 mb-3">
                <h3 className="text-secondary">My Invoices</h3>
                <hr className="mt-3 border-secondary" />
                <CustomerInvoiceTable invoiceList={pageState.invoiceList} />
            </Row>
        </>
    )
}
