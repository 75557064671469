import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { invoiceApi } from "../../../api/invoice.js";
import { Col, Row, Button, Form } from "react-bootstrap";
import LoadingBar from "../../../components/LoadingBar.jsx";

const canadaProvinces = [
    { code: "AB", name: "Alberta" },
    { code: "BC", name: "British Columbia" },
    { code: "MB", name: "Manitoba" },
    { code: "NB", name: "New Brunswick" },
    { code: "NL", name: "Newfoundland and Labrador" },
    { code: "NS", name: "Nova Scotia" },
    { code: "ON", name: "Ontario" },
    { code: "PE", name: "Prince Edward Island" },
    { code: "QC", name: "Quebec" },
    { code: "SK", name: "Saskatchewan" },
    { code: "NT", name: "Northwest Territories" },
    { code: "NU", name: "Nunavut" },
    { code: "YT", name: "Yukon" },
];

const usStates = [
    { code: "AL", name: "Alabama" },
    { code: "AK", name: "Alaska" },
    { code: "AZ", name: "Arizona" },
    { code: "AR", name: "Arkansas" },
    { code: "CA", name: "California" },
    { code: "CO", name: "Colorado" },
    { code: "CT", name: "Connecticut" },
    { code: "DE", name: "Delaware" },
    { code: "FL", name: "Florida" },
    { code: "GA", name: "Georgia" },
    { code: "HI", name: "Hawaii" },
    { code: "ID", name: "Idaho" },
    { code: "IL", name: "Illinois" },
    { code: "IN", name: "Indiana" },
    { code: "IA", name: "Iowa" },
    { code: "KS", name: "Kansas" },
    { code: "KY", name: "Kentucky" },
    { code: "LA", name: "Louisiana" },
    { code: "ME", name: "Maine" },
    { code: "MD", name: "Maryland" },
    { code: "MA", name: "Massachusetts" },
    { code: "MI", name: "Michigan" },
    { code: "MN", name: "Minnesota" },
    { code: "MS", name: "Mississippi" },
    { code: "MO", name: "Missouri" },
    { code: "MT", name: "Montana" },
    { code: "NE", name: "Nebraska" },
    { code: "NV", name: "Nevada" },
    { code: "NH", name: "New Hampshire" },
    { code: "NJ", name: "New Jersey" },
    { code: "NM", name: "New Mexico" },
    { code: "NY", name: "New York" },
    { code: "NC", name: "North Carolina" },
    { code: "ND", name: "North Dakota" },
    { code: "OH", name: "Ohio" },
    { code: "OK", name: "Oklahoma" },
    { code: "OR", name: "Oregon" },
    { code: "PA", name: "Pennsylvania" },
    { code: "RI", name: "Rhode Island" },
    { code: "SC", name: "South Carolina" },
    { code: "SD", name: "South Dakota" },
    { code: "TN", name: "Tennessee" },
    { code: "TX", name: "Texas" },
    { code: "UT", name: "Utah" },
    { code: "VT", name: "Vermont" },
    { code: "VA", name: "Virginia" },
    { code: "WA", name: "Washington" },
    { code: "WV", name: "West Virginia" },
    { code: "WI", name: "Wisconsin" },
    { code: "WY", name: "Wyoming" }
];


function AddCreditsPage() {
    const [courses, setCourses] = useState(null);
    const [country, setCountry] = useState("Canada");
    const [provinceState, setProvinceState] = useState("");
    const [quantities, setQuantities] = useState({});
    const [prices, setPrices] = useState({});
    const [waiveCharge, setWaiveCharge] = useState(false);
    const [gst, setGst] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const { clientId } = useParams();

    var navigate = useNavigate();

    useEffect(() => {
        const getAllCourses = async () => {
            const response = await invoiceApi.getAllPPUCourses(clientId);
            setCourses(response);
            const initialQuantities = response.reduce((acc, course) => {
                acc[course.courseId] = 0; // Set initial quantity to 0 for each course
                return acc;
            }, {});
            setQuantities(initialQuantities);

            const initialPrices = response.reduce((acc, course) => {
                acc[course.courseId] = course.price || 0; // Set initial price to the course price
                return acc;
            }, {});
            setPrices(initialPrices);
        };
        getAllCourses();
    }, [clientId]);

    useEffect(() => {
        let calculatedSubTotal = 0;
        if (courses !== null) {
            courses.forEach((course) => {
                const quantity = quantities[course.courseId] || 0;
                const price = prices[course.courseId] || 0;
                calculatedSubTotal += price * quantity;
            });
    
            let gstAmount = 0;
            if (country === "Canada") {
                gstAmount = calculatedSubTotal * 0.05; // 5% GST
            }
    
            setSubTotal(calculatedSubTotal);
            setGst(gstAmount);
    
            if (waiveCharge) {
                setTotal(0);
            } else {
                setTotal(calculatedSubTotal + gstAmount);
            }
        }
    }, [courses, quantities, prices, country, waiveCharge]);

    const handleQuantityChange = (courseId, quantity) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [courseId]: quantity,
        }));
    };

    const handlePriceChange = (courseId, price) => {
        setPrices((prevPrices) => ({
            ...prevPrices,
            [courseId]: price,
        }));
    };

    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        setProvinceState(""); // Reset the province/state selection
    };

    const handleWaiveChargeChange = (e) => {
        setWaiveCharge(e.target.checked);
    };

    const getProvincesOrStates = () => {
        return country === "Canada" ? canadaProvinces : usStates;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const lineItems = courses.map((course) => ({
            courseId: course.courseId,
            quantity: quantities[course.courseId] || 0,
            price: prices[course.courseId] || 0,
        }));
        await invoiceApi.createInvoice(
            clientId,
            address1,
            address2,
            city,
            country,
            provinceState,
            zip,
            lineItems,
            waiveCharge
        );
        navigate(`/client/${clientId}/credits`);
    };

    const handleCancel = () => {
        navigate(`/client/${clientId}/credits`);
    };

    if (!courses) {
        return <LoadingBar className="mt-5" />;
    }

    return (
        <>
            <h5 className="mb-3 text-secondary">Add Credits</h5><hr />
            <Row className="bg-light shadow p-4 rounded-3 mb-5 mt-0">  
            <table className="p-4">
                <thead>
                    <tr>
                        <th className="p-2">Course</th>
                        <th className="p-2">Quantity</th>
                        <th className="p-2">Price</th>
                        <th className="p-2">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {courses.map((course) => (
                        <tr key={course.courseId}>
                            <td className="p-2">{course.courseDisplayName}</td>
                            <td className="p-2">
                                <Form.Control
                                    type="number"
                                    value={quantities[course.courseId] || 0}
                                    onChange={(e) => handleQuantityChange(course.courseId, Number(e.target.value))}
                                    min={0}
                                />
                            </td>
                            <td className="p-2">
                                <Form.Control
                                    type="number"
                                    value={prices[course.courseId] || 0}
                                    onChange={(e) => handlePriceChange(course.courseId, Number(e.target.value))}
                                    min={0}
                                />
                            </td>
                            <td className="p-2">${(quantities[course.courseId] * prices[course.courseId])?.toFixed(2)}</td>
                        </tr>
                    ))}
                    <tr><td className="p-3"></td></tr>
                    <tr>
                        <td colSpan="3" className="text-end p-2">Sub Total</td>
                        <td className="p-2">${subTotal.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="text-end p-2">GST</td>
                        <td className="p-2">${gst.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3" className="text-end p-2">Total</td>
                        <td className="p-2">${total.toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
            </Row>

            <Form.Group className="mb-3">
                <Form.Check
                    type="checkbox"
                    label="Waive Charge"
                    checked={waiveCharge}
                    onChange={handleWaiveChargeChange}
                />
            </Form.Group>

            <h5 className="mb-3 text-secondary mt-5">Billing Info</h5><hr className="mt-3 border-secondary" />
            <Row className="bg-light shadow p-4 rounded-3 mb-5 mt-0">
                <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            type="text"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Country</Form.Label>
                        <Form.Select value={country} onChange={handleCountryChange}>
                            <option value="Canada">Canada</option>
                            <option value="United States">United States</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Prov / State</Form.Label>
                        <Form.Select value={provinceState} onChange={(e) => setProvinceState(e.target.value)}>
                            <option value="">Select</option>
                            {getProvincesOrStates().map((provState) => (
                                <option key={provState.code} value={provState.code}>
                                    {provState.code}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={4}>
                    <Form.Group className="mb-3">
                        <Form.Label>Postal Code / Zip</Form.Label>
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                </Row>
            </Row>

            <div className="d-flex justify-content-end">
                <Button variant="info" className="me-2" onClick={handleSubmit}>Create Order</Button>
                <Button variant="info" onClick={handleCancel}>Cancel</Button>
            </div>
            <Row className="mt-5"><h1> </h1></Row>
        </>
    );
}

export default AddCreditsPage;
