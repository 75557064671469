import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AssessmentQuestionOptionList from './AssessmentQuestionOptionList.jsx';
import AssessmentQuestionOptionEdit from './AssessmentQuestionOptionEdit.jsx';
import { assessmentQuestionApi } from '../../../api/assessmentQuestion.js';

export default function AssessmentQuestionOptions(props) {
    console.log("AssessmentQuestionOptions: " + JSON.stringify(props));

    const { tab } = useParams();
    const { questionId } = props;

    const [questionOptions, setQuestionOptions] = useState([]);
    const [isEditingQuestionOption, setIsEditingQuestionOption] = useState(false);
    const [questionOptionIdToEdit, setQuestionOptionIdToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    const toggleEditing = (questionOptionId) => {
        setQuestionOptionIdToEdit(questionOptionId);
        setIsEditingQuestionOption(!isEditingQuestionOption);
    };

    const getAssessmentQuestionOptions = async () => {
        const response = await assessmentQuestionApi.getAssessmentQuestion(questionId);
        setQuestionOptions(response.options);
    };

    useEffect(() => {
        if (tab === "assessment") {
            getAssessmentQuestionOptions();
        } else {
            setIsEditingQuestionOption(false);
        }
    }, [tab]);

    useEffect(() => {
        if (isUpdated) {
            getAssessmentQuestionOptions();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <>
            {isEditingQuestionOption ? (
                <AssessmentQuestionOptionEdit 
                    questionId={questionId} 
                    questionOptionId={questionOptionIdToEdit} 
                    setIsEditingQuestionOption={toggleEditing} 
                    setIsUpdated={setIsUpdated} 
                />
            ) : (
                <AssessmentQuestionOptionList 
                    questionId={questionId} 
                    questionOptions={questionOptions} 
                    setIsEditingQuestionOption={toggleEditing} 
                    setQuestionOptionIdToEdit={setQuestionOptionIdToEdit} 
                    isUpdated={isUpdated} 
                    setIsUpdated={setIsUpdated} 
                    isEmpty={!questionOptions || questionOptions.length === 0}
                />
            )}
        </>
    );
}
