import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Modal, Row, Col } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as formik from 'formik';
import * as yup from 'yup';
import { courseAccessApi } from "../../../api/courseAccess.js";

export default function CourseAccessEdit(props) {
    
    const { courseId, courseAccessId, setIsEditingCourseAccess, isEditingCourseAccess, setIsUpdated } = props;
    const { Formik } = formik;    
    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedCourseAccess, setSelectedCourseAccess] = useState(null);
    
    const schema = yup.object().shape({
        doNotReportScore: yup.boolean(),
        showScoreToParticipant: yup.boolean()
    });

    const courseAccessDefaults = {
        courseId: courseId,
        employeeGroupId: "",
        doNotReportScore: false,
        showScoreToParticipant: true
    };
    
    const getCourseData = async () => {
        const course = await courseAccessApi.getCourseAccess(courseAccessId);
        setSelectedCourseAccess(course);
    };

    const handleCancel = () => {
        setIsEditingCourseAccess(false);
    };
    
    useEffect(() => {
        if (courseAccessId) getCourseData();
    }, [courseAccessId]);

    return (
        <>
            <Formik        
                validationSchema={schema}                                
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        await courseAccessApi.updateCourseAccess(courseAccessId, values.doNotReportScore, values.showScoreToParticipant);
                        setIsEditingCourseAccess(false);
                        setIsUpdated(true);
                    } catch (e){
                        setPageState(prevState => ({...prevState, pageError: "Error Submitting Course" }));
                        console.error("Error Submitting Course" + e);
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedCourseAccess || courseAccessDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal 
                            show={isEditingCourseAccess} 
                            onHide={() => handleCancel()}
                            centered
                            animation={false}
                            backdrop="static"
                        >

                            <Modal.Header className="bg-primary" closeButton>
                                <Modal.Title>Edit Course Access</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bg-light py-5">
                                {/*Display the selected client and employee group */}
                                <Row>
                                    <Col md={4} style={{fontWeight: "bold"}}>Client:</Col><Col md={8}>{selectedCourseAccess?.clientName}</Col>
                                </Row>
                                <Row>
                                    <Col md={4} style={{fontWeight: "bold"}}>Employee Group:</Col><Col md={8}>{selectedCourseAccess?.employeeGroupName}</Col>
                                </Row>
                                
                                <Form.Group className="ms-5 mb-3 mt-4" controlId="doNotReportScore">
                                    <Form.Check
                                        className="ms-5"
                                        name="doNotReportScore"
                                        label="Do not report score"
                                        onChange={handleChange}
                                        checked={values.doNotReportScore}
                                        type="checkbox"
                                    />
                                </Form.Group>
                                <Form.Group className="ms-5" controlId="showScoreToParticipant">
                                    <Form.Check
                                        className="ms-5"
                                        name="showScoreToParticipant"
                                        label="Show score to participant"
                                        onChange={handleChange}
                                        checked={values.showScoreToParticipant}
                                        type="checkbox"
                                    />
                                </Form.Group>
                          
                            </Modal.Body>
                            <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                                <Button variant="info w-25 m-2" onClick={handleSubmit} disabled={isSubmitting || !dirty}>Save</Button>
                                <Button variant="info w-25 m-2" onClick={handleCancel}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>

            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}          
        </>
    )
}
