import React, { useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import {useNavigate, useParams} from 'react-router-dom';
import formatDate from "../../../_helpers/formatDate";
import { invoiceApi } from "../../../api/invoice";

function InvoiceTable({ invoiceList, onDeletePressed }) {
    const navigate = useNavigate();
    const {clientId} = useParams();
    const navigateToInvoice = useCallback((invoiceId) => {
        navigate(`/client/${clientId}/invoice/${invoiceId}`);
    }, [navigate]);

    

    const [columnDefs, setColumnDefs] = useState([
        {
            field: 'orderDate',
            headerName: 'Invoice Date',
            flex: 3,
            valueFormatter: params => params.value ? formatDate(params.value) : ''
        },
        {
            field: 'totalCredits',
            headerName: 'Credits',
            flex: 2
        },
        {
            field: 'total',
            headerName: 'Amount',
            flex: 2
        },
        {
            field: 'datePaid',
            headerName: 'Paid',
            flex: 3,
            valueFormatter: params => params.value ? formatDate(params.value) : ''
        },
        {
            headerName: '',
            field: 'id',
            flex: 2,
            cellRenderer: ({value} = params) => (
                <>                    
                    <i
                        className="bi bi-search"
                        style={{ cursor: 'pointer'}}
                        onClick={() => navigateToInvoice(value)}
                    > Details</i>
                    <span style={{paddingLeft:10, paddingRight:10}}>|</span>
                    <i
                        className="bi bi-trash"
                        style={{ cursor: 'pointer', marginRight:10}}
                        onClick={() => {
                                if (confirm("Are you sure you want to delete this invoice?")) onDeletePressed(value);
                            }
                        }   
                    >Delete</i>
                </>
            ),
        },
    ]);

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
            <AgGridReact
                rowData={invoiceList}
                columnDefs={columnDefs}
                domLayout='autoHeight'
            />
        </div>
    );
}

export default InvoiceTable;
