import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AssessmentQuestionList from './AssessmentQuestionList.jsx';
import AssessmentQuestionEdit from './AssessmentQuestionEdit.jsx';
import { assessmentQuestionApi } from '../../../api/assessmentQuestion.js';

export default function AssessmentQuestions() {

  const { courseId, tab } = useParams();

  const [questions, setQuestions] = useState([]);
  const [isEditingQuestion, setIsEditingQuestion] = useState(false);
  const [questionIdToEdit, setQuestionIdToEdit] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  
  const toggleEditing = (questionId) => {
    setQuestionIdToEdit(questionId);
    setIsEditingQuestion(!isEditingQuestion);
  };

  const getAssessmentQuestions = async () => {
    const response = await assessmentQuestionApi.getAssessmentQuestions(courseId);
    setQuestions(response);
  }; 

  useEffect (() => {
    if (tab === "assessment") {
      getAssessmentQuestions();
    } else {
      setIsEditingQuestion(false);
    }
  }, [tab]);

  useEffect(() => {
    if (isUpdated) {
      getAssessmentQuestions();
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return (
    <>
      {isEditingQuestion ? (
        <AssessmentQuestionEdit 
          courseId={courseId} 
          questionId={questionIdToEdit} 
          setIsEditingQuestion={toggleEditing} 
          setIsUpdated={setIsUpdated} 
        />
      ) : (
        <AssessmentQuestionList 
          courseId={courseId} 
          questions={questions} 
          setIsEditingQuestion={toggleEditing} 
          setQuestionIdToEdit={setQuestionIdToEdit} 
          setIsUpdated={setIsUpdated} 
          isEmpty={!questions || questions.length === 0}
        />
      )}
    </>
  );
};
