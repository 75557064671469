import React, { useState, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";

const CourseCreditTable = React.memo(({ creditsList, creditsChanged }) => {
    const PencilRenderer = (params) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>{params.value}</span>
                <i className="bi bi-pencil-fill" style={{ fontSize: '0.8em', color: 'grey' }}></i>
            </div>
        );
    };

    const [columnDefs, setColumnDefs] = useState([
        { field: 'courseName', headerName: 'Course', flex: 3 },
        { field: 'courseDisplayName', headerName: 'Display Name', flex: 3 },
        {
            field: 'totalCredits',
            headerName: 'Credits Given',
            flex: 2,
            editable: true,
            cellRenderer: PencilRenderer, // Use custom renderer
        },
        {
            field: 'usedCredits',
            headerName: 'Credits Used',
            flex: 2,
            editable: true,
            cellRenderer: PencilRenderer, // Use custom renderer
        },
        {
            field: 'remainingCredits',
            headerName: 'Credits Remaining',
            flex: 2,
            valueGetter: (params) => params.data.totalCredits - params.data.usedCredits,
        },
    ]);

    const onCellValueChanged = useCallback((params) => {
        const updatedCreditsList = [...creditsList];
        const rowIndex = params.node.rowIndex;

        updatedCreditsList[rowIndex] = {
            ...updatedCreditsList[rowIndex],
            [params.colDef.field]: params.newValue
        };

        creditsChanged(updatedCreditsList);
    }, [creditsList, creditsChanged]);

    return (
        <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
            <AgGridReact
                rowData={creditsList}
                columnDefs={columnDefs}
                onCellValueChanged={onCellValueChanged}
                domLayout='autoHeight'
                stopEditingWhenCellsLoseFocus={true} // Optional: stops editing when focus is lost
            />
        </div>
    );
});

export default CourseCreditTable;
