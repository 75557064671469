import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getClientUser = async (clientUserId) => {
    const response = await axios.get(`ClientUser/${clientUserId}`, { headers: authHeader() });
    return response.data.result;
}

const createClientUser = async (clientUser) => {
    const data = JSON.stringify(clientUser);
    const response = await axios.post('ClientUser', data, { headers: authHeader() });
    return response.data.result;
}

const updateClientUser = async (clientUser) => {
    const data = JSON.stringify(clientUser);
    const response = await axios.put('ClientUser', data, { headers: authHeader() });
    return response.data.result;
}

const deleteClientUser = async (clientUserId) => {
    const response = await axios.delete(`ClientUser/${clientUserId}`, { headers: authHeader() });
    return response.data.result;
}

const activateClientUser = async (activationKey, password) => {
    const data = JSON.stringify({ activationKey, password });
    const response = await axios.post('ClientUser/activate', data);
    return response.data.result;
}

const resendInvite = async (userId) => {
    await axios.put(`ClientUser/${userId}/invite`, null, {headers: authHeader()});
}

export const clientUserApi = {
    getClientUser,
    createClientUser,
    updateClientUser,
    deleteClientUser,
    activateClientUser, 
    resendInvite
}