import React, {useState} from "react"
import {useLocation, useNavigate} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {Alert, Card, Col, Container, Row} from "react-bootstrap";
import {Formik} from "formik";
import * as formik from 'formik';
import * as yup from 'yup';
import {adminAuthApi} from "../../api/adminAuth.js";
function ForgotPasswordPage() {
    const { Formik } = formik;
    const schema = yup.object().shape({
        email: yup.string().email('Invalid email format').required(),
    });

    const [pageState, setPageState] = useState({pageError: null, submitted: false});

    if (pageState.submitted){

    } else {

    }
    return (
        <>
            {pageState.submitted ? (

                <Container className="mb-5 align-self">
                    <h4 className="text-secondary">Password Rest Link Sent</h4>
                    <hr className="border-secondary mb-5"/>
                    Your password reset request has been submitted. If you've entered a valid email address you will recieve an email reset
                    link in you inbox shortly.  The reset link will be valid for 24 hours.
                </Container>
            ): (
                <>
                    <Formik
                        validationSchema={schema}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                try {
                                    setSubmitting(true);
                                    await adminAuthApi.sendPasswordResetLink(values.email);
                                    setPageState(prevState => {
                                        return {...prevState, submitted: true};
                                    });
                                } catch (e){
                                    setPageState(prevState => {return {...prevState, pageError: "An error occurred while trying to submit your password reset request.  Please try again." }})
                                    console.error(e);
                                }
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                        initialValues={{
                            email: '',
                        }}
                    >
                        {({ handleSubmit, handleChange,touched, values, isSubmitting, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>

                                    <h4 className="text-secondary">Forgot Password</h4>
                                    <hr className="border-secondary mb-5"/>
                                    Please enter your email address in the field below and we will send you a link that will allow you to reset your password.
                        

                                <Form.Group className="mb-3 mt-5" controlId="email">
                                    <Form.Label className="text-center">
                                        Email address
                                    </Form.Label>
                                    <Form.Control
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                        type="email" placeholder="Enter email"
                                        isInvalid={touched.email && !!errors.email}
                                    />
                                    {touched.email && (<Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>)}
                                </Form.Group>

                                <Button className="btn-info w-100 mt-3" type="submit" disabled={isSubmitting}>
                                    Send Password Reset Link
                                </Button>
                                {pageState.pageError && (
                                <Alert key="pageError" variant="danger mt-3">
                                    {pageState.pageError}
                                </Alert>
                            )}
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
}

export default ForgotPasswordPage;
