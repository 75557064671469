import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

import ActionBar from "../../../components/ActionBar.jsx";
import { postLoginQuestionApi } from "../../../api/postLoginQuestion.js";

export default function PostLoginQuestionList(props) {

    const { questions, setIsEditingQuestion, setQuestionIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'question', headerName: 'Question', flex: 8, rowDrag: true, sortable: true },
        { field: 'description', headerName: 'Description', flex: 5 },
        { field: 'type', headerName: 'Type', flex: 3 },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);

    useEffect(() => {
        if (questions) {
            setRowData(questions);
        }
    }, [questions]);

    const handleEditQuestion = (questionId) => {
        setIsEditingQuestion(true);
        setQuestionIdToEdit(questionId);
    };

    const handleNewQuestion = () => {
        setIsEditingQuestion(true);
        setQuestionIdToEdit(null);
    };

    const onRowDragEnd = (params) => {
        let newOrder = params.api.getDisplayedRowAtIndex(0).data.id;
        for (let i = 1; i < params.api.getDisplayedRowCount(); i++) {
            newOrder += ',' + params.api.getDisplayedRowAtIndex(i).data.id;
        }
        const newOrderIntegers = newOrder.split(',').map(idString => parseInt(idString, 10));
        postLoginQuestionApi.reorderPostLoginQuestions(newOrderIntegers).then(() => {
            console.log('Questions order successfully updated on backend');
        }).catch((error) => {
            console.error('Error reordering questions on backend:', error);
        });
    };

    const handleDeleteQuestion = async (questionId) => {
        try {
            await postLoginQuestionApi.deletePostLoginQuestion(questionId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Post Login Questions found.&nbsp;
            <Link to="#" onClick={handleNewQuestion} className="text-info">Add a question</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Post Login Questions</h5>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                    <Button variant="info" onClick={handleNewQuestion}>+ Add Question</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.question}
                                    onDelete={handleDeleteQuestion}
                                    onEdit={handleEditQuestion}
                                />
                            ),
                        }}
                        onRowDragEnd={onRowDragEnd}
                        rowDragManaged={true}
                        suppressMoveWhenRowDragging={true}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>
        </>
    );
}