import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getFeedbackQuestions = async (clientId) => {
    const response = await axios.get(`FeedbackQuestion/client/${clientId}`, { headers: authHeader() });
    return response.data.result;
}

const getFeedbackQuestion = async (feedbackQuestionId) => {
    const response = await axios.get(`FeedbackQuestion/${feedbackQuestionId}`, { headers: authHeader() });
    return response.data.result;
}

const createFeedbackQuestion = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.post('FeedbackQuestion', data, { headers: authHeader() });
    return response.data.result;
}

const updateFeedbackQuestion = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.put('FeedbackQuestion', data, { headers: authHeader() });
    return response.data.result;
}

const deleteFeedbackQuestion = async (feedbackQuestionId) => {
    const response = await axios.delete(`FeedbackQuestion/${feedbackQuestionId}`, { headers: authHeader() });
    return response.data.result;
}

const reorderFeedbackQuestions = async (feedbackQuestions) => {
    const data = {
        orderedFeedbackQuestions: feedbackQuestions,
    };
    const response = await axios.put('FeedbackQuestion/reorder', data, { headers: authHeader() });
    return response.data.result;
}

const copyFeedbackQuestions = async (sourceClientId, targetClientId) => {
    const data = {
        copyFromClientId: sourceClientId,
        copyToClientId: targetClientId
    };
    const response = await axios.post('FeedbackQuestion/copy', data, { headers: authHeader() });
    return response.data.result;
}

export const feedbackQuestionApi = {
    getFeedbackQuestions,
    getFeedbackQuestion,
    createFeedbackQuestion,
    updateFeedbackQuestion,
    deleteFeedbackQuestion,
    reorderFeedbackQuestions,
    copyFeedbackQuestions
}