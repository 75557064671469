import React, { useState } from "react";
import { Form, Button, Col, Row, Container, Toast, ToastContainer, Alert } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import * as formik from 'formik';
import * as yup from 'yup';

import { clientApi } from "../../../api/client.js";
import TitleElement from "../../../components/TitleElement.jsx";

export default function CreateClient() {
    const navigate = useNavigate();
    const [pageState, setPageState] = useState({ pageError: null });
    const [uploadedLogo, setuploadedLogo] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [adminEmail, setAdminEmail] = useState('');
    const { Formik } = formik;

    const schema = yup.object().shape({
        clientName: yup.string()
            .required('Client Name is required')
            .test(
                'isUnique',
                'Client Name already in use',
                async (value) => {
                    const isAvailable = await clientApi.checkClientNameAvailablity(value);
                    return !isAvailable;
                }
            ),
        logo: yup.mixed(),
        canViewReports: yup.boolean(),
        payPerUse: yup.boolean(),
        adminFirstName: yup.string().required('Admin First Name is required'),
        adminLastName: yup.string().required('Admin Last Name is required'),
        adminPhoneNumber: yup.string(),
        adminUsername: yup.string().required('Admin Username is required'),
        adminEmail: yup.string().email('Invalid email').required('Email Address is Required')
    });

    const logoUploaded = (event) => {
        setuploadedLogo(event.target.files[0]);
    };

    return (
        <>
            <TitleElement title="New Client" />

            <Formik
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const client = {
                            clientName: values.clientName,
                            logo: uploadedLogo,
                            canViewReports: values.canViewReports,
                            payPerUse: values.payPerUse,
                            adminFirstName: values.adminFirstName,
                            adminLastName: values.adminLastName,
                            adminPhoneNumber: values.adminPhoneNumber,
                            adminUsername: values.adminUsername,
                            adminEmail: values.adminEmail
                        };

                        const clientId = await clientApi.createClient(client);
                        console.log("created client: " + clientId);
                        setAdminEmail(values.adminEmail);
                        setShowToast(true);

                        console.log("created client: " + clientName);

                        setTimeout(() => {
                            navigate(`/client/${clientId}/details`);
                          }, 4000); 
                    } catch (e) {
                        setPageState(prevState => ({ ...prevState, pageError: "Error Submitting New Client" }));
                        console.error("Error Submitting New Client" + e);
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={{
                    clientName: '',
                    logo: '',
                    canViewReports: true,
                    payPerUse: false,
                    adminFirstName: '',
                    adminLastName: '',
                    adminPhoneNumber: '',
                    adminUsername: '',
                    adminEmail: ''
                }}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-3 pt-3">
                            <Col md={6} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">
                                <Row>
                                    {/* Client Name Input */}
                                    <Form.Group className="mb-3" controlId="clientName">
                                        <Form.Label>Client Name: </Form.Label>
                                        <Form.Control
                                            name="clientName"
                                            value={values.clientName}
                                            onChange={handleChange}
                                            type="text"
                                            isInvalid={touched.clientName && !!errors.clientName}
                                        />
                                        {touched.clientName && (<Form.Control.Feedback type="invalid">
                                            {errors.clientName}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                </Row>
                                <Row className="d-flex align-items-center">
                                    <Col>
                                        {/* Can View Reports Checkbox */}
                                        <Form.Group className="mb-3 mt-4 " controlId="canViewReports">
                                            <Form.Check
                                                name="canViewReports"
                                                label="Can View Reports"
                                                onChange={handleChange}
                                                checked={values.canViewReports}
                                                type="checkbox"
                                            />
                                        </Form.Group>
                                        {/* Pay Per Use Checkbox */}
                                        <Form.Group className="mb-3" controlId="payPerUse">
                                            <Form.Check
                                                name="payPerUse"
                                                label="Pay Per Use"
                                                onChange={handleChange}
                                                checked={values.payPerUse}
                                                type="checkbox"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={{ span: 3, offset: 1 }}>
                                {/* Display the uploaded logo */}
                                {uploadedLogo ? (
                                    <Container className="p-5 mb-3 border rounded-3 shadow d-flex justify-content-center">
                                        {uploadedLogo && <img src={URL.createObjectURL(uploadedLogo)} alt="Uploaded Logo" className="mt-3" style={{ maxWidth: "200px" }} />}
                                    </Container>
                                ) : null}

                                {/* Logo Upload */}
                                <Form.Group controlId="logo" className="mb-3">
                                    <Form.Label>Client Logo: </Form.Label>
                                    <Form.Control
                                        name="logo"
                                        onChange={logoUploaded}
                                        type="file"
                                        isInvalid={touched.logo && !!errors.logo}
                                    />
                                    {touched.logo && <Form.Control.Feedback type="invalid">{errors.logo}</Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                            <h5 className="text-secondary mb-3">Create Client Admin User</h5>
                            <Col md={10} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">
                                <Row>
                                    {/* User FirstName Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="adminFirstName">
                                        <Form.Label>First Name:</Form.Label>
                                        <Form.Control
                                            name="adminFirstName"
                                            onChange={handleChange}
                                            value={values.adminFirstName}
                                            type="text"
                                            isInvalid={touched.adminFirstName && !!errors.adminFirstName}
                                        />
                                        {touched.adminFirstName && (<Form.Control.Feedback type="invalid">
                                            {errors.adminFirstName}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>

                                    {/* User Last Name Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="adminLastName">
                                        <Form.Label>Last Name:</Form.Label>
                                        <Form.Control
                                            name="adminLastName"
                                            onChange={handleChange}
                                            value={values.adminLastName}
                                            type="text"
                                            isInvalid={touched.adminLastName && !!errors.adminLastName}
                                        />
                                        {touched.adminLastName && (<Form.Control.Feedback type="invalid">
                                            {errors.adminLastName}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>

                                    {/* User Phone Number Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="adminPhoneNumber">
                                        <Form.Label>Phone Number: <i><small>(Optional)</small></i></Form.Label>
                                        <Form.Control
                                            name="adminPhoneNumber"
                                            onChange={handleChange}
                                            value={values.adminPhoneNumber}
                                            type="text"
                                            isInvalid={touched.adminPhoneNumber && !!errors.adminPhoneNumber}
                                        />
                                        {touched.adminPhoneNumber && (<Form.Control.Feedback type="invalid">
                                            {errors.adminPhoneNumber}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    {/* User Name Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="adminUsername">
                                        <Form.Label>Username:</Form.Label>
                                        <Form.Control
                                            name="adminUsername"
                                            onChange={handleChange}
                                            value={values.adminUsername}
                                            type="text"
                                            isInvalid={touched.adminUsername && !!errors.adminUsername}
                                        />
                                        {touched.adminUsername && (<Form.Control.Feedback type="invalid">
                                            {errors.adminUsername}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>

                                    {/* User Email Input */}
                                    <Form.Group as={Col} className="mb-3" controlId="adminEmail">
                                        <Form.Label>Email Address:</Form.Label>
                                        <Form.Control
                                            name="adminEmail"
                                            onChange={handleChange}
                                            value={values.adminEmail}
                                            type="email"
                                            isInvalid={touched.adminEmail && !!errors.adminEmail}
                                        />
                                        {touched.adminEmail && (<Form.Control.Feedback type="invalid">
                                            {errors.adminEmail}
                                        </Form.Control.Feedback>)}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col md={5}>
                                        <Form.Text className="text-muted w-20 mt-3">An email will be sent to the client admin with the new username and a link to activate their account.</Form.Text>
                                    </Col>
                                    <Col md={{span: 3, offset: 1}}>
                                        {/* Submit Button */}
                                        <Button className="btn-info w-100 mt-2" type="submit" disabled={isSubmitting || !dirty}>Create Client</Button>
                                    </Col>
                                    <Col md={3}>
                                        {/* Cancel Button */}
                                        <Button as={Link} to="/clients" className="btn-info w-100 mt-2" type="button">Cancel</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}

                        {/* Toast Notification */}
                        <ToastContainer position="middle-center" className="p-3">
                            <Toast onClose={() => setShowToast(false)} show={showToast} delay={4000} autohide>
                                <Toast.Header className="bg-primary">
                                    <strong className="me-auto">Client Created</strong>
                                </Toast.Header>
                                <Toast.Body className="p-4">An activation email has been sent to {adminEmail}</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </Form>
                )}
            </Formik>
        </>
    )
}
