import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getAssessmentQuestions = async (courseId) => {
    const response = await axios.get(`AssessmentQuestion/course/${courseId}`,{headers: authHeader()} );
    return response.data.result;
}

const getAssessmentQuestion = async (assessmentQuestionId) => {
    const response = await axios.get(`AssessmentQuestion/${assessmentQuestionId}`, {headers:authHeader()});
    return response.data.result;
}

const getAssessmentQuestionOption = async (assessmentQuestionId, optionId) => {
    const assessmentQuestion = await getAssessmentQuestion(assessmentQuestionId);
    return assessmentQuestion.options.find(opt => opt.id === optionId);
}

const createAssessmentQuestion = async (values) => {

    const data = JSON.stringify(values);

    const response = await axios.post('AssessmentQuestion', data, {headers:authHeader()});
    return response.data.result;
}

const updateAssessmentQuestion = async (values) => {

    const data = JSON.stringify(values);

    const response = await axios.put('AssessmentQuestion', data, {headers:authHeader()});
    return response.data.result;
}

const deleteAssessmentQuestion = async (assessmentQuestionId) => {
    const response = await axios.delete(`AssessmentQuestion/${assessmentQuestionId}`, {headers:authHeader()});
    return response.data;
}

const reorderAssessmentQuestions = async (assessmentQuestions) => {

    const data = {
        orderedAssessmentQuestions: assessmentQuestions,
    };

    const response = await axios.put('AssessmentQuestion/reorder', data, {headers:authHeader()});
    return response.data;
}

const copyAssessmentQuestions = async (sourceCourseId, targetCourseId) => {
    
    const data = {
        copyFromCourseId: sourceCourseId,
        copyToCourseId: targetCourseId
    };
    
    const response = await axios.post('AssessmentQuestion/copy', data, {headers:authHeader()});
    return response.data;
}

const createAssessmentQuestionOption = async (values) => {

    const data = JSON.stringify(values);

    const response = await axios.post('AssessmentQuestion/option', data, {headers:authHeader()});
    return response.data.result;
}  

const updateAssessmentQuestionOption = async (values) => {  

    const data = JSON.stringify(values);

    const response = await axios.put('AssessmentQuestion/option', data, {headers:authHeader()});
    return response.data.result;
}

const deleteAssessmentQuestionOption = async (assessmentQuestionOptionId) => {
    const response = await axios.delete(`AssessmentQuestionOption/${assessmentQuestionOptionId}`, {headers:authHeader()});
    return response.data;
}

const reorderAssessmentQuestionOptions = async (assessmentQuestionOptions) => { 

    const data = {
        orderedOptions: assessmentQuestionOptions,
    };

    const response = await axios.put('AssessmentQuestion/option/reorder', data, {headers:authHeader()});
    return response.data;
}

const exportAssessmentQuestions = async (courseId) => {
    return axios.get(`AssessmentQuestion/course/${courseId}/export/Questions`, {
        headers: authHeader(),
        responseType: 'blob' 
    }).then(response => {
        const filename = getFileNameFromContentDisposition(response.headers['content-disposition']);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const exportAssessmentQuestionsWithFeedback = async (courseId) => {
    return axios.get(`AssessmentQuestion/course/${courseId}/export/QuestionsWithFeedback`, {
        headers: authHeader(),
        responseType: 'blob' 
    }).then(response => {
        const filename = getFileNameFromContentDisposition(response.headers['content-disposition']);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const exportAssessmentQuestionAnswerKey = async (courseId) => {
    return axios.get(`AssessmentQuestion/course/${courseId}/export/AnswerKey`, {
        headers: authHeader(),
        responseType: 'blob' 
    }).then(response => {
        const filename = getFileNameFromContentDisposition(response.headers['content-disposition']);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    });
}

const getFileNameFromContentDisposition = (contentDisposition) => {
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    if (matches != null && matches[1]) {
        console.log(matches[1].replace(/['"]/g, ''));
        return matches[1].replace(/['"]/g, '');
    }
    return 'document.docx';
}

export const assessmentQuestionApi = {
    getAssessmentQuestions,
    getAssessmentQuestion,
    getAssessmentQuestionOption,
    createAssessmentQuestion,
    updateAssessmentQuestion,
    deleteAssessmentQuestion,
    reorderAssessmentQuestions,
    copyAssessmentQuestions,
    createAssessmentQuestionOption,
    updateAssessmentQuestionOption,
    deleteAssessmentQuestionOption,
    reorderAssessmentQuestionOptions,
    exportAssessmentQuestions,
    exportAssessmentQuestionsWithFeedback,
    exportAssessmentQuestionAnswerKey
}