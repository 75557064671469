import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";

import ActionBar from "../../../components/ActionBar.jsx";
import formatDate from "../../../_helpers/formatDate";
import { employeeGroupApi } from "../../../api/employeeGroup.js";

export default function EmployeeGroupList(props) {

    const { groups, setIsEditingGroup, setGroupIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'name', headerName: 'Group Name', flex: 3 },
        { field: 'username', headerName: 'Username', flex: 3 },
        { field: 'passKey', headerName: 'Passkey', flex: 3 },
        { field: 'createdDate', headerName: 'Created', flex: 5, valueFormatter: (params) => formatDate(params.data.createdDate) },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);

    useEffect(() => {
        if (groups) {
            setRowData(groups);
        }
    }, [groups]);

    const handleEditGroup = (groupId) => {
        setIsEditingGroup(true);
        setGroupIdToEdit(groupId);
    };

    const handleNewGroup = () => {
        setIsEditingGroup(true);
        setGroupIdToEdit(null);
    };

    const handleDeleteGroup = async (groupId) => {
        try {
            await employeeGroupApi.deleteEmployeeGroup(groupId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting group:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Employee Groups found.&nbsp;
            <Link to="#" onClick={handleNewGroup} className="text-info">Add a group</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Employee Groups</h5>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                    <Button variant="info" onClick={handleNewGroup}>+ Add Group</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.name}
                                    onDelete={handleDeleteGroup}
                                    onEdit={handleEditGroup}
                                />
                            ),
                        }}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>
        </>
    );
}
