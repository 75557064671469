import React, { useState, useCallback } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";

import { clientApi } from "../../../api/client.js";
import formatDate from "../../../_helpers/formatDate";

export default function ClientList() {

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        {field: 'clientName', headerName: 'Client Name', sortable: true, filter: true, flex: 4, floatingFilter: true},
        {field: 'dateCreated', headerName: 'Date Created', sortable: true, filter: false, flex: 6, floatingFilter: false, valueFormatter: (params) => formatDate(params.data.dateCreated)},
        {field: 'payPerUse', headerName: 'Pay Per Use', sortable: true, filter: true, flex: 2, floatingFilter: false},
        {field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1, floatingFilter: false}
    ]);

    const onGridReady = useCallback(() => {
        clientApi.getAllClients().then((clients) => { 
            setRowData(clients);
        })
    }, []);

    const handleDeleteClient = async (clientId) => {
        try {
          await clientApi.deleteClient(clientId);
          clientApi.getAllClients().then((clients) => {
            setRowData(clients);
        })
        } catch (error) {
          console.error('Error deleting client:', error);
          alert('There was an error deleting the client: ', error);
        }
    };

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h3 className="text-secondary">Client List</h3>
                </Col>
                <Col md={6} style={{textAlign:"right"}}>                    
                    <Button as={Link} to="/newclient" variant="info">+ New Client</Button>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3"> 
                <Col className="ag-theme-alpine w-auto">  
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                              <ActionRenderer clientId={props.data.id} clientName={props.data.clientName} onDelete={handleDeleteClient} />
                            ),
                        }}
                        onGridReady={onGridReady}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50, 100]}
                        pagination={true}
                        domLayout='autoHeight'
                    />
                </Col>
            </Row>
        </>
    )
}

function ActionRenderer({ clientId, clientName, onDelete }){

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteConfirm = async () => {
        onDelete(clientId);
        setShowDeleteModal(false);
      };

    return (
        <>
            <Container fluid className="d-flex justify-content-center">
                <Link to={`/client/${clientId}/details`} className="bi bi-pencil me-3" style={{fontSize:18}}></Link>
                <Link onClick={setShowDeleteModal} className="bi bi-trash" style={{fontSize:18}}></Link>
            </Container>

            <Modal 
                show={showDeleteModal} 
                onHide={() => setShowDeleteModal(false)}
                centered
                animation={false}
            >
                <Modal.Header className="bg-primary" closeButton>
                    <Modal.Title>Delete Client</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light text-center py-5">Are you sure you want to delete {clientName}?</Modal.Body>
                <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                    <Button variant="info w-25 m-2" onClick={handleDeleteConfirm}>Delete</Button>
                    <Button variant="info w-25 m-2" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}