import React from "react";
import { Navbar, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext.jsx";

export default function AdminHeader() {
    const authContext = useAuth();
    const masquerading = authContext.authentication.masquerading;

    const endMasquerade = () => {
        authContext.endMasquerade();
    };

    return (
        <div>
            <Navbar className="d-flex justify-content-between align-items-center shadow bg-light px-5 py-2">
                <Navbar.Brand href="#home">
                    <img src="/frame-logo.png" alt="Frame & Associates" width="20%" />
                </Navbar.Brand>

                <Navbar.Text>
                    {authContext.signedIn() ? (
                        <>
                            <i className="bi bi-person text-info me-2 fs-4 align-middle"></i>
                            <Link className="text-decoration-none me-2 fs-5 align-middle" style={{ cursor: 'pointer' }} to="/account">
                                {authContext.authentication.firstName}
                            </Link>
                             |
                            <Link className="text-decoration-none ms-2 fs-5 align-middle" style={{ cursor: 'pointer' }} to="/login" onClick={() => authContext.logout()}>
                                logout
                            </Link>
                        </>
                    ) : (
                        <Link className="text-decoration-none fs-5 align-middle" style={{ cursor: 'pointer' }} to="/login">
                            Login
                        </Link>
                    )}
                </Navbar.Text>
            </Navbar>

            {masquerading && (
                <Alert variant="success" className="mb-0 text-center">
                    <p className="mb-0">
                        You are currently masquerading.
                        <Alert.Link className="text-decoration-none ms-2" style={{ cursor: 'pointer' }} onClick={endMasquerade}>
                        End Masquerade <i class="bi bi-box-arrow-right"></i> 
                        </Alert.Link>
                    </p>
                </Alert>
            )}
        </div>
    );
}
