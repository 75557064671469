import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getCourseAccess = async (courseAccessId) => {
    const response = await axios.get(`CourseAccess/${courseAccessId}`, { headers: authHeader() });
    return response.data.result;
}

const createCourseAccess = async (courseId, employeeGroupId, doNotReportScore, showScoreToParticipant) => {
    console.log("createCourseAccess: " + courseId + " " + employeeGroupId + " " + doNotReportScore + " " + showScoreToParticipant);
    const data = JSON.stringify({courseId, employeeGroupId, doNotReportScore, showScoreToParticipant});
    const response = await axios.post('CourseAccess', data, { headers: authHeader() });
    return response.data.result;
}

const updateCourseAccess = async (id, doNotReportScore, showScoreToParticipant) => {
    const data = JSON.stringify({id, doNotReportScore, showScoreToParticipant});
    const response = await axios.put('CourseAccess', data, { headers: authHeader() });
    return response.data.result;
}

const reorderCourseAccesses = async (courseAccesses) => {
    const data = {
        orderedCourseAccess: courseAccesses,
    };
    const response = await axios.put('CourseAccess/reorder', data, { headers: authHeader() });
    return response.data.result;
}

const deleteCourseAccess = async (courseAccessId) => {
    const response = await axios.delete(`CourseAccess/${courseAccessId}`, { headers: authHeader() });
    return response.data.result;
}

export const courseAccessApi = {
    getCourseAccess,
    createCourseAccess,
    updateCourseAccess,
    reorderCourseAccesses,
    deleteCourseAccess,
}