import axios from "../_helpers/axiosInstance.js";

const validateUserActivationKey = async (activationKey) => {

    const response = await axios.get(`user/activationKey/${activationKey}`)
    return response.data.result;
}

export const userApi = {
    validateUserActivationKey
}