import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import * as formik from 'formik';
import * as yup from 'yup';

import { courseAccessApi } from "../../../api/courseAccess.js";
import { clientApi } from "../../../api/client.js";
import { employeeGroupApi } from "../../../api/employeeGroup.js";

export default function CourseAccessAdd(props) {
    
    const { courseId, courseAccessId, setIsAddingCourseAccess, isAddingCourseAccess, setIsUpdated, courseAccesses } = props;
    const { Formik } = formik;    
    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [clients, setClients] = useState([]);
    const [employeeGroups, setEmployeeGroups] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedEmployeeGroup, setSelectedEmployeeGroup] = useState(null);
    const [selectedCourseAccess, setSelectedCourseAccess] = useState(null);
    
    const schema = yup.object().shape({
        courseId: yup.string(), //.required("Course is required"),
        employeeGroupId: yup.string().required("Group is required"),
        doNotReportScore: yup.boolean(),
        showScoreToParticipant: yup.boolean()
    });

    const courseAccessDefaults = {
        courseId: courseId,
        employeeGroupId: "",
        doNotReportScore: false,
        showScoreToParticipant: true
    };
    
    const getCourseData = async () => {
        const course = await courseAccessApi.getCourseAccess(courseAccessId);
        setSelectedCourseAccess(course);
    };

    const getClientList = async () => {
        try {
            const response = await clientApi.getAllClients();
            setClients(response);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const getEmployeeGroupList = async (clientId) => {
        try {
            const response = await employeeGroupApi.getEmployeeGroups(clientId);
            
            // Filter out groups that already have access
            const filteredGroups = response.filter(group => 
                !courseAccesses.some(access => access.employeeGroupId === group.id)
            );
    
            setEmployeeGroups(filteredGroups);
        } catch (error) {
            console.error('Error fetching employee groups:', error);
        }
    };

    const handleCancel = () => {
        setIsAddingCourseAccess(false);
    };
    
    useEffect(() => {
        getClientList();
        if (courseAccessId) getCourseData();
    }, [courseAccessId]);

    return (
        <>
            <Formik        
                validationSchema={schema}                                
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        await courseAccessApi.createCourseAccess(courseId, values.employeeGroupId, values.doNotReportScore, values.showScoreToParticipant);
                        setIsAddingCourseAccess(false);
                        setIsUpdated(true);
                    } catch (e){
                        setPageState(prevState => ({...prevState, pageError: "Error Submitting Course" }));
                        console.error("Error Submitting Course" + e);
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedCourseAccess || courseAccessDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal 
                            show={isAddingCourseAccess} 
                            onHide={() => handleCancel()}
                            centered
                            animation={false}
                            backdrop="static"
                        >
                            {console.log('test', selectedClient, selectedEmployeeGroup, values)}

                            <Modal.Header className="bg-primary" closeButton>
                                <Modal.Title>Set Course Access</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bg-light py-5">
                                <Form.Group className="d-flex flex-column align-items-center" controlId="clientId">
                                    <Form.Label>Select a Client:</Form.Label>
                                    <Typeahead
                                        clearButton
                                        className="w-75 mt-3"
                                        id="clientId"
                                        options={clients.map(client => client.clientName)}
                                        placeholder="Select a client"
                                        onChange={(selected) => {
                                            const selectedClient = clients.find(client => client.clientName === selected[0]);
                                            const clientId = selectedClient ? selectedClient.id : '';
                                            setFieldValue('clientId', clientId);
                                            setSelectedClient(clientId);
                                            setEmployeeGroups([]);
                                            setSelectedEmployeeGroup(null);
                                            if (clientId) {
                                                getEmployeeGroupList(clientId);
                                            }
                                        }}
                                    />
                                </Form.Group>

                                {selectedClient && (
                                    <Form.Group className="d-flex flex-column align-items-center mt-4" controlId="employeeGroupId">
                                        <Form.Label>Select an Employee Group:</Form.Label>
                                        <Form.Control className="w-75 mt-3" as="select" 
                                            onChange={(e) => {
                                                const groupId = e.target.value;
                                                setFieldValue('employeeGroupId', groupId);
                                                setSelectedEmployeeGroup(groupId);
                                            }}>
                                            <option value="">Select an employee group</option>
                                            {employeeGroups.map(group => (
                                                <option key={group.id} value={group.id}>
                                                    {group.name}
                                                </option>
                                            ))}
                                            {touched.employeeGroupId && (<Form.Control.Feedback type="invalid">
                                                {errors.employeeGroupId}
                                            </Form.Control.Feedback>)}
                                        </Form.Control>
                                    </Form.Group>
                                )}

                                <Form.Group className="ms-5 mb-3 mt-4" controlId="doNotReportScore">
                                    <Form.Check
                                        className="ms-5"
                                        name="doNotReportScore"
                                        label="Do not report score"
                                        onChange={handleChange}
                                        checked={values.doNotReportScore}
                                        type="checkbox"
                                    />
                                </Form.Group>
                                <Form.Group className="ms-5" controlId="showScoreToParticipant">
                                    <Form.Check
                                        className="ms-5"
                                        name="showScoreToParticipant"
                                        label="Show score to participant"
                                        onChange={handleChange}
                                        checked={values.showScoreToParticipant}
                                        type="checkbox"
                                    />
                                </Form.Group>
                          
                            </Modal.Body>
                            <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                                <Button variant="info w-25 m-2" onClick={handleSubmit} disabled={isSubmitting || !dirty}>Save</Button>
                                <Button variant="info w-25 m-2" onClick={handleCancel}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>

            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}          
        </>
    )
}
