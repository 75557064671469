import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getInvoices = async (clientId) => {
    const response = await axios.get(`Invoice/client/${clientId}`, { headers: authHeader() });
    return response.data.result;
}

const getInvoice = async (invoiceId) => {
    const response = await axios.get(`Invoice/${invoiceId}`, { headers: authHeader() });
    return response.data.result;
}

const getMyInvoices = async () => {
    const response = await axios.get(`Invoice`, { headers: authHeader() });
    return response.data.result;
}

const deleteInvoice = async (invoiceId) => {
    const response = await axios.delete(`Invoice/${invoiceId}`, { headers: authHeader() });
    return response.data;
}

const getAllPPUCourses = async (clientId) => {
    const response = await axios.get(`Invoice/client/${clientId}/courses`, { headers: authHeader() });
    return response.data.result;
}

const createInvoice = async (clientId, address1, address2, city, country, state, zip, lineItems, waiveCharge) => {
    const data = JSON.stringify({ clientId, address1, address2, city, country, state, zip, lineItems, waiveCharge });
    await axios.post('Invoice', data, { headers: authHeader() });
}

const payInvoice = async (invoiceId, paymentIntent) => {
    const data = JSON.stringify({ paymentIntent, invoiceId });
    await axios.post('Invoice/pay', data, { headers: authHeader() });
}

export const invoiceApi = {
    getInvoices,
    getMyInvoices,
    getInvoice,
    createInvoice,
    deleteInvoice,
    getAllPPUCourses,
    payInvoice
}