import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import { feedbackQuestionApi } from "../../../api/feedbackQuestion.js";

export default function FeedbackQuestionEdit(props) {
    
    const { clientId, questionId, setIsEditingQuestion, setIsUpdated } = props;
    const { Formik } = formik;    
    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showOptionsInput, setShowOptionsInput] = useState(false);

    const schema = yup.object().shape({
        clientId: yup.string().required(),
        question: yup.string().required(),
        questionType: yup.string().required(),
        options: yup.string(),
    });

    const questionDefaults = {
        clientId: clientId,
        question: "",
        questionType: "",
        options: ""
    }
    console.log("questionId: " + questionId + " clientId: " + clientId);    
    
    console.log("selected question: " + JSON.stringify(selectedQuestion));
    
    const getQuestionData = async () => {
        const question = await feedbackQuestionApi.getFeedbackQuestion(questionId);
        setSelectedQuestion(question);
        setShowOptionsInput(question?.questionType === "options");
    };

    useEffect(() => {
        if (questionId) getQuestionData();
    }, [questionId]);

    return (
        <>
            <Row className="ms-3 mb-3">
                {selectedQuestion ? <h5 className="text-secondary mb-3">Edit Feedback Question</h5> : <h5 className="text-secondary mb-3">Create Feedback Question</h5>}
            </Row>           

            <Formik                                        
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        try {
                            if (selectedQuestion) {
                                await feedbackQuestionApi.updateFeedbackQuestion(values);
                            } else {
                                await feedbackQuestionApi.createFeedbackQuestion(values);
                            }
                            setIsEditingQuestion(false);
                            setIsUpdated(true);
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Error Submitting Question" }})
                            console.error("Error Submitting Question" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedQuestion || questionDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-5">
                            <Col md={7} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">

                                {/* Feedback Question Input */}
                                <Form.Group as={Row} className="mb-3" controlId="question">
                                    <Form.Label>Enter Question:</Form.Label>
                                    <Form.Control
                                        name="question"
                                        onChange={handleChange}
                                        value={values.question}
                                        type="text" 
                                        isInvalid={touched.question && !!errors.question}                                        
                                    />
                                    {touched.question && (<Form.Control.Feedback type="invalid">
                                        {errors.question}
                                    </Form.Control.Feedback>)}
                                </Form.Group>

                                {/* Feedback Question Type Input */}
                                <Form.Group as={Row} className="mb-3" controlId="questionType">
                                    <Form.Label>Question Type:</Form.Label>
                                    <Form.Select
                                        name="questionType"
                                        onChange={(e) => {
                                            handleChange(e);
                                            setShowOptionsInput(e.target.value === "options");
                                          }}
                                        value={values.questionType}
                                        isInvalid={touched.questionType && !!errors.questionType}                                        
                                    >
                                        <option>Select Type</option>
                                        <option value="text">Text</option>
                                        <option value="rating">Rating</option>
                                        <option value="options">Options</option>
                                    </Form.Select>
                                    {touched.questionType && (<Form.Control.Feedback questionType="invalid">
                                        {errors.questionType}
                                    </Form.Control.Feedback>)}
                                </Form.Group>

                                {/* Option Input - Conditionally rendered */}
                                {showOptionsInput && (
                                <Form.Group as={Row} className="mb-3" controlId="options">
                                    <Form.Label>Options (Enter each option on a new line):</Form.Label>
                                    <Form.Control
                                    name="options"
                                    as="textarea"
                                    onChange={handleChange}
                                    value={values.options}
                                    type="text"
                                    rows="4"
                                    isInvalid={touched.options && !!errors.options}
                                    />
                                    {touched.options && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.options}
                                    </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                )}

                                <Row>
                                    {/* Create/Update Question Button and Cancel Button */}
                                    <Col md={{span: 4, offset: 5}}>
                                        <Button className="btn-info m-2 w-100" type="submit" disabled={isSubmitting || !dirty}>Save Question</Button>
                                    </Col>
                                    <Col md={3}>
                                        <Button className="btn-info m-2 w-100 text-white" type="button" onClick={() => setIsEditingQuestion(false)}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form> 
                )}
            </Formik>
        </>
    )
}