import React, { useState, useEffect } from "react";
import { Container, Col, Form, Row, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";

import AdminUserList from "./AdminUserList.jsx";
import AdminUserEdit from "./AdminUserEdit.jsx";
import { adminUserApi } from "../../../api/adminUser.js";

export default function AdminUsers() {
    // const { tab } = useParams();
    // const { client, clientId,  } = props;

    const [adminUsers, setAdminUsers] = useState([]);
    const [isEditingUser, setIsEditingUser] = useState(false);
    const [userIdToEdit, setUserIdToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    // if (isEditingUser && tab !== "details" && tab !== "") {
    //     setIsEditingUser(false);
    // }

    const getAdminUsers = async () => {
        const response = await adminUserApi.getAllAdminUsers();
        setAdminUsers(response);
    };
    

    const toggleEditing = (userId) => {
        setIsEditingUser(!isEditingUser);
        setUserIdToEdit(userId);
    };

    useEffect(() => {
        getAdminUsers();
    }, []);

    useEffect(() => {
        if (isUpdated) {
            getAdminUsers();
            setIsUpdated(false);
        }
    }, [isUpdated]);


    return (
        <>
            { adminUsers.length > 0 && 
                <>
                    <Row>
                        {isEditingUser ? (
                            <AdminUserEdit 
                                userId={userIdToEdit} 
                                setIsEditingUser={toggleEditing} 
                                setIsUpdated={setIsUpdated} 
                            />
                        ) : (
                            <AdminUserList 
                                users={adminUsers} 
                                setIsEditingUser={toggleEditing} 
                                setUserIdToEdit={setUserIdToEdit} 
                                isUpdated={isUpdated} 
                                setIsUpdated={setIsUpdated} 
                            />
                        )}
                    </Row>
                </>
            }
        </>            
    );
}
