import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getAccount = async () => {
    const response = await axios.get(`Account`, { headers: authHeader() });
    return response.data.result;
};

const updateEmailAddress = async (emailAddress) => {
    const response = await axios.post(`Account`, emailAddress, { headers: authHeader() });
    return response.data.result;
};

const updatePassword = async (password) => {
    const response = await axios.post(`Account/password`, password, { headers: authHeader() });
    return response.data.result;
};

export const accountApi = {
    getAccount,
    updateEmailAddress,
    updatePassword
};