import React, { useCallback, useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";

import { clientApi } from "../../../api/client.js";
import { clientUserApi } from "../../../api/clientUser.js";
import ActionBar from "../../../components/ActionBar.jsx";
import formatDate from "../../../_helpers/formatDate";

export default function ClientUserList(props) {
    const { clientId, users, setIsEditingUser, setUserIdToEdit, isUpdated, setIsUpdated } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'username', headerName: 'Username', flex: 2 }, 
        { field: 'emailAddress', headerName: 'Email', flex: 3 },
        { field: 'accountActivated', headerName: 'Verified', flex: 2 },
        { field: 'dateCreated', headerName: 'Created', flex: 3, valueFormatter: (params) => formatDate(params.data.dateCreated) },
        { field: 'clientUserId', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 2 }
    ]);

    const [userCount, setUserCount] = useState(users.length);

    const onGridReady = useCallback(() => {
        if (users) {
            setRowData(users);
        }
    }, [users]);

    const handleEditUser = (userId) => {
        setIsEditingUser(true);
        setUserIdToEdit(userId);
    };
    
    const handleNewUser = () => {
        setIsEditingUser(true);
        setUserIdToEdit(null);
        console.log("handleNewUser");
    };
    
    const handleDeleteUser = async (userId) => {
        try {
            await clientUserApi.deleteClientUser(userId);
            refreshUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('There was an error deleting the user: ', error);
        }
    };

    const refreshUsers = async () => {
        clientApi.getClient(clientId).then((client) => {
            setRowData(client.clientUsers);
            setUserCount(client.clientUsers.length);
        })
    };

    useEffect(() => {
        if (isUpdated) {
            refreshUsers();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Client Admin Users</h5>
                </Col>
                <Col md={5} style={{textAlign:"right"}}>     
                    <Button variant="info" onClick={handleNewUser}>+ Add User</Button>
                </Col>
            </Row>
            <Row className="mt-5"> 
                <Col md={11} className="ag-theme-alpine">
                    {rowData &&
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            components={{
                                'actionRenderer': (props) => (
                                    <ActionBar 
                                        id={props.data.clientUserId} 
                                        name={props.data.username} 
                                        onDelete={userCount > 1 ? handleDeleteUser : 'hideDelete'} 
                                        onEdit={handleEditUser}    
                                        showMasquerade={true}                                     
                                    />
                                ),
                            }}
                            onGridReady={onGridReady}
                            domLayout='autoHeight'
                        />
                    }
                </Col>
            </Row>
        </>            
    );
}
