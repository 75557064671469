import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import ClientDetails from "./ClientDetails.jsx";
import EmployeeGroups from "./EmployeeGroups.jsx";
import CourseCredits from "./CourseCredits.jsx";
import PostLoginQuestions from "./PostLoginQuestions.jsx";
import FeedbackQuestions from "./FeedbackQuestions.jsx";

import TitleElement from "../../../components/TitleElement.jsx";
import LoadingBar from "../../../components/LoadingBar.jsx";
import { clientApi } from "../../../api/client.js";

export default function EditClientTabManager() {

	const { clientId } = useParams();
	const location = useLocation(); 
	const navigate = useNavigate();
	const [selectedClient, setSelectedClient] = useState(null);
	const [activeTab, setActiveTab] = useState("details");

	const handleTabSelect = (key) => {
		navigate(`/client/${clientId}/${key}`, { replace: true });
	};

	const getClientData = async () => {
		const client = await clientApi.getClient(clientId);
		setSelectedClient(client);
	};

	useEffect(() => {
		// Extract the last part of the URL to determine the active tab
		const pathParts = location.pathname.split("/");
		const currentTab = pathParts[pathParts.length - 1];
	
		// Set the active tab based on the current path
		if (pathParts.length === 3) {
			// If the URL doesn't specify a tab, redirect to /clientId/details
			navigate(`/client/${clientId}/details`, { replace: true });
		} else {
			setActiveTab(currentTab);
		}
	}, [location.pathname, navigate, clientId]);

	useEffect(() => {
		getClientData();
	}, [clientId]);

	return (
		<>
		{selectedClient ? (
			<>
				<TitleElement title={"Client: " + selectedClient.clientName} noRule />

				<Tabs defaultActiveKey={"details"} 
					activeKey={activeTab} 
					className="mb-5 border-bottom border-primary"
					variant="underline"
					onSelect={handleTabSelect}
					justify>

					<Tab eventKey="details" title="Details">
						<ClientDetails client={selectedClient} clientId={clientId} users={selectedClient.clientUsers} />
					</Tab>
					<Tab eventKey="groups" title="Employee Groups">
						<EmployeeGroups client={selectedClient} />
					</Tab>
					<Tab eventKey="credits" title="Course Credits">
						<CourseCredits />
					</Tab>
					<Tab eventKey="postlogin" title="Post-Login Questions">
						<PostLoginQuestions />
					</Tab>
					<Tab eventKey="feedback" title="Feedback Questions">
						<FeedbackQuestions />
					</Tab>
				</Tabs>
			</>
		) : (
			<LoadingBar />
		)}
	</>
  );
}
