// Generate an 8 character password with at least 1 uppercase, 1 lowercase, and 1 number

export function generatePassword() {
  const lowercase = Array(3).fill('').map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 97));
  const uppercase = Array(3).fill('').map(() => String.fromCharCode(Math.floor(Math.random() * 26) + 65));
  const numbers = Array(2).fill('').map(() => Math.floor(Math.random() * 10));

  // Combine and shuffle characters
  const combined = [...lowercase, ...uppercase, ...numbers];
  const shuffled = combined.sort(() => Math.random() - 0.5);

  return shuffled.join('');
}