import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getEmployeeGroups = async (clientId) => {
    const response = await axios.get(`EmployeeGroup/client/${clientId}`, { headers: authHeader() });
    return response.data.result;
}

const getEmployeeGroup = async (employeeGroupId) => {
    const response = await axios.get(`EmployeeGroup/${employeeGroupId}`, { headers: authHeader() });
    return response.data.result;
}

const getEmployeeGroupCourses = async (employeeGroupId) => {
    const response = await axios.get(`EmployeeGroup/${employeeGroupId}/CourseAccess`, { headers: authHeader() });
    console.log("getEmployeeGroupCourses: " + JSON.stringify(response.data.result));
    return response.data.result;
}

const createEmployeeGroup = async (employeeGroup) => {
    const data = JSON.stringify(employeeGroup);
    const response = await axios.post('EmployeeGroup', data, { headers: authHeader() });
    return response.data.result;
}

const updateEmployeeGroup = async (employeeGroup) => {
    const data = JSON.stringify(employeeGroup);
    const response = await axios.put('EmployeeGroup', data, { headers: authHeader() });
    return response.data.result;
}

const deleteEmployeeGroup = async (employeeGroupId) => {
    const response = await axios.delete(`EmployeeGroup/${employeeGroupId}`, { headers: authHeader() });
    return response.data.result;
}

export const employeeGroupApi = {
    getEmployeeGroups,
    getEmployeeGroup,
    getEmployeeGroupCourses,
    createEmployeeGroup,
    updateEmployeeGroup,
    deleteEmployeeGroup
}