import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getAllAdminUsers = async () => {
    const response = await axios.get('AdminUser', { headers: authHeader() });
    return response.data.result;
};

const getAdminUser = async (id) => {
    const response = await axios.get(`AdminUser/${id}`, { headers: authHeader() });
    return response.data.result;
};

const createAdminUser = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.post('AdminUser', data, { headers: authHeader() });
    return response.data.result;
};

const updateAdminUser = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.put('AdminUser', data, { headers: authHeader() });
    return response.data.result;
};

const deleteAdminUser = async (id) => {
    console.log("Deleting User: " + id);
    const response = await axios.delete(`AdminUser/${id}`, {headers: authHeader() });
    return response.data.result;
};

const activateAdminUser = async (activationKey, password) => {
    const data = JSON.stringify({ activationKey, password });
    const response = await axios.post(`AdminUser/activate`, data, { headers: authHeader() });
    return response.data.result;
};
const resendInvite = async (userId) => {
    await axios.put(`AdminUser/${userId}/invite`, null, {headers: authHeader()});
}

export const adminUserApi = {
    getAllAdminUsers,
    getAdminUser,
    createAdminUser,
    updateAdminUser,
    deleteAdminUser,
    activateAdminUser,
    resendInvite
};