import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CourseAccessList from './CourseAccessList.jsx';
import CourseAccessEdit from './CourseAccessEdit.jsx';
import { courseApi } from '../../../api/course.js';
import CourseAccessAdd from './CourseAccessAdd.jsx';

export default function CourseAccess() {

    const { courseId, tab } = useParams();

    const [courseAccesses, setCourseAccesses] = useState([]);
    const [isEditingCourseAccess, setIsEditingCourseAccess] = useState(false);
    const [isAddingCourseAccess, setIsAddingCourseAccess] = useState(false);
    const [courseAccessIdToEdit, setCourseAccessIdToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);

    const toggleEditing = (courseAccessId) => {
        setCourseAccessIdToEdit(courseAccessId);
        setIsEditingCourseAccess(!isEditingCourseAccess);
    }; 

    const getCourseAccess = async () => {
        const response = await courseApi.getCourse(courseId);
        setCourseAccesses(response.employeeGroupAccess);
    };

    useEffect(() => {
        if (tab === "access") {
            getCourseAccess();
        } else {
            setIsEditingCourseAccess(false);
        }
    }, [tab]);

    useEffect(() => {
        if (isUpdated) {
            getCourseAccess();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <>
            {isAddingCourseAccess && (
                <CourseAccessAdd 
                    courseId={courseId} 
                    courseAccessId={courseAccessIdToEdit} 
                    setIsAddingCourseAccess={setIsAddingCourseAccess}
                    isAddingCourseAccess={isAddingCourseAccess} 
                    setIsUpdated={setIsUpdated} 
                    courseAccesses={courseAccesses}
                />
            )}
            {isEditingCourseAccess && (
                <CourseAccessEdit 
                    courseId={courseId} 
                    courseAccessId={courseAccessIdToEdit} 
                    setIsEditingCourseAccess={setIsEditingCourseAccess} 
                    isEditingCourseAccess={isEditingCourseAccess}
                    setIsUpdated={setIsUpdated} 
                />
            )}
            <CourseAccessList 
                    courseId={courseId} 
                    courseAccesses={courseAccesses} 
                    setIsEditingCourseAccess={toggleEditing} 
                    setIsAddingCourseAccess={setIsAddingCourseAccess}
                    setCourseAccessIdToEdit={setCourseAccessIdToEdit} 
                    isUpdated={isUpdated} 
                    setIsUpdated={setIsUpdated} 
                    isEmpty={!courseAccesses || courseAccesses.length === 0}
                />
        </>
    );
}
