import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";

import ActionBar from "../../../components/ActionBar.jsx";
import { assessmentQuestionApi } from "../../../api/assessmentQuestion.js";

export default function AssessmentQuestionOptionList(props) {

    const { questionOptions, setIsEditingQuestionOption, setQuestionOptionIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'text', headerName: 'Option Text', rowDrag: true, flex: 4 },
        { field: 'selectionResponse', headerName: 'Selection Response', flex: 4 },
        { field: 'correct', headerName: 'Correct', flex: 1, cellRenderer: 'correctRenderer' },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);

    useEffect(() => {
        if (questionOptions) {
            setRowData(questionOptions);
        }
    }, [questionOptions]);

    const handleEditQuestionOption = (questionOptionId) => {
        setIsEditingQuestionOption(true);
        setQuestionOptionIdToEdit(questionOptionId);
    };

    const handleNewQuestionOption = () => {
        setIsEditingQuestionOption(true);
        setQuestionOptionIdToEdit(null);
    };

    const onRowDragEnd = (params) => {
        let newOrder = params.api.getDisplayedRowAtIndex(0).data.id;
        for (let i = 1; i < params.api.getDisplayedRowCount(); i++) {
            newOrder += ',' + params.api.getDisplayedRowAtIndex(i).data.id;
        }
        const newOrderIntegers = newOrder.split(',').map(idString => parseInt(idString, 10));
        assessmentQuestionApi.reorderAssessmentQuestionOptions(newOrderIntegers).then(() => {
                console.log('Option order successfully updated on backend');
            })
            .catch((error) => {
                console.error('Error reordering options on backend:', error);
        });
    };

    const handleDeleteQuestionOption = async (questionOptionId) => {
        try {
            await assessmentQuestionApi.deleteAssessmentQuestionOption(questionOptionId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting option:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Options attached to this Question.&nbsp;
            <Link to="#" onClick={handleNewQuestionOption} className="text-info">Add an option</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Question Options</h5>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                    <Button variant="info" onClick={handleNewQuestionOption}>+ Add Option</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.text}
                                    onDelete={handleDeleteQuestionOption}
                                    onEdit={handleEditQuestionOption}
                                />
                            ),
                            'correctRenderer': (props) => (
                                <input
                                    type="checkbox"
                                    disabled
                                    checked={props.value}
                                />
                            ),
                        }}
                        onRowDragEnd={onRowDragEnd}
                        rowDragManaged={true}
                        suppressMoveWhenRowDragging={true}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>
        </>
    );
}
