import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";

import { invoiceApi } from "../../../api/invoice.js";
import formatDate from "../../../_helpers/formatDate.js";
import LoadingBar from "../../../components/LoadingBar.jsx";

const STRIPE_PUBLISHABLE_KEY = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

function CustomerInvoiceDetails() {
    const [invoice, setInvoice] = useState(null);
    const { invoiceId } = useParams();

    useEffect(() => {
        const getInvoiceDetails = async () => {
            const invoiceDetails = await invoiceApi.getInvoice(invoiceId);
            console.log(invoiceDetails);
            setInvoice(invoiceDetails);
        };
        getInvoiceDetails();
    }, [invoiceId]);

    const onPaymentComplete = async () => {
        const invoiceDetails = await invoiceApi.getInvoice(invoiceId);
        console.log(invoiceDetails);
        setInvoice(invoiceDetails);
    };
    if (!invoice) {
        return <LoadingBar className="mt-5" />;
    }

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h3 className="text-secondary">Invoice #{invoice.id}{invoice.paid && <span> [PAID]</span>}</h3>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3">
                <Col md={12} className="ag-theme-alpine">
                    <InvoiceTable lineItems={invoice.lineItems} />
                </Col>
            </Row>
            <Row>
                <Col md={12} className="text-end">
                    <InvoiceTotals subTotal={invoice.subTotal} tax={invoice.tax} total={invoice.total} />
                </Col>
            </Row>
            {invoice.paid ? (
                <PaymentInformation
                    datePaid={invoice.datePaid}
                    cardType={invoice.cardType}
                    lastFour={invoice.lastFour}
                />
            ) : (
                <Elements stripe={stripePromise} options={{ clientSecret: invoice.paymentIntent.clientSecret }}>
                    <PaymentForm paymentIntent={invoice.paymentIntent} 
                        onPaymentComplete={onPaymentComplete} invoiceId={invoice.id} />
                </Elements>
            )}
        </>
    );
}

function InvoiceTable({ lineItems }) {
    const [columnDefs] = useState([
        { field: 'courseDisplayName', headerName: 'Course', flex: 2, resizable: false },
        { field: 'quantity', headerName: 'Quantity', flex: 1, resizable: false },
        {
            field: 'price',
            headerName: 'Price',
            flex: 1,
            resizable: false,
            valueFormatter: params => {
                const price = params.value;
                return price !== null && price !== undefined ? `$${price.toFixed(2)}` : 'N/A';
            }
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            resizable: false,
            valueFormatter: params => {
                const price = params.data.price;
                const quantity = params.data.quantity;
                return price !== null && price !== undefined && quantity !== null && quantity !== undefined
                    ? `$${(price * quantity).toFixed(2)}`
                    : 'N/A';
            }
        }
    ]);

    const defaultColDef = {
        sortable: false,
        filter: false,
        resizable: false,
        suppressMenu: true,
        suppressMovable: true,
        cellStyle: { cursor: 'default' },
    };

    const [rowData] = useState(lineItems.map(item => ({
        ...item,
        total: item.price * item.quantity,
    })));

    return (
        <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowHoverHighlight={true}
            suppressColumnVirtualisation={true}
            suppressRowClickSelection={true}
            suppressCellSelection={true}
            domLayout='autoHeight'
        />
    );
}

function InvoiceTotals({ subTotal, tax, total }) {
    return (
        <Col md={{ span: 3, offset: 9 }}>
            <p style={{ backgroundColor: '#B3D3F444', padding: '10px' }}>Subtotal: {subTotal !== null && subTotal !== undefined ? `$${subTotal.toFixed(2)}` : 'N/A'}</p>
            <p style={{ backgroundColor: '#B3D3F444', padding: '10px' }}>Tax: {tax !== null && tax !== undefined ? `$${tax.toFixed(2)}` : 'N/A'}</p>
            <p style={{ backgroundColor: '#B3D3F444', padding: '10px' }}><strong>Total: {total !== null && total !== undefined ? `$${total.toFixed(2)}` : 'N/A'}</strong></p>
        </Col>
    );
}

function PaymentInformation({ datePaid, cardType, lastFour }) {
    return (
        <>
            <h5 className="text-secondary">Payment Information</h5>
            <hr className="border-secondary" />
            <Row className="payment-information mt-3 bg-light shadow p-4 rounded-3 mb-5">
                <Row>
                <Col md={6}>
                        <strong>Status:</strong> PAID <br />
                        <strong>Payment Date:</strong> {formatDate(datePaid)}
                    </Col>
                    <Col md={6}>
                        <strong>Credit Card Type:</strong> {cardType}<br />
                        <strong>Credit Card Number:</strong> {`**** **** **** ${lastFour}`}
                    </Col>
                </Row>
            </Row>
        </>
    );
}

function PaymentForm({invoiceId, paymentIntent, onPaymentComplete }) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
            return;
        }
    
        // First, submit the elements
        const { error: submitError } = await elements.submit();
        
        if (submitError) {
            setError(submitError.message);
            setSuccess(false);
            return;
        }
    
        // Confirm the payment
        const { error, paymentIntent: confirmedPaymentIntent } = await stripe.confirmPayment({
            elements,
            redirect: "if_required"
        });
    
        if (error) {
            setError(error.message);
            setSuccess(false);
        } else if (confirmedPaymentIntent.status === 'succeeded') {
            setSuccess(true);
            setError(null);
    
            try {
                await invoiceApi.payInvoice(invoiceId, paymentIntent.id);
                onPaymentComplete();
            } catch (error) {
                console.error('Error updating invoice status:', error);
            }
        } else {
            setSuccess(false);
            setError('Payment failed');
        }
    };
    const paymentElementOptions = {
        layout: 'tabs',
        paymentMethodOrder: ['card']
    };

    const stripeOptions = {
        clientSecret: paymentIntent.clientSecret
    };

    return (
        <form onSubmit={handleSubmit} className="payment-form">
            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            {success && <Alert variant="success" className="mt-3">Payment successful!</Alert>}
            <PaymentElement options={paymentElementOptions} />
            <Button type="submit" className="mt-3 w-25" variant="info" disabled={!stripe} style={{float: 'right'}}>Pay</Button>
           
        </form>
    );
}

export default CustomerInvoiceDetails;
