import React, { createContext, useContext, useState, useCallback } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({ show: false, message: '', type: 'light', error: false });

  const showNotification = useCallback((message, type = 'primary') => {
    setNotification({ show: true, message, type: type.toLowerCase() });
    setTimeout(() => setNotification({ ...notification, show: false }), 3000); // Auto-hide after 3 seconds
  }, [notification]);

  const showError = useCallback((message) => {
    setNotification({ show: true, message, type: 'danger', error: true });
    setTimeout(() => setNotification({ ...notification, show: false }), 5000); // Auto-hide after 5 seconds
  }, [notification]);

  const showInfo = useCallback((message) => {
    setNotification({ show: true, message, type: 'primary', error: false });
    setTimeout(() => setNotification({ ...notification, show: false }), 3000); // Auto-hide after 3 seconds
  }, [notification]);

  return (
    <NotificationContext.Provider value={{ showNotification, showError, showInfo }}>
      {children}
      <ToastContainer
        className="p-3"
        style={{
          position: 'fixed',
          bottom: '30px',
          right: '20px',
          // transform: 'translateX(-50%)',
          zIndex: 1050,
        }}
      >
        <Toast bg={notification.type} onClose={() => setNotification({ ...notification, show: false })} show={notification.show}>
          <Toast.Body className={notification.type}>
            {notification.message}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </NotificationContext.Provider>
  );
};
