import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getAllClients = async () => {
    const response = await axios.get('Client', { headers: authHeader() });
    return response.data.result;
}

const getClient = async (clientId) => {
    const response = await axios.get(`Client/${clientId}`, { headers: authHeader() });
    return response.data.result;
}

const checkClientNameAvailablity = async (clientName) => {
    const clients = await getAllClients();
    return clients.some((client) => client.clientName.toLowerCase() === clientName.toLowerCase());
};

const createClient = async (client) => {
    const formData = new FormData();

    formData.append('ClientName', client.clientName);
    formData.append('AdminUsername', client.adminUsername);
    formData.append('AdminFirstName', client.adminFirstName);
    formData.append('AdminLastName', client.adminLastName);
    formData.append('CanViewReports', client.canViewReports);
    formData.append('AdminPhoneNumber', client.adminPhoneNumber);
    formData.append('AdminEmail', client.adminEmail);
    formData.append('Password', client.password);
    formData.append('Logo', client.logo);
    formData.append('PayPerUse', client.payPerUse);

    const response = await axios.post('Client', formData, { headers: { 'content-type': 'multipart/form-data', ...authHeader() } });
    return response.data.result;
}

const updateClient = async (client) => {
    const formData = new FormData();

    formData.append('ClientId', client.clientId);
    formData.append('ClientName', client.clientName);
    formData.append('Logo', client.logo);
    formData.append('PayPerUse', client.payPerUse);
    formData.append('CanViewReports', client.canViewReports);
    formData.append('PurchaserName', client.purchaserName);

    const response = await axios.put('Client', formData, { headers: { 'content-type': 'multipart/form-data', ...authHeader() } });
    return response.data.result;
}

const deleteClient = async (clientId) => {
    const response = await axios.delete(`Client/${clientId}`, { headers: authHeader() });
    return response.data.result;
}

export const clientApi = {
    getAllClients,
    getClient,
    checkClientNameAvailablity,
    createClient,
    updateClient,
    deleteClient
}