import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Modal } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import { courseAccessApi } from "../../../api/courseAccess.js";
import { courseApi } from "../../../api/course.js";

export default function EmployeeGroupCourseAdd(props) {
    
    const { groupId, setIsAddingCourseAccess, isAddingCourseAccess, setIsUpdated, courseAccesses } = props;
    const { Formik } = formik;    
    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedCourseAccess, setSelectedCourseAccess] = useState(null);
    
    const schema = yup.object().shape({
        courseId: yup.string(),//.required("Course is required"),
        doNotReportScore: yup.boolean(),
        showScoreToParticipant: yup.boolean()
    });

    const courseAccessDefaults = {
        courseId: "",
        employeeGroupId: groupId,
        doNotReportScore: false,
        showScoreToParticipant: true
    };
    

    const getCourseList = async () => {
        try {
            const response = await courseApi.getAllCourses();
            
            // Filter out courses that already have access for a specific employee group
            const filteredCourses = response.filter(course => 
                !courseAccesses.some(access => access.courseId === course.id)
            );
    
            setCourses(filteredCourses);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const handleCancel = () => {
        setIsAddingCourseAccess(false);
    };
    
    useEffect(() => {
        getCourseList();
    }, []);

    return (
        <>
            <Formik        
                validationSchema={schema}                                
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        await courseAccessApi.createCourseAccess(values.courseId, groupId, values.doNotReportScore, values.showScoreToParticipant);
                        setIsAddingCourseAccess(false);
                        setIsUpdated(true);
                    } catch (e){
                        setPageState(prevState => ({...prevState, pageError: "Error Submitting Course" }));
                        console.error("Error Submitting Course" + e);
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedCourseAccess || courseAccessDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors, setFieldValue }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal 
                            show={isAddingCourseAccess} 
                            onHide={() => handleCancel()}
                            centered
                            animation={false}
                            backdrop="static"
                        >
                            {console.log('test', selectedCourse, values)}

                            <Modal.Header className="bg-primary" closeButton>
                                <Modal.Title>Add Course Access</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="bg-light py-5">
                                <Form.Group className="d-flex flex-column align-items-center" controlId="courseId">
                                    <Form.Label>Select a Course:</Form.Label>
                                    <Form.Control className="w-75 mt-3" as="select" 
                                        onChange={(e) => {
                                            setFieldValue('courseId', e.target.value);
                                            setSelectedCourse(e.target.value);
                                        }}>
                                        <option value="">Select a course</option>
                                        {courses.map(course => (
                                            <option key={course.id} value={course.id}>
                                                {course.courseName}
                                            </option>
                                        ))}
                                        {touched.courseId && (<Form.Control.Feedback type="invalid">
                                            {errors.courseId}
                                        </Form.Control.Feedback>)}
                                    </Form.Control>
                                </Form.Group>
  
                                <Form.Group className="ms-5 mb-3 mt-4" controlId="doNotReportScore">
                                    <Form.Check
                                        className="ms-5"
                                        name="doNotReportScore"
                                        label="Do not report score"
                                        onChange={handleChange}
                                        checked={values.doNotReportScore}
                                        type="checkbox"
                                    />
                                </Form.Group>
                                <Form.Group className="ms-5" controlId="showScoreToParticipant">
                                    <Form.Check
                                        className="ms-5"
                                        name="showScoreToParticipant"
                                        label="Show score to participant"
                                        onChange={handleChange}
                                        checked={values.showScoreToParticipant}
                                        type="checkbox"
                                    />
                                </Form.Group>
                          
                            </Modal.Body>
                            <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                                <Button variant="info w-25 m-2" onClick={handleSubmit} disabled={isSubmitting || !dirty}>Save</Button>
                                <Button variant="info w-25 m-2" onClick={handleCancel}>Cancel</Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                )}
            </Formik>

            {pageState.pageError && (
                <Alert key="pageError" variant="danger mt-3">
                    {pageState.pageError}
                </Alert>
            )}          
        </>
    )
}
