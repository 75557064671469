import React, { useState, useEffect } from "react";
import { Col, Row, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Typeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import ActionBar from "../../../components/ActionBar.jsx";
import { feedbackQuestionApi } from "../../../api/feedbackQuestion.js";
import { clientApi } from "../../../api/client.js";

export default function FeedbackQuestionList(props) {

    const { clientId, questions, setIsEditingQuestion, setQuestionIdToEdit, setIsUpdated, isEmpty } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs] = useState([
        { field: 'question', headerName: 'Question', flex: 8, rowDrag: true, sortable: true },
        { field: 'questionType', headerName: 'Type', flex: 3 },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);
    const [showModal, setShowModal] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    useEffect(() => {
        if (questions) {
            setRowData(questions);
        }
    }, [questions]);

    const handleEditQuestion = (questionId) => {
        setIsEditingQuestion(true);
        setQuestionIdToEdit(questionId);
    };

    const handleNewQuestion = () => {
        setIsEditingQuestion(true);
        setQuestionIdToEdit(null);
    };

    const handleImportQuestions = () => {
        getClientList();
        setShowModal(true);
    };

    const onRowDragEnd = (params) => {
        let newOrder = params.api.getDisplayedRowAtIndex(0).data.id;
        for (let i = 1; i < params.api.getDisplayedRowCount(); i++) {
            newOrder += ',' + params.api.getDisplayedRowAtIndex(i).data.id;
        }
        const newOrderIntegers = newOrder.split(',').map(idString => parseInt(idString, 10));
        feedbackQuestionApi.reorderFeedbackQuestions(newOrderIntegers).then(() => {
                console.log('Questions order successfully updated on backend');
            })
            .catch((error) => {
                console.error('Error reordering questions on backend:', error);
        });
    };

    const getClientList = async () => {
        try {
            const response = await clientApi.getAllClients();
            setClients(response);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const handleCopyQuestions = async () => {
        if (!selectedClient) return;

        try {
            await feedbackQuestionApi.copyFeedbackQuestions(selectedClient, clientId);
            setShowModal(false);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error copying questions:', error);
        }
    };

    const handleDeleteQuestion = async (questionId) => {
        try {
            await feedbackQuestionApi.deleteFeedbackQuestion(questionId);
            setIsUpdated(true);
        } catch (error) {
            console.error('Error deleting question:', error);
        }
    };

    const EmptyTableMessage = () => (
        <h6 className="d-flex justify-content-center text-secondary">
            No Feedback Questions found.&nbsp;
            <Link to="#" onClick={handleNewQuestion} className="text-info">Add a question</Link>&nbsp;
            or&nbsp;
            <Link to="#" onClick={handleImportQuestions} className="text-info">import questions from an existing client</Link>.
        </h6>
    );

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h5 className="text-secondary">Feedback Questions</h5>
                </Col>
                <Col md={6} style={{ textAlign: "right" }}>
                    <Button variant="info" onClick={handleNewQuestion}>+ Add Question</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col md={12} className="ag-theme-alpine">
                    {isEmpty ? <EmptyTableMessage /> : <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                                <ActionBar
                                    id={props.data.id}
                                    name={props.data.question}
                                    onDelete={handleDeleteQuestion}
                                    onEdit={handleEditQuestion}
                                />
                            ),
                        }}
                        onRowDragEnd={onRowDragEnd}
                        rowDragManaged={true}
                        suppressMoveWhenRowDragging={true}
                        domLayout='autoHeight'
                    />}
                </Col>
            </Row>

            <Modal 
                show={showModal} 
                onHide={() => setShowModal(false)}
                centered
                animation={false}
            >
                <Modal.Header className="bg-primary" closeButton>
                    <Modal.Title>Import Questions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light text-center py-5">
                    <Form>
                        <Form.Group className="mt-3 d-flex flex-column align-items-center">
                            <Form.Label>Select a Client to import Feedback Questions from:</Form.Label>
                            <Typeahead
                                clearButton
                                className="w-75 mt-3"
                                id="select-client"
                                options={clients.map(client => client.clientName)}
                                placeholder="Select a client"
                                onChange={(selected) => {
                                    const selectedClient = clients.find(client => client.clientName === selected[0]);
                                    setSelectedClient(selectedClient ? selectedClient.id : '');
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                    <Button variant="info w-25 m-2" onClick={handleCopyQuestions}>Import</Button>
                    <Button variant="info w-25 m-2" onClick={() => setShowModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
