import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import EmployeeGroupCourseList from './EmployeeGroupCourseList.jsx';
import EmployeeGroupCourseEdit from './EmployeeGroupCourseEdit.jsx';
import { employeeGroupApi } from '../../../api/employeeGroup.js';
import EmployeeGroupCourseAdd from './EmployeeGroupCourseAdd.jsx';

export default function EmployeeGroupCourses(props) {
    console.log("EmployeeGroupCourses: " + JSON.stringify(props));

    const { tab } = useParams();
    const { groupId } = props;

    const [courseAccesses, setCourseAccesses] = useState([]);
    const [isEditingCourseAccess, setIsEditingCourseAccess] = useState(false);
    const [isAddingCourseAccess, setIsAddingCourseAccess] = useState(false);
    const [courseAccessIdToEdit, setCourseAccessIdToEdit] = useState(null);
    const [isUpdated, setIsUpdated] = useState(false);


    const getEmployeeGroupCourses = async () => {
        const response = await employeeGroupApi.getEmployeeGroupCourses(groupId);
        setCourseAccesses(response);
    };

    useEffect(() => {
        if (tab === "groups") {
            getEmployeeGroupCourses();
        } else {
            setIsEditingCourseAccess(false);
        }
    }, [tab]);

    useEffect(() => {
        if (isUpdated) {
            getEmployeeGroupCourses();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <>
            {isEditingCourseAccess && (
                <EmployeeGroupCourseEdit 
                    groupId={groupId} 
                    courseAccessId={courseAccessIdToEdit} 
                    setIsEditingCourseAccess={setIsEditingCourseAccess} 
                    isEditingCourseAccess={isEditingCourseAccess}
                    setIsUpdated={setIsUpdated} 
                />
            )}
            {isAddingCourseAccess && (
                <EmployeeGroupCourseAdd 
                    groupId={groupId}
                    setIsAddingCourseAccess={setIsAddingCourseAccess} 
                    isAddingCourseAccess={isAddingCourseAccess}
                    setIsUpdated={setIsUpdated} 
                    courseAccesses={courseAccesses}
                />
            )}
            <EmployeeGroupCourseList 
                    groupId={groupId} 
                    courseAccesses={courseAccesses} 
                    setIsEditingCourseAccess={setIsEditingCourseAccess} 
                    setIsAddingCourseAccess={setIsAddingCourseAccess}
                    setCourseAccessIdToEdit={setCourseAccessIdToEdit} 
                    isUpdated={isUpdated} 
                    setIsUpdated={setIsUpdated} 
                    isEmpty={!courseAccesses || courseAccesses.length === 0}
                />
        </>
    );
}
