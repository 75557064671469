import React, { useCallback, useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";

import ActionBar from "../../../components/ActionBar.jsx";
import formatDate from "../../../_helpers/formatDate";
import { adminUserApi } from "../../../api/adminUser.js";

export default function AdminUserList(props) {
    const { users, setIsEditingUser, setUserIdToEdit, isUpdated, setIsUpdated } = props;

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        { field: 'username', headerName: 'Username', flex: 2 }, 
        { field: 'email', headerName: 'Email', flex: 3 },
        { field: 'active', headerName: 'Verified', flex: 2 },
        { field: 'dateActivated', headerName: 'Created', flex: 4, valueFormatter: params => params.value ? formatDate(params.value) : '' },
        { field: 'role', headerName: 'Role', flex: 2 },
        { field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1 }
    ]);

    const [userCount, setUserCount] = useState(users.length);

    const onGridReady = useCallback(() => {
        if (users) {
            setRowData(users);
            console.log("setRowData", users);
        }
    }, [users]);

    const handleEditUser = (userId) => {
        setIsEditingUser(true);
        setUserIdToEdit(userId);
    };
    
    const handleNewUser = () => {
        setIsEditingUser(true);
        setUserIdToEdit(null);
        console.log("handleNewUser");
    };
    
    const handleDeleteUser = async (userId) => {
        try {
            await adminUserApi.deleteAdminUser(userId);
            refreshUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('There was an error deleting the user: ', error);
        }
    };

    const refreshUsers = async () => {
        adminUserApi.getAllAdminUsers().then((users) => {
            setRowData(users);
            setUserCount(users);
        })
    };

    useEffect(() => {
        if (isUpdated) {
            refreshUsers();
            setIsUpdated(false);
        }
    }, [isUpdated]);

    return (
        <>            
            <Row className="mt-3">
                <Col md={6}>
                    <h3 className="text-secondary">Manage Admin Users</h3>
                </Col>
                <Col md={6} style={{textAlign:"right"}}>     
                    <Button variant="info" onClick={handleNewUser}>+ Add User</Button>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3"> 
                <Col md={12} className="ag-theme-alpine">
                    {rowData &&
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            components={{
                                'actionRenderer': (props) => (
                                    <ActionBar 
                                        id={props.data.id} 
                                        name={props.data.username} 
                                        onDelete={userCount > 1 ? handleDeleteUser : 'hideDelete'} 
                                        onEdit={handleEditUser} 
                                    />
                                ),
                            }}
                            onGridReady={onGridReady}
                            domLayout='autoHeight'
                        />
                    }
                </Col>
            </Row>
        </>            
    );
}
