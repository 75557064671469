import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminAuthApi } from '../api/adminAuth.js';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authentication, setAuthentication] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const authStr = localStorage.getItem('authentication');
        if (authStr) {
            const auth = JSON.parse(authStr);
            setAuthentication(new Authentication(
                auth.username, auth.firstName, auth.lastName, auth.clientName, auth.clientId,
                auth.role, auth.jwtToken, auth.refreshToken
            ));
        }
    }, []);

    const signIn = async (username, password) => {
        let result = await adminAuthApi.login(username, password);
        let claims = jwtDecode(result.jwtToken);
        const newAuth = new Authentication(
            claims.username, claims.firstName, claims.lastName, claims.clientName, parseInt(claims.clientId),
            claims.role, result.jwtToken, result.refreshToken
        );
        setAuthentication(newAuth);
        localStorage.setItem('authentication', JSON.stringify(newAuth));
    };


    const masqueradeAs = async (clientUserId) => {
        let result = await adminAuthApi.masqueradeAsClientUser(clientUserId);
        let claims = jwtDecode(result.jwtToken);
        const newAuth = new Authentication(
            claims.username, claims.firstName, claims.lastName, claims.clientName, parseInt(claims.clientId),
            claims.role, result.jwtToken, result.refreshToken
        );
        newAuth.masquerading = true;
        newAuth.masqueraderAuthentication = {...authentication};
        setAuthentication(newAuth);
        localStorage.setItem('authentication', JSON.stringify(newAuth));
        navigate('/');
    };

    const endMasquerade = async () => {
        if (authentication && authentication.masquerading){
            setAuthentication({...authentication.masqueraderAuthentication});
            localStorage.setItem('authentication', JSON.stringify({...authentication.masqueraderAuthentication}));
            navigate('/');
        }
    }

    const refreshToken = async () => {
        console.log("refreshToken called", authentication);
        if (authentication) {
            try {
                console.log("about to send request to refresh token");
                let result = await adminAuthApi.refreshToken(authentication.jwtToken, authentication.refreshToken);
                let claims = jwtDecode(result.jwtToken);
                const newAuth = new Authentication(
                    claims.username, claims.firstName, claims.lastName, claims.clientName, parseInt(claims.clientId),
                    claims.role, result.jwtToken, result.refreshToken
                );
                newAuth.masqueraderAuthentication = authentication.masqueraderAuthentication;
                newAuth.masquerading = authentication.masquerading;
                setAuthentication(newAuth);
                localStorage.setItem('authentication', JSON.stringify(newAuth));
                return result.jwtToken;
            } catch (e) {
                console.warn('Could not refresh token. Logging out.', e);
                logout();
            }
        } else {
            logout();
        }
    };

    const signedIn = () => authentication !== null;

    const logout = () => {
        setAuthentication(null);
        localStorage.removeItem('authentication');
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ authentication, signIn, signedIn, refreshToken, logout, masqueradeAs, endMasquerade }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export class Authentication {
    constructor(username, firstName, lastName, clientName, clientId, role, jwtToken, refreshToken) {
        this.masqueraderAuthentication = null;
        this.masquerading = false;
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.clientName = clientName;
        this.clientId = clientId;
        this.role = role;
        this.jwtToken = jwtToken;
        this.refreshToken = refreshToken;
    }
}
