import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import { postLoginQuestionApi } from "../../../api/postLoginQuestion.js";

export default function PostLoginQuestionEdit(props) {
    
    const { clientId, questionId, setIsEditingQuestion, setIsUpdated } = props;
    const { Formik } = formik;    

    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showOptionsInput, setShowOptionsInput] = useState(false);

    const schema = yup.object().shape({
        question: yup.string().required(),
        type: yup.string().required(),
        fieldToPopulate: yup.string().required(),
    });

    const questionDefaults = {
        clientId: clientId,
        question: "",
        type: "",
        description: "",
        options: "",
        fieldToPopulate: ""
    }

	const getQuestionData = async () => {
		const question = await postLoginQuestionApi.getPostLoginQuestion(questionId);

        // Replace the options and description fields with empty string if null
        const normalizedQuestion = {
            ...question,
            description: question.description || "",
            options: question.options || "",
        };

		setSelectedQuestion(normalizedQuestion);
        setShowOptionsInput(question?.type === "options");
	};

	useEffect(() => {
		if (questionId) getQuestionData();
	}, [questionId]);

    return (
        <>
            <Row className="ms-3 mb-3">
                {selectedQuestion ? <h5 className="text-secondary mb-3">Edit Post Login Question</h5> : <h5 className="text-secondary mb-3">Create Post Login Question</h5>}
            </Row>           

            <Formik                                        
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        try {
                            if (selectedQuestion) {
                                await postLoginQuestionApi.updatePostLoginQuestion(values);
                            } else {
                                await postLoginQuestionApi.createPostLoginQuestion(values);
                            }
                            setIsUpdated(true);
                            setIsEditingQuestion(false);
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Error Submitting Question" }})
                            console.error("Error Submitting Question" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedQuestion || questionDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-5">
                            <Col md={7} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">

                                {/* Post Login Question Input */}
                                <Form.Group as={Row} className="mb-3" controlId="question">
                                    <Form.Label>Enter Question:</Form.Label>
                                    <Form.Control
                                        name="question"
                                        onChange={handleChange}
                                        value={values.question}
                                        type="text" 
                                        placeholder={"e.g. First Name"}
                                        isInvalid={touched.question && !!errors.question}                                        
                                    />
                                    {touched.question && (<Form.Control.Feedback type="invalid">
                                        {errors.question}
                                    </Form.Control.Feedback>)}
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="description">
                                    <Form.Label>Question Description:</Form.Label>
                                    <Form.Control
                                        name="description"
                                        onChange={handleChange}
                                        value={values.description}
                                        type="text" 
                                        isInvalid={touched.description && !!errors.description}                                        
                                    />
                                    {touched.description && (<Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>)}
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="type">
                                    <Form.Label>Question Type:</Form.Label>
                                    <Form.Select
                                        name="type"
                                        onChange={(e) => {
                                            handleChange(e);
                                            setShowOptionsInput(e.target.value === "options");
                                          }}
                                        value={values.type}
                                        isInvalid={touched.type && !!errors.type}                                        
                                    >
                                        <option>Select Type</option>
                                        <option value="text">Text</option>
                                        <option value="numeric">Numeric</option>
                                        <option value="yesno">Yes/No</option>
                                        <option value="options">Options</option>
                                    </Form.Select>
                                    {touched.type && (<Form.Control.Feedback type="invalid">
                                        {errors.type}
                                    </Form.Control.Feedback>)}
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="fieldToPopulate">
                                    <Form.Label>Field To Populate:</Form.Label>
                                    <Form.Select
                                        name="fieldToPopulate"
                                        onChange={handleChange}
                                        value={values.fieldToPopulate}
                                        isInvalid={touched.fieldToPopulate && !!errors.fieldToPopulate}                                        
                                    >
                                        <option>Select Field</option>
                                        <option value="firstName">First Name</option>
                                        <option value="lastName">Last Name</option>
                                        <option value="employeeNumber">Employee Number</option>
                                        <option value="location">Location</option>
                                        <option value="other">Other</option>
                                    </Form.Select>
                                    {touched.fieldToPopulate && (<Form.Control.Feedback type="invalid">
                                        {errors.fieldToPopulate}
                                    </Form.Control.Feedback>)}
                                </Form.Group>    

                                {/* Option Input - Conditionally rendered */}
                                {showOptionsInput && (
                                <Form.Group as={Row} className="mb-3" controlId="options">
                                    <Form.Label>Options (Enter each option on a new line):</Form.Label>
                                    <Form.Control
                                    name="options"
                                    as="textarea"
                                    onChange={handleChange}
                                    value={values.options}
                                    type="text"
                                    rows="4"
                                    placeholder="e.g. Afternoon Shift, Night Shift"
                                    isInvalid={touched.options && !!errors.options}
                                    />
                                    {touched.options && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.options}
                                    </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                                )}

                                <Row>
                                    {/* Create/Update Question Button and Cancel Button */}
                                    <Col md={{span: 4, offset: 5}}>
                                        <Button className="btn-info m-2 w-100" type="submit" disabled={isSubmitting || !dirty}>Save Question</Button>
                                    </Col>
                                    <Col md={3}>
                                        <Button className="btn-info m-2 w-100 text-white" type="button" onClick={() => setIsEditingQuestion(false)}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form> 
                )}
            </Formik>
        </>
    )
}