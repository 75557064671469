import axios from "../_helpers/axiosInstance.js";
import { authHeader } from "../_helpers/authHeader.js";

const getPostLoginQuestions = async (clientId) => {
    const response = await axios.get(`PostLoginQuestion/client/${clientId}`, { headers: authHeader() });
    return response.data.result;
}

const getPostLoginQuestion = async (postLoginQuestionId) => {
    const response = await axios.get(`PostLoginQuestion/${postLoginQuestionId}`, { headers: authHeader() });
    return response.data.result;
}

const createPostLoginQuestion = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.post('PostLoginQuestion', data, { headers: authHeader() });
    return response.data.result;
}

const updatePostLoginQuestion = async (values) => {
    const data = JSON.stringify(values);
    const response = await axios.put('PostLoginQuestion', data, { headers: authHeader() });
    return response.data.result;
}

const deletePostLoginQuestion = async (postLoginQuestionId) => {
    const response = await axios.delete(`PostLoginQuestion/${postLoginQuestionId}`, { headers: authHeader() });
    console.log("Deleting postLoginQuestion: " + response.data.result);
    return response.data.result;
}

const reorderPostLoginQuestions = async (postLoginQuestions) => {
    const data = {
        orderedPostLoginQuestions: postLoginQuestions,
    };
    await axios.put('PostLoginQuestion/reorder', data, { headers: authHeader() });
    return response.data.result;
}

export const postLoginQuestionApi = {
    getPostLoginQuestions,
    getPostLoginQuestion,
    createPostLoginQuestion,
    updatePostLoginQuestion,
    deletePostLoginQuestion,
    reorderPostLoginQuestions
}