// Import libraries and library components

import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// Import public pages to pass to router

import PublicLayout from "./PublicLayout.jsx";

import LoginPage from "./public/LoginPage.jsx";
import ActivateAdminUserPage from "./public/ActivateAdminUserPage.jsx";
import ActivateClientUserPage from './public/ActivateClientUserPage.jsx';
import ForgotPasswordPage from "./public/ForgotPasswordPage.jsx";
import ResetPasswordPage from "./public/ResetPasswordPage.jsx";

// Import pages that require authorization

import ProtectedLayout from "./ProtectedLayout.jsx";

import ClientList from "./protected/client/ClientList.jsx";
import CreateClient from "./protected/client/CreateClient.jsx";
import EditClientTabManager from "./protected/client/EditClientTabManager.jsx";

import CourseList from "./protected/course/CourseList.jsx";
import CourseListPPU from "./protected/course/CourseListPPU.jsx";
import CreateCourse from "./protected/course/CreateCourse.jsx";
import EditCourseTabManager from "./protected/course/EditCourseTabManager.jsx";
import CourseAttempts from "./protected/course/CourseAttempts.jsx";

import Feedback from "./protected/course/Feedback.jsx";
import EditAccount from "./protected/user/EditAccount.jsx";
import AdminUsers from "./protected/user/AdminUsers.jsx";

import InvoiceDetailsPage from "./protected/invoice/InvoiceDetailsPage.jsx";
import CustomerInvoices from './protected/invoice/CustomerInvoices.jsx';
import AddCreditsPage from './protected/client/AddCreditsPage.jsx';
import CustomerInvoiceDetails from './protected/invoice/CustomerInvoiceDetails.jsx';

import setupInterceptors from "../_helpers/axiosInterceptors.js";
import {useNotification} from "../contexts/NotificationContext.jsx";
import {AuthProvider, useAuth} from "../contexts/AuthContext.jsx";
import LogoffPage from './public/LogoffPage.jsx';

function ApplicationRoutes() {
    const authContext = useAuth();
    const notificationContext = useNotification();
    const {signedIn, authentication} = authContext;
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCustomerAdmin, setIsCustomerAdmin] = useState(false);

    useEffect(() => {
        setupInterceptors(() => authContext, () => notificationContext);
        if (signedIn()) {
            const role = authentication.role;
            setIsSuperUser(role === 'SuperUser');
            setIsAdmin(role === 'Admin');
            setIsCustomerAdmin(role === 'CustomerAdmin');
        }
    }, [authentication, signedIn]);

    return (
        <Routes>
            {/* Set Default routes */}
            {/* Unauthenticated default route */}
            {!signedIn() && <Route path="/" element={<PublicLayout><LoginPage /></PublicLayout>} />}
            {/* Customer Admin default route */}
            {signedIn() && isCustomerAdmin && (
                <Route element={<ProtectedLayout />}>
                    <Route path="/" element={<RequireAuth><CourseAttempts /></RequireAuth>} />                    
                </Route>
            )}
            {/* Admin & Super User default route */}
            {signedIn() && (isAdmin || isSuperUser) && (
                <Route element={<ProtectedLayout />}>
                    <Route path="/" element={<RequireAuth><ClientList /></RequireAuth>} />                   
                </Route>
            )}

            {/* the rest of the routes */}
            {/* Public routes */}
            <Route path="/login" element={<PublicLayout><LoginPage /></PublicLayout>} />
            <Route path="/logoff" element={<PublicLayout><LogoffPage /></PublicLayout>} />  {/* Add this route */}
            <Route path="/verifyAdminUser/:activationKey" element={<PublicLayout><ActivateAdminUserPage /></PublicLayout>} />
            <Route path="/verifyClientUser/:activationKey" element={<PublicLayout><ActivateClientUserPage /></PublicLayout>} />
            <Route path="/forgotPassword" element={<PublicLayout><ForgotPasswordPage /></PublicLayout>} />
            <Route path="/resetPassword/:resetKey" element={<PublicLayout><ResetPasswordPage /></PublicLayout>} />

            {/* Protected Admin & Super User routes */}
            {signedIn() && (isAdmin || isSuperUser) && (
                <Route element={<ProtectedLayout />}>                    
                    <Route path="/clients" element={<RequireAuth><ClientList /></RequireAuth>} />
                    <Route path="/newclient" element={<RequireAuth><CreateClient /></RequireAuth>} />
                    <Route path="/attempts" element={<RequireAuth><CourseAttempts /></RequireAuth>} />
                    <Route path="/feedback" element={<RequireAuth><Feedback /></RequireAuth>} />
                    <Route path="/account" element={<RequireAuth><EditAccount /></RequireAuth>} />
                    <Route path="/courses" element={<RequireAuth><CourseList /></RequireAuth>} />
                    <Route path="/newcourse" element={<RequireAuth><CreateCourse /></RequireAuth>} />
                    <Route path="/payperuse" element={<RequireAuth><CourseListPPU /></RequireAuth>} />
                    <Route path="/client/:clientId/:tab?" element={<RequireAuth><EditClientTabManager /></RequireAuth>} />
                    <Route path="/client/:clientId/invoice/:invoiceId" element={<RequireAuth><InvoiceDetailsPage /></RequireAuth>} />
                    <Route path="/client/:clientId/addcredits" element={<RequireAuth><AddCreditsPage /></RequireAuth>} />
                    <Route path="/course/:courseId/:tab?" element={<RequireAuth><EditCourseTabManager /></RequireAuth>} />

                </Route>
            )}

            {/* Protected Super User exclusive routes */}
            {signedIn() && isSuperUser && (
                <Route element={<ProtectedLayout />}>
                    <Route path="/admin-users" element={<RequireAuth><AdminUsers /></RequireAuth>} />
                </Route>
            )}

            {/* Protected shared routes for Admin & Super User & Customer Admin */}
            {signedIn() && (isAdmin || isSuperUser || isCustomerAdmin) && (
                <Route element={<ProtectedLayout />}>
                    <Route path="/account" element={<RequireAuth><EditAccount /></RequireAuth>} />
                    <Route path="/feedback" element={<RequireAuth><Feedback /></RequireAuth>} />
                    <Route path="/attempts" element={<RequireAuth><CourseAttempts /></RequireAuth>} />
                </Route>
            )}

            {/* Protected Customer Admin routes */}
            {signedIn() && isCustomerAdmin && (
                <Route element={<ProtectedLayout />}>
                    <Route path="/invoices" element={<RequireAuth><CustomerInvoices /></RequireAuth>} />    
                    <Route path="/invoices/:invoiceId" element={<RequireAuth><CustomerInvoiceDetails /></RequireAuth>} />                    
                </Route>
            )}
        </Routes>
    );
}

const RequireAuth = ({ children }) => {
    const location = useLocation();
    const { signedIn } = useAuth();

    if (!signedIn()) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export default () => (
    <AuthProvider>
        <ApplicationRoutes />
    </AuthProvider>
);
