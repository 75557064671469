import React, { useState, useCallback } from "react";
import { Container, Col, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";

import { courseApi } from "../../../api/course.js";
import formatDate from "../../../_helpers/formatDate";

export default function CourseList() {

    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        {field: 'courseName', headerName: 'Course Name', sortable: true, filter: true, flex: 4, floatingFilter: true},
        {field: 'displayName', headerName: 'Display Name', sortable: true, filter: true, flex: 4, floatingFilter: true},
        {field: 'createdDate', headerName: 'Date Created', sortable: true, filter: false, flex: 4, floatingFilter: false, valueFormatter: (params) => formatDate(params.data.createdDate)},
        {field: 'passingScore', headerName: 'Pass', sortable: true, filter: true, flex: 2, floatingFilter: false},
        {field: 'id', headerName: 'Actions', cellRenderer: 'actionRenderer', flex: 1, floatingFilter: false}
    ]);

    const onGridReady = useCallback(() => {
        courseApi.getAllCourses().then((courses) => { 
            setRowData(courses);
        })
    }, []);

    const handleDeleteCourse = async (courseId) => {
        try {
          await courseApi.deleteCourse(courseId);
          courseApi.getAllCourses().then((courses) => {
            setRowData(courses);
        })
        } catch (error) {
          console.error('Error deleting course:', error);
          alert('There was an error deleting the course: ', error);
        }
    };

    return (
        <>
            <Row className="mt-3">
                <Col md={6}>
                    <h3 className="text-secondary">Course List</h3>
                </Col>
                <Col md={6} style={{textAlign:"right"}}>                    
                    <Button as={Link} to="/newcourse" variant="info">+ New Course</Button>
                </Col>
            </Row>
            <hr className="mt-3 border-secondary" />
            <Row className="mt-3"> 
                <Col className="ag-theme-alpine w-auto">  
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        components={{
                            'actionRenderer': (props) => (
                              <ActionRenderer courseId={props.data.id} courseName={props.data.courseName} onDelete={handleDeleteCourse} />
                            ),
                        }}
                        onGridReady={onGridReady}
                        paginationPageSize={10}
                        paginationPageSizeSelector={[10, 20, 50, 100]}
                        pagination={true}
                        domLayout='autoHeight'
                    />
                </Col>
            </Row>
        </>
    )
}

function ActionRenderer({ courseId, courseName, onDelete }){

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteConfirm = async () => {
        onDelete(courseId);
        setShowDeleteModal(false);
      };

    return (
        <>
            <Container fluid className="d-flex justify-content-center">
                <Link to={`/course/${courseId}`} className="bi bi-pencil me-3" style={{fontSize:18}}></Link>
                <Link onClick={setShowDeleteModal} className="bi bi-trash" style={{fontSize:18}}></Link>
            </Container>

            <Modal 
                show={showDeleteModal} 
                onHide={() => setShowDeleteModal(false)}
                centered
                animation={false}
            >
                <Modal.Header className="bg-primary" closeButton>
                    <Modal.Title>Delete Course</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light text-center py-5">Are you sure you want to delete {courseName}?</Modal.Body>
                <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                    <Button variant="info w-25 m-2" onClick={handleDeleteConfirm}>Delete</Button>
                    <Button variant="info w-25 m-2" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}