import React, { useState, useRef } from "react";
import { Alert, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import formatDate from "../../../_helpers/formatDate";
import LoadingBar from "../../../components/LoadingBar.jsx";
import { courseApi } from "../../../api/course.js";

export default function CoursewareUpload(props) {
    const { course, courseId } = props;
    const coursewareInputRef = useRef(null);

    const [pageState, setPageState] = useState({ pageError: null });
    const [showModal, setShowModal] = useState(false); 
    const [uploadedCourseware, setUploadedCourseware] = useState(null);
    const [currentCoursewareName, setCurrentCoursewareName] = useState(course.coursewareFileName);
    const [currentCoursewareDate, setCurrentCoursewareDate] = useState(formatDate(course.courseUploadedDate));

    const { Formik } = formik;

    const schema = yup.object().shape({
        courseId: yup.string().required(),
        courseware: yup.mixed(),
    });

    const coursewareUploaded = (event) => {
        setUploadedCourseware(event.target.files[0]);
    }; 

    const handleClear = () => {
        setUploadedCourseware(null);
        coursewareInputRef.current.value = null;
    };

    const fetchUpdatedCourseware = async (courseId) => {
        try {
            const response = await courseApi.getCourse(courseId);
            setCurrentCoursewareName(response.coursewareFileName);
            setCurrentCoursewareDate(response.courseUploadedDate);
        } catch (error) {
            console.error('Error fetching updated courseware:', error);
        }
    };

    return (
        <>
            <Row className="ms-3 mb-3">
                <h5 className="text-secondary mb-3">Courseware Details</h5>
            </Row>

            <Formik
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    try {
                        setShowModal(true); 
                        try {
                            const courseware = {
                                courseId: values.courseId,
                                courseware: uploadedCourseware,
                                userRole: 0,
                            };
                            await courseApi.uploadCourseware(courseware);
                            await fetchUpdatedCourseware(values.courseId); 
                            resetForm();
                            handleClear();
                        } catch (e) {
                            setPageState(prevState => ({ ...prevState, pageError: "Error Uploading Courseware [CoursewareUpload.jsx]" }));
                            console.error("Error Submitting New Courseware" + e);
                        } finally {
                            setShowModal(false); 
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={{
                    courseId: courseId,
                }}
                enableReinitialize
            >
                {({ handleSubmit, touched, isSubmitting, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-3 pt-3">
                            <Col md={8}>
                                <Row className="ms-5 mb-3">
                                    <Col md={4} className="fw-bold">Current Courseware:</Col>
                                    <Col>{currentCoursewareName || "No Courseware Found"}</Col>
                                </Row>
                                <Row className="ms-5 mb-5">
                                    <Col md={4} className="fw-bold">Date Uploaded:</Col>
                                    <Col>{currentCoursewareDate || "No Courseware Found"}</Col>
                                </Row>

                                <Row className="mb-3">
                                    <h5 className="text-secondary mb-3">Upload Courseware</h5>
                                    {console.log('file to upload: ', uploadedCourseware)}
                                </Row>

                                <Row className="ms-5 mb-3">
                                    <Col>
                                        {/* Courseware upload */}
                                        <Form.Group controlId="courseware" className="mb-3">
                                            <Form.Label className="mb-3">Note: Uploading new courseware will overwrite currently uploaded courseware</Form.Label>
                                            <Form.Control
                                                name="courseware"
                                                ref={coursewareInputRef}
                                                onChange={coursewareUploaded}
                                                type="file"
                                                isInvalid={touched.courseware && !!errors.courseware}
                                            />
                                            {touched.courseware && <Form.Control.Feedback type="invalid">{errors.courseware}</Form.Control.Feedback>}
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Upload and Clear Buttons */}
                                <Row className="pt-5">
                                    <Button className="btn-info ms-auto w-25" type="submit" disabled={isSubmitting || !uploadedCourseware}>Upload</Button>
                                    <Button onClick={handleClear} disabled={isSubmitting || !uploadedCourseware} className="btn-info w-25 mx-3" type="button">Cancel</Button>
                                </Row>
                            </Col>
                        </Row>

                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form>
                )}
            </Formik>

            {/* Modal for uploading state */}
            <Modal show={showModal} centered>
                <Modal.Header className="bg-primary">
                    <Modal.Title>Uploading Courseware</Modal.Title>
                </Modal.Header>
                <Modal.Body as={Col} className="bg-light text-center p-5">
                    <Row className="mb-3 d-flex justify-content-center">Please wait while the courseware is being uploaded.</Row>
                    <Row className="m-5">
                        <LoadingBar />
                    </Row>
                    {uploadedCourseware && (
                        <>
                            <Row className="mb-3 small d-flex justify-content-center">File: {uploadedCourseware.name}</Row>
                            <Row className="mb-3 small d-flex justify-content-center">Size: {(uploadedCourseware.size / 1024 / 1024).toFixed(2)} MB</Row>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className="bg-light d-flex justify-content-center border-0">
                </Modal.Footer>     
            </Modal>
        </>
    )
}
