import React, { useState, useEffect } from "react";
import { Alert, Form, Button, Col, Row } from "react-bootstrap";
import * as formik from 'formik';
import * as yup from 'yup';

import AssessmentQuestionOptions from "./AssessmentQuestionOptions.jsx";
import { assessmentQuestionApi } from "../../../api/assessmentQuestion.js";

export default function AssessmentQuestionEdit(props) {
    
    const { courseId, questionId, setIsEditingQuestion, setIsUpdated } = props;
    const { Formik } = formik;    
    
    const [pageState, setPageState] = useState({pageError: null}); 
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const schema = yup.object().shape({
        question: yup.string().required('Question is required'),
        points: yup.number().required('Points are required').min(1, 'Points must be greater than 0'),
    });

    const questionDefaults = {
        courseId: courseId,
        question: "",
        points: 0
    }
    console.log("questionId: " + questionId + " courseId: " + courseId);    
    
    console.log("selected question: " + JSON.stringify(selectedQuestion));
    
    const getQuestionData = async () => {
        const question = await assessmentQuestionApi.getAssessmentQuestion(questionId);
        setSelectedQuestion(question);
    };

    useEffect(() => {
        if (questionId) getQuestionData();
    }, [questionId]);

    return (
        <>
            <Row className="ms-3 mb-3">
                {selectedQuestion ? <h5 className="text-secondary mb-3">Edit Assessment Question</h5> : <h5 className="text-secondary mb-3">Create Assessment Question</h5>}
            </Row>           

            <Formik                                        
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                    console.log("submit pressed" + JSON.stringify(values));
                    try {
                        try {
                            if (selectedQuestion) {
                                await assessmentQuestionApi.updateAssessmentQuestion(values);
                            } else {
                                await assessmentQuestionApi.createAssessmentQuestion(values);
                            }
                            setIsEditingQuestion(false);
                            setIsUpdated(true);
                        } catch (e){
                            setPageState(prevState => {return {...prevState, pageError: "Error Submitting Question" }})
                            console.error("Error Submitting Question" + e);
                        }
                    } finally {
                        setSubmitting(false);
                    }
                }}
                initialValues={selectedQuestion || questionDefaults}
                enableReinitialize={true}
            >
                {({ handleSubmit, handleChange, touched, values, isSubmitting, dirty, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} md={12}>
                        <Row className="ms-5">
                            <Col md={7} className="bg-light shadow p-4 rounded-3 mb-5 mt-0">

                                {/* Assessment Question Input */}
                                <Form.Group as={Row} className="mb-3" controlId="question">
                                    <Form.Label>Enter Question:</Form.Label>
                                    <Form.Control
                                        name="question"
                                        onChange={handleChange}
                                        value={values.question}
                                        type="text" 
                                        isInvalid={touched.question && !!errors.question}                                        
                                    />
                                    {touched.question && (<Form.Control.Feedback type="invalid">
                                        {errors.question}
                                    </Form.Control.Feedback>)}
                                </Form.Group>

                                    {/* Assessment Question Point Value */}
                                    <Form.Group as={Row} className="mb-3" controlId="points">
                                    <Form.Label>Point Value:</Form.Label>
                                    <Form.Control
                                        name="points"
                                        onChange={handleChange}
                                        value={values.points}
                                        type="number" 
                                        isInvalid={touched.points && !!errors.points}                                        
                                    />
                                    {touched.points && (<Form.Control.Feedback type="invalid">
                                        {errors.points}
                                    </Form.Control.Feedback>)}
                                </Form.Group>

                                <Row>
                                    {/* Create/Update Question Button and Cancel Button */}
                                    <Col md={{span: 4, offset: 5}}>
                                        <Button className="btn-info m-2 w-100" type="submit" disabled={isSubmitting || !dirty}>Save Question</Button>
                                    </Col>
                                    <Col md={3}>
                                        <Button className="btn-info m-2 w-100 text-white" type="button" onClick={() => setIsEditingQuestion(false)}>Cancel</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {pageState.pageError && (
                            <Alert key="pageError" variant="danger mt-3">
                                {pageState.pageError}
                            </Alert>
                        )}
                    </Form> 
                )}
            </Formik>
            {selectedQuestion && 
                <Row className="ms-3 mb-3">
                    <AssessmentQuestionOptions questionId={questionId} />
                </Row>       
            }
        </>
    )
}