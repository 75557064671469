import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

// import {loadStripe} from "@stripe/stripe-js";
// import {Elements} from "@stripe/react-stripe-js";
// import "./_helpers/stripeConfig.js";

import ApplicationRoutes from './pages/ApplicationRoutes.jsx';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './main.scss';
import { NotificationProvider } from './contexts/NotificationContext.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';



ReactDOM.createRoot(document.getElementById('root')).render(
      <BrowserRouter>
        <NotificationProvider>
          <ScrollToTop />
          <ApplicationRoutes/>
        </NotificationProvider>
      </BrowserRouter>
)
