import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { courseCreditApi } from "../../../api/courseCredit.js";
import { invoiceApi } from "../../../api/invoice.js";
import CourseCreditTable from "./CourseCreditTable.jsx";
import InvoiceTable from "./InvoiceTable.jsx";

export default function CourseCredits() {

    const navigate = useNavigate();

    const [pageState, setPageState] = useState({
        creditsList: [], invoiceList: []
    })
    const {clientId} = useParams();
    useEffect(() => {
        const getCourseCredits = async () => {
            const response = await courseCreditApi.getCourseCredits(clientId);
            setPageState(prevState => {
                return {...prevState, creditsList: response.result}
            });
        }
        const getInvoices = async () => {
            const response = await invoiceApi.getInvoices(clientId);
            setPageState(prevState => {
                return {...prevState, invoiceList: response}
            });
        }
        getCourseCredits();
        getInvoices();
    }, [clientId]);

    const creditsChanged = (courseCredit) =>{
        if (pageState.creditsList){
            var beforeEdit = pageState.creditsList.find(c => c.id === courseCredit.id);
            if (beforeEdit && (beforeEdit.totalCredits !== courseCredit.totalCredits || beforeEdit.usedCredits !== courseCredit.usedCredits)){
                return true;
            }
        }
        return false;
    }
    const onCreditsChanged = async (newCreditsList) => {
        let reloadCredits = false;
        if (newCreditsList){
            newCreditsList.forEach(c => {
                courseCreditApi.updateCourseCredits(c.id, c.totalCredits, c.usedCredits);
            })
        }
    }
    const onAddCredits = async () => {
        //navigate to add credits
        navigate(`/client/${clientId}/addcredits`);
        
    }
    const deleteInvoice = async (invoiceId) => {
        await invoiceApi.deleteInvoice(invoiceId);
        const response = await invoiceApi.getInvoices(clientId);
        setPageState(prevState => {
            return {...prevState, invoiceList: response}
        });
    }
    return (
        <>
            <Row className="mb-3">
                <Col>
                    <h5 className="text-secondary">Course Credits</h5>
                </Col>
                <Col className="text-end">
                    <Button variant="info" onClick={onAddCredits}>+ Add Credits</Button>
                </Col>
            </Row>
            <hr className="border-secondary"/>
            <Row className="mb-3">
                <CourseCreditTable creditsList={pageState.creditsList} creditsChanged={onCreditsChanged} />
            </Row>
            <Row className="mb-3">
                <h5 className="text-secondary">Invoices</h5>
                <hr className="border-secondary"/>
                <InvoiceTable className="mb-5" invoiceList={pageState.invoiceList} onDeletePressed={deleteInvoice} />
            </Row>
        </>
    )
}
